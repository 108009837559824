import { put } from "redux-saga/effects";
import AccountActions from "reducers/account";
import _ from "lodash";

export default function* errorHandler(error, callback) {
  if (error === "authentication failed!") {
    //To do show alert message
    yield put(AccountActions.signoutRequest());
    return;
  }
  if (_.isFunction(callback)) yield put(callback(error));
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { Row, Col, Table } from "react-bootstrap";
import moment from "moment";
import Card from "components/Card/Card";
import TransporterDetailsActions from "reducers/transporter/transporter-details";


class InformationsTab extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  renderRegisterInfo(register) {
    const { t, i18n } = this.props;
    const { code, createdAt, expireAt } = register;
    return (
      <Table responsive="sm">
        <thead>
          <tr>
            <th colSpan={2}>
              {t("transporterInfosPage.transporter_infos_register_title", {
                lng: i18n.language,
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              {t("transporterInfosPage.transporter_infos_register_code", {
                lng: i18n.language,
              })}
            </th>
            <td>{code}</td>
          </tr>
          <tr>
            <th>
              {t("transporterInfosPage.transporter_infos_register_created_at", {
                lng: i18n.language,
              })}
            </th>
            <td>{moment(createdAt).format("DD MMMM YYYY")}</td>
          </tr>
          <tr>
            <th>
              {t("transporterInfosPage.transporter_infos_register_expire_at", {
                lng: i18n.language,
              })}
            </th>
            <td>{moment(expireAt).format("DD MMMM YYYY")}</td>
          </tr>
        </tbody>
      </Table>
    );
  }

  renderBusinessModel(details) {
    const { t, i18n } = this.props;

    const { amount, business_model } = details.billing;
    return (
      <Table responsive="sm">
        <thead>
          <tr>
            <th colSpan={2}>&nbsp; </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              {t("transporterInfosPage.transporter_infos_bisness_model", {
                lng: i18n.language,
              })}
            </th>
            <td>{business_model === "flat" ? "FIXE" : "POURCENTAGE"}</td>
          </tr>
          <tr>
            <th>
              {t("transporterInfosPage.transporter_infos_amount", {
                lng: i18n.language,
              })}
            </th>
            <td>{amount}</td>
          </tr>
        </tbody>
      </Table>
    );
  }

  renderDetails(details) {
    const { t, i18n, backOfficeTransporterToggleActivationRequest } = this.props;
    const { _id, siret, name, shortName = null, email, web, active } = details;

    return (
      <Table responsive="sm">
        <thead>
          <tr>
            <th colSpan={2}>
              {t("transporterInfosPage.transporter_infos_details_title", {
                lng: i18n.language,
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              {t("transporterInfosPage.transporter_infos_siret_name", {
                lng: i18n.language,
              })}
            </th>
            <td>{siret}</td>
          </tr>
          <tr>
            <th>
              {t("transporterInfosPage.transporter_infos_company_name", {
                lng: i18n.language,
              })}
            </th>
            <td>{name}</td>
          </tr>
          <tr>
            <th>
              {t("transporterInfosPage.transporter_infos_company_short_name", {
                lng: i18n.language,
              })}
            </th>
            <td>{shortName}</td>
          </tr>
          <tr>
            <th>
              {t("transporterInfosPage.transporter_infos_email", {
                lng: i18n.language,
              })}
            </th>
            <td>{email}</td>
          </tr>
          <tr>
            <th>
              {t("transporterInfosPage.transporter_infos_web_site", {
                lng: i18n.language,
              })}
            </th>
            <td>{web}</td>
          </tr>
          <tr>
            <th>
              {t("transporterInfosPage.transporter_infos_active", {
                lng: i18n.language,
              })}
            </th>
            <td>
              <label class="switch">
                <input type="checkbox" checked={active ? "checked" : ""} onChange={e => backOfficeTransporterToggleActivationRequest(_id)} />
                <span class="slider round"></span>
              </label>
              {/* {active ? "OUI" : "NON"} */}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  render() {
    const { details } = this.props;

    return (
      <Card
        title=""
        category=""
        content={
          <div>
            <Row>
              <Col md="6">{this.renderDetails(details)}</Col>
              <Col md="6">{this.renderBusinessModel(details)}</Col>
              <Col md="6">{this.renderRegisterInfo(details.driverRegister)}</Col>
            </Row>
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeTransporterToggleActivationRequest: (...args) =>
      dispatch(
        TransporterDetailsActions.backOfficeTransporterToggleActivationRequest(
          ...args
        )
      ),
  };
};

const transporterInfosRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationsTab);
export default withTranslation(["Private", "Common"], { wait: true })(
  transporterInfosRedux
);

import React, { Component } from "react";
import { Navbar, Nav, NavDropdown, MenuItem, FormGroup } from "react-bootstrap";
import avatar from "assets/img/default-avatar.png";
import { withTranslation } from "react-i18next";

class HeaderLinks extends Component {
  constructor(props) {
    super(props);

    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    const { signoutRequest } = this.props;
    signoutRequest();
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <div>
        {/*}
       <FeedBackPopUp
          //{...{feedBackRequest}}
          backdrop="static"
          keyboard={false}
          show={showFeedBackPopUp}
          onHide={() => { this.setState(Object.assign({}, this.state, { showFeedBackPopUp: false })) }}

        />
       {*/}

        <Navbar.Form pullLeft className="navbar-search-form">
          <FormGroup></FormGroup>
        </Navbar.Form>
        <Nav pullRight>
          <div
            className="photo"
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <img
              src={avatar}
              alt="Avatar"
              style={{
                width: 50,
                height: 50,
                //overflow: hidden,
                borderRadius: 18,
                //float: "left",
                marginRight: 10,
                marginLeft: 10,
                zIndex: 5,
                border: 2,
              }}
            />
          </div>
        </Nav>

        <Nav pullRight>
          <NavDropdown
            eventKey={4}
            title={
              <div>
                <i className="fa fa-list" />
                <p className="hidden-md hidden-lg">
                  More
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-3"
            bsClass="dropdown-with-icons dropdown"
          >
            <MenuItem eventKey={4.5} onClick={this.signOut}>
              <div className="text-danger">
                <i className="pe-7s-close-circle" />{" "}
                {t(`navLinkLogOut.nav_link_log_out`, { lng: i18n.language })}
              </div>
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}
export default withTranslation(["Private", "Common"], { wait: true })(
  HeaderLinks
);

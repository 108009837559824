import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeCreateTransporterAdminRequest: ["admin", "companyId", "callback"],
  backOfficeCreateTransporterAdminRequestSuccess: null,
  backOfficeCreateTransporterAdminRequestFailure: ["error"],
  backOfficeClearError: null,
});

export const AddTransporterAdminTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  action: {
    loading: false,
    error: "",
  },
});

export const backOfficeClearError = (state, action) => {
  return Immutable.setIn(state, ["action", "error"], "");
};

export const backOfficeCreateTransporterAdminRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeCreateTransporterAdminRequestSuccess = (
  state,
  action
) => {
  return Immutable.setIn(state, ["action", "loading"], false);
  //return Immutable.setIn(nextSate, ["company", 'users'], [action.admin, ...state.company.users.admins])
};

export const backOfficeCreateTransporterAdminRequestFailure = (
  state,
  action
) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_CREATE_TRANSPORTER_ADMIN_REQUEST]:
    backOfficeCreateTransporterAdminRequest,
  [Types.BACK_OFFICE_CREATE_TRANSPORTER_ADMIN_REQUEST_SUCCESS]:
    backOfficeCreateTransporterAdminRequestSuccess,
  [Types.BACK_OFFICE_CREATE_TRANSPORTER_ADMIN_REQUEST_FAILURE]:
    backOfficeCreateTransporterAdminRequestFailure,
  [Types.BACK_OFFICE_CLEAR_ERROR]: backOfficeClearError,
});

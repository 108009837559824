import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  taxiGetDetailsResetState: null,
  taxiGetDetailsRequest: ["taxiId"],
  taxiGetDetailsRequestSuccess: ["taxi"],
  taxiGetDetailsRequestFailure: ["error"],
});

export const TaxiDetailsType = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  taxi: {
    details: null,
  },

  action: {
    loading: false,
    error: "ddd",
  },
});

/// GENERAL
export const taxiGetDetailsResetState = (state, action) => {
  return INITIAL_STATE;
};

export const taxiGetDetailsRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const taxiGetDetailsRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const taxiGetDetailsRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["taxi", "details"], action.taxi);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TAXI_GET_DETAILS_RESET_STATE]: taxiGetDetailsResetState,
  [Types.TAXI_GET_DETAILS_REQUEST]: taxiGetDetailsRequest,
  [Types.TAXI_GET_DETAILS_REQUEST_SUCCESS]: taxiGetDetailsRequestSuccess,
  [Types.TAXI_GET_DETAILS_REQUEST_FAILURE]: taxiGetDetailsRequestFailure,
});

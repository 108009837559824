import React, { Component } from "react";
import { connect } from "react-redux";
import CompanyDetailsActions from "reducers/company/company-details";
import { Alert } from "react-bootstrap";

import { withTranslation } from "react-i18next";

import {
  Col,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
} from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";
import _ from "lodash";
import { Formik } from "formik";
import { number, object } from "yup";

const validationSchema = object().shape({
  dispatching1stCycleTimeOut: number().required("required"),
  dispatching2ndCycleTimeOut: number(),
});

class CompanyUpdateServiceSettings extends Component {
  constructor(props) {
    super(props);

    this.formikProps = null;
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {}

  onSubmitForm(values, { resetForm, setSubmitting }) {
    const {
      backOfficeModifyServiceSettingsRequest,
      backOfficeGetServiceDetailsRequest,
      companyId,
      serviceId,
      onHide,
    } = this.props;

    const { dispatching1stCycleTimeOut, dispatching2ndCycleTimeOut } = values;

    backOfficeModifyServiceSettingsRequest(
      dispatching1stCycleTimeOut,
      dispatching2ndCycleTimeOut,
      companyId,
      serviceId,
      (error) => {
        if (error) {
          return;
        }
        setSubmitting(false);
        resetForm();
        onHide();
        backOfficeGetServiceDetailsRequest(companyId, serviceId);
      }
    );
  }

  render() {
    const { action, settings } = this.props;
    const { t, i18n } = this.props;

    const { error } = action;

    return (
      <Formik
        initialValues={{
          dispatching1stCycleTimeOut:
            settings && settings.dispatching1stCycleTimeOut
              ? settings.dispatching1stCycleTimeOut
              : 5,
          dispatching2ndCycleTimeOut:
            settings && settings.dispatching2ndCycleTimeOut
              ? settings.dispatching2ndCycleTimeOut
              : 15,
        }}
        validationSchema={validationSchema}
        onSubmit={this.onSubmitForm}
      >
        {(props) => {
          const { errors, touched } = props;

          return (
            <Modal
              {...this.props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              backdrop="static"
            >
              {error !== "" ? (
                <Alert bsStyle="danger">
                  <span>{error}</span>
                </Alert>
              ) : null}
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Settings{" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form horizontal>
                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {" "}
                      1st Cycle TimeOut (minute)
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder=""
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "dispatching1stCycleTimeOut") &&
                          _.has(touched, "dispatching1stCycleTimeOut")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange(
                          "dispatching1stCycleTimeOut"
                        )}
                        onInput={() =>
                          props.setFieldTouched(
                            "dispatching1stCycleTimeOut",
                            true,
                            true
                          )
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue(
                            "dispatching1stCycleTimeOut",
                            value,
                            true
                          );
                        }}
                        value={props.values.dispatching1stCycleTimeOut}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      2nd Cycle TimeOut (minute)
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder=""
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "dispatching2ndCycleTimeOut") &&
                          _.has(touched, "dispatching2ndCycleTimeOut")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange(
                          "dispatching2ndCycleTimeOut"
                        )}
                        onInput={() =>
                          props.setFieldTouched(
                            "dispatching2ndCycleTimeOut",
                            true,
                            true
                          )
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue(
                            "dispatching2ndCycleTimeOut",
                            value,
                            true
                          );
                        }}
                        value={props.values.dispatching2ndCycleTimeOut}
                        required
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  // disabled={props.isSubmitting}
                  onClick={() => {
                    props.resetForm();
                    this.props.onHide();
                  }}
                >
                  {" "}
                  {t("Common:footerPage.close", { lng: i18n.language })}
                </Button>
                <Button
                  bsStyle="success"
                  fill
                  // disabled={props.isSubmitting}
                  onClick={() => {
                    props.handleSubmit();
                  }}
                >
                  {t("Common:footerPage.save_changes", { lng: i18n.language })}
                </Button>
              </Modal.Footer>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    action: state.backOfficeGetDetailsCompany.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeGetServiceDetailsRequest: (...args) =>
      dispatch(
        CompanyDetailsActions.backOfficeGetServiceDetailsRequest(...args)
      ),
    backOfficeModifyServiceSettingsRequest: (...args) =>
      dispatch(
        CompanyDetailsActions.backOfficeModifyServiceSettingsRequest(...args)
      ),
  };
};

const CompanyUpdateServiceSettingsRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyUpdateServiceSettings);
export default withTranslation(["Private", "Common"], { wait: true })(
  CompanyUpdateServiceSettingsRedux
);

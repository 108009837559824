import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton";
import _ from "lodash";
import NewHospService from "./company-add-service";
import { withTranslation } from "react-i18next";

class ServicesTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddNewServiceModal: false,
    };
  }

  renderServices() {
    const { services } = this.props;
    const { t, i18n } = this.props;

    return (
      <ReactTable
        data={services}
        filterable
        columns={[
          {
            Header: (
              <>
                {" "}
                {t("companyServicesPage.firstname_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "name",
          },
          {
            Header: (
              <>
                {" "}
                {t("companyServicesPage.address_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "",
            Cell: (prop) => {
              const { address = "" } = prop.original.address;
              let desc = _.split(address, ",");

              return (
                <>
                  <i className="fa fa-map-marker" /> {`${desc}`}
                </>
              );
            },
          },

          {
            Header: (
              <> {t("Common:ReactTable.action", { lng: i18n.language })}</>
            ),
            accessor: "actions",
            sortable: false,
            filterable: false,
            Cell: (prop) => {
              const { _id } = prop.original;
              return (
                <div className="actions-right">
                  <Link to={`/hosp-service/${_id}`}>
                    <Button bsStyle="dark" simple icon title="view">
                      <i className="fa fa-eye" />
                    </Button>
                  </Link>{" "}
                  {/* <Button
                                    bsStyle="dark"
                                    simple
                                    icon
                                    title="edit"
                                >
                                    <i className="fa fa-pencil" />
                                </Button>{" "}
                                <Button
                                    bsStyle="dark"
                                    simple
                                    icon
                                    title="deactivate"
                                >
                                    <i className="fa fa-minus-circle" />
                                </Button>{" "}

                                <Button
                                    bsStyle="dark"
                                    simple
                                    icon
                                    title="delete"
                                >
                                    <i className="fa fa-trash" />
                                </Button>{" "} */}
                </div>
              );
            },
          },
        ]}
        previousText={t("Common:ReactTable.previous_text_label", {
          lng: i18n.language,
        })}
        nextText={t("Common:ReactTable.next_text_label", {
          lng: i18n.language,
        })}
        ofText={t("Common:ReactTable.off_Text_label", { lng: i18n.language })}
        rowsText={t("Common:ReactTable.rows_Text_label", {
          lng: i18n.language,
        })}
        loadingText={t("Common:ReactTable.loading_Text_label", {
          lng: i18n.language,
        })}
        noDataText={t("Common:ReactTable.no_data_Text_label", {
          lng: i18n.language,
        })}
        showPaginationTop={false}
        showPaginationBottom
        className="-striped -highlight"
      />
    );
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <Card
        title=""
        category=""
        content={
          <div>
            <NewHospService
              {...{ ...this.props }}
              show={this.state.showAddNewServiceModal}
              onHide={() => {
                this.setState(
                  Object.assign({}, this.state, {
                    showAddNewServiceModal: false,
                  })
                );
              }}
            />

            <Button
              bsStyle="primary"
              fill
              wd
              onClick={() => {
                this.setState(
                  Object.assign({}, this.state, {
                    showAddNewServiceModal: true,
                  })
                );
              }}
            >
              {t("companyServicesPage.company_services_button", {
                lng: i18n.language,
              })}
            </Button>
            {this.renderServices()}
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const CompanyServiceRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesTab);
export default withTranslation(["Private", "Common"], { wait: true })(
  CompanyServiceRedux
);

import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton";
import _ from "lodash";
import NewTransporterAdmin from "./transporter-admin";
import NewAmbuDriver from "./add-transporter-driver";
import SweetAlert from "react-bootstrap-sweetalert";
import ModifyTransporterUser from "./transporter-modify-user";
import ModifyTransporterDriver from "./transporter-modify-driver";

import { Row, Col, Tab, Nav, NavItem } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import TransporterDetailsActions from "reducers/transporter/transporter-details";

class UsersTab extends Component {
  constructor(props) {
    super(props);

    this.onPressDeleteUserButton = this.onPressDeleteUserButton.bind(this);
    this.onPressDeleteDriverButton = this.onPressDeleteDriverButton.bind(this);

    this.state = {
      showModifyCompanyUserPopUp: false,
      showModifyCompanyUserDriverPopUp: false,
      userToUpdate: null,
      alert: null,
      driverUser: null,
      showAddNewAdminModale: false,
      showAddNewDriverModale: false,
    };
  }

  componentDidMount() {}

  onPressDeleteUserButton(props) {
    const { backOfficeDeleteUserCompanyRequest } = this.props;
    const { companyId } = this.props;

    backOfficeDeleteUserCompanyRequest(props._id, companyId);
    this.hideAlert();
  }

  warningWithConfirmAndCancelMessage(props) {
    const { t, i18n } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Suppression"
          onConfirm={() => this.onPressDeleteUserButton(props)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Oui supprimer"
          cancelBtnText="Non"
          showCancel
        >
          {t("transporterUsersPage.delete_company_user_message", {
            lng: i18n.language,
          })}
        </SweetAlert>
      ),
    });
  }

  warningWithConfirmAndCancelMessageDriver(props) {
    const { t, i18n } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Suppression"
          onConfirm={() => this.onPressDeleteDriverButton(props)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Oui supprimer"
          cancelBtnText="Non"
          showCancel
        >
          {t("transporterUsersPage.delete_company_user_message", {
            lng: i18n.language,
          })}
        </SweetAlert>
      ),
    });
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  onPressDeleteUserButton(props) {
    const { backOfficeDeleteUserTransporterRequest } = this.props;
    const { companyId } = this.props;

    backOfficeDeleteUserTransporterRequest(props._id, companyId);
    this.hideAlert();
  }

  onPressDeleteDriverButton(props) {
    const { backOfficeDeleteDriverTransporterRequest } = this.props;
    const { companyId } = this.props;

    backOfficeDeleteDriverTransporterRequest(props._id, companyId);
    this.hideAlert();
  }

  renderAdmins() {
    const { admins } = this.props;
    const { t, i18n } = this.props;

    return (
      <ReactTable
        data={admins}
        filterable
        columns={[
          {
            Header: (
              <>
                {" "}
                {t("transporterUsersPage.firstname_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "firstname",
          },
          {
            Header: (
              <>
                {" "}
                {t("transporterUsersPage.lastname_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "lastname",
          },
          {
            Header: (
              <>
                {" "}
                {t("transporterUsersPage.email_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "email",
          },
          {
            Header: (
              <> {t("Common:ReactTable.action", { lng: i18n.language })}</>
            ),
            accessor: "actions",
            sortable: false,
            filterable: false,
            Cell: (prop) => {
              return (
                <div className="actions-right">
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="view"
                    //onClick={() => history.push(`amb-company/${_id}/`)}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="edit"
                    onClick={() => {
                      this.setState(
                        Object.assign({}, this.state, {
                          showModifyCompanyUserPopUp: true,
                          userToUpdate: prop.original,
                        })
                      );
                    }}
                  >
                    <i className="fa fa-pencil" />
                  </Button>{" "}
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="delete"
                    onClick={() => {
                      this.warningWithConfirmAndCancelMessage(prop.original);
                    }}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                </div>
              );
            },
          },
        ]}
        previousText={t("Common:ReactTable.previous_text_label", {
          lng: i18n.language,
        })}
        nextText={t("Common:ReactTable.next_text_label", {
          lng: i18n.language,
        })}
        ofText={t("Common:ReactTable.off_Text_label", { lng: i18n.language })}
        rowsText={t("Common:ReactTable.rows_Text_label", {
          lng: i18n.language,
        })}
        loadingText={t("Common:ReactTable.loading_Text_label", {
          lng: i18n.language,
        })}
        noDataText={t("Common:ReactTable.no_data_Text_label", {
          lng: i18n.language,
        })}
        showPaginationTop={false}
        showPaginationBottom
        className="-striped -highlight"
      />
    );
  }

  renderDrivers() {
    const { t, i18n, drivers } = this.props;

    return (
      <ReactTable
        data={drivers}
        filterable
        columns={[
          {
            Header: (
              <>
                {" "}
                {t("transporterUsersPage.firstname_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "firstname",
          },
          {
            Header: (
              <>
                {" "}
                {t("transporterUsersPage.lastname_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "lastname",
          },
          {
            Header: (
              <>
                {" "}
                {t("transporterUsersPage.phone_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "phone",
          },
          {
            Header: (
              <> {t("Common:ReactTable.action", { lng: i18n.language })}</>
            ),
            accessor: "actions",
            sortable: false,
            filterable: false,
            Cell: (prop) => (
              <div className="actions-right">
                <Button
                  bsStyle="dark"
                  simple
                  icon
                  title="view"
                  //onClick={() => history.push(`amb-company/${_id}/`)}
                >
                  <i className="fa fa-eye" />
                </Button>{" "}
                <Button
                  bsStyle="dark"
                  simple
                  icon
                  title="edit"
                  onClick={() => {
                    this.setState(
                      Object.assign({}, this.state, {
                        showModifyCompanyUserDriverPopUp: true,
                        driverUser: prop.original,
                      })
                    );
                  }}
                >
                  <i className="fa fa-pencil" />
                </Button>{" "}
                <Button
                  bsStyle="dark"
                  simple
                  icon
                  title="delete"
                  onClick={() => {
                    this.warningWithConfirmAndCancelMessageDriver(
                      prop.original
                    );
                  }}
                >
                  <i className="fa fa-trash" />
                </Button>{" "}
              </div>
            ),
          },
        ]}
        previousText={t("Common:ReactTable.previous_text_label", {
          lng: i18n.language,
        })}
        nextText={t("Common:ReactTable.next_text_label", {
          lng: i18n.language,
        })}
        ofText={t("Common:ReactTable.off_Text_label", { lng: i18n.language })}
        rowsText={t("Common:ReactTable.rows_Text_label", {
          lng: i18n.language,
        })}
        loadingText={t("Common:ReactTable.loading_Text_label", {
          lng: i18n.language,
        })}
        noDataText={t("Common:ReactTable.no_data_Text_label", {
          lng: i18n.language,
        })}
        showPaginationTop={false}
        showPaginationBottom
        className="-striped -highlight"
      />
    );
  }

  render() {
    const { t, i18n, companyId } = this.props;
    const {} = this.props;
    const { showAddNewAdminModale, showAddNewDriverModale } = this.state;

    return (
      <Card
        title=""
        category=""
        content={
          <Tab.Container id="tabs-with-dropdown" defaultActiveKey="admins">
            <Row className="clearfix">
              <Col sm={12}>
                <Nav bsStyle="tabs">
                  {this.state.alert}

                  <NavItem eventKey="admins">
                    {t("transporterUsersPage.company_add_user_admin_label", {
                      lng: i18n.language,
                    })}
                  </NavItem>
                  <NavItem eventKey="drivers">
                    {t(
                      "transporterUsersPage.company_add_user_chauffeur_label",
                      { lng: i18n.language }
                    )}
                  </NavItem>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content animation>
                  <Tab.Pane eventKey="admins">
                    <NewTransporterAdmin
                      {...{ ...this.props }}
                      show={showAddNewAdminModale}
                      onHide={() => {
                        this.setState(
                          Object.assign({}, this.state, {
                            showAddNewAdminModale: false,
                          })
                        );
                      }}
                    />
                    <ModifyTransporterUser
                      transporterId={companyId}
                      transporterUser={this.state.userToUpdate}
                      show={this.state.showModifyCompanyUserPopUp}
                      onHide={() => {
                        this.setState(
                          Object.assign({}, this.state, {
                            showModifyCompanyUserPopUp: false,
                          })
                        );
                      }}
                    />

                    <ModifyTransporterDriver
                      driverId={companyId}
                      driverUser={this.state.driverUser}
                      show={this.state.showModifyCompanyUserDriverPopUp}
                      onHide={() => {
                        this.setState(
                          Object.assign({}, this.state, {
                            showModifyCompanyUserDriverPopUp: false,
                          })
                        );
                      }}
                    />
                    <Button
                      bsStyle="success"
                      fill
                      wd
                      onClick={() => {
                        this.setState(
                          Object.assign({}, this.state, {
                            showAddNewAdminModale: true,
                          })
                        );
                      }}
                    >
                      Ajouter un admin
                    </Button>
                    {this.renderAdmins()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="drivers">
                    <NewAmbuDriver
                      {...{ ...this.props }}
                      show={showAddNewDriverModale}
                      onHide={() => {
                        this.setState(
                          Object.assign({}, this.state, {
                            showAddNewDriverModale: false,
                          })
                        );
                      }}
                    />

                    <Button
                      bsStyle="primary"
                      fill
                      wd
                      onClick={() => {
                        this.setState(
                          Object.assign({}, this.state, {
                            showAddNewDriverModale: true,
                          })
                        );
                      }}
                    >
                      Ajouter un chauffeur
                    </Button>
                    {this.renderDrivers()}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeDeleteUserTransporterRequest: (...args) =>
      dispatch(
        TransporterDetailsActions.backOfficeDeleteUserTransporterRequest(
          ...args
        )
      ),

    backOfficeDeleteDriverTransporterRequest: (...args) =>
      dispatch(
        TransporterDetailsActions.backOfficeDeleteDriverTransporterRequest(
          ...args
        )
      ),
  };
};

const CompanyUsersRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTab);
export default withTranslation(["Private", "Common"], { wait: true })(
  CompanyUsersRedux
);

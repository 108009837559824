import { put, takeLatest } from "redux-saga/effects";
import CompanyDetailsActions, {
  CompanyDetailsType,
} from "reducers/company/company-details";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* backOfficeGetCompanyDetailsAsyncRequest({ companyId }) {
  const query = `
        {
            BackOfficeCompanyWithId(
                companyId:"${companyId}"
              )
              { 
              _id 
                siret 
                active 
                 name 
                   email 
                            web
                                billing {business_model}
                                        billing{amount}
              
                                        services{ 
                                          _id name address{address}  contact{firstname lastname email  phones}
                                          
                                          }
              
              }
              
 
        }
    `;
  try {
    const data = yield graphQLClient().request(query);
    const companyInfos = data.BackOfficeCompanyWithId;

    yield put(
      CompanyDetailsActions.backOfficeGetCompanyDetailsRequestSuccess(
        companyInfos
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      CompanyDetailsActions.backOfficeGetCompanyDetailsRequestFailure
    );
  }
}

function* backOfficeCompanyUsersAsyncRequest({ companyId }) {
  const query = `
      {
        BackOfficeCompanyUsers(companyId: "${companyId}")
        {_id firstname lastname email}
      }
  `;

  try {
    const data = yield graphQLClient().request(query);

    const UsersInfos = data.BackOfficeCompanyUsers;

    yield put(
      CompanyDetailsActions.backOfficeCompanyUsersRequestSuccess(UsersInfos)
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      CompanyDetailsActions.backOfficeCompanyUsersRequestFailure
    );
  }
}

function* backOfficeGetServiceUsersRequest({ companyId, serviceId }) {
  const query = `
    {
      BackOfficeServiceUsers(companyId: "${companyId}", serviceId: "${serviceId}")
      { 
          firstname lastname email active _id
          
          
          
          }
    }
`;

  try {
    const data = yield graphQLClient().request(query);
    const { BackOfficeServiceUsers } = data;

    yield put(
      CompanyDetailsActions.backOfficeGetServiceUsersRequestSuccess(
        BackOfficeServiceUsers
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];

    yield errorHandler(
      message,
      CompanyDetailsActions.backOfficeGetServiceUsersRequestFailure
    );
  }
}

function* backOfficeGetServiceDetailsRequest({ companyId, serviceId }) {
  const query = `
      {
          BackOfficeServiceWithId(companyId: "${companyId}", serviceId: "${serviceId}")
    { 

      _id name address{address}   contact{firstname lastname email phones} whitelist
      settings { dispatching1stCycleTimeOut dispatching2ndCycleTimeOut}
    }
      }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const { BackOfficeServiceWithId } = data;

    yield put(
      CompanyDetailsActions.backOfficeGetServiceDetailsRequestSuccess(
        BackOfficeServiceWithId
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];

    yield errorHandler(
      message,
      CompanyDetailsActions.backOfficeGetServiceDetailsRequestFailure
    );
  }
}

function* backOfficeModifyCompanyUserAsyncRequest({
  companyUser,
  id,
  companyId,
  callback,
}) {
  const {
    firstname = "",
    lastname = "",
    email = "",
    password = "",
    _id,
  } = companyUser;
  const query = `
    mutation {
      BackOfficeModifyCompanyUser(user:{ 
          firstname:"${firstname}",
          lastname:"${lastname}",
          email:"${email}",
          password:"${password}"
        },
        userId:"${id}"
)
 

        
        
        }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const { BackOfficeModifyCompanyUser } = data;
    yield put(
      CompanyDetailsActions.backOfficeModifyUserCompanyRequestSuccess()
    );
    yield put(CompanyDetailsActions.backOfficeCompanyUsersRequest(companyId));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      CompanyDetailsActions.backOfficeModifyUserCompanyRequestFailure
    );
    if (_.isFunction(callback)) callback(error);
  }
}

function* backOfficeModifyServiceSettingsAsyncRequest({
  firstCycleTimeOut,
  secondCycleTimeOut,
  companyId,
  serviceId,
  callback,
}) {
  const query = `
  mutation {
    BackOfficeUpdateServiceSettings(
      firstCycleTimeOut:${firstCycleTimeOut},
      secondCycleTimeOut:${secondCycleTimeOut},
      companyId:"${companyId}",
      serviceId:"${serviceId}",
    )  
  }
`;
  try {
    yield graphQLClient().request(query);
    yield put(
      CompanyDetailsActions.backOfficeModifyServiceSettingsRequestSuccess()
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    // const { message = "" } = error.response.errors[0]
    yield put(
      CompanyDetailsActions.backOfficeModifyServiceSettingsRequestFailure(error)
    );
    if (_.isFunction(callback)) callback(error);
  }
}

function* backOfficeModifyUserServiceAsyncRequest({
  serviceUser,
  id,
  companyId,
  serviceId,
  callback,
}) {
  const {
    firstname = "",
    lastname = "",
    email = "",
    password = "",
  } = serviceUser;
  const query = `
    mutation {
      BackOfficeModifyServiceUser(user:{ 
          firstname:"${firstname}",
          lastname:"${lastname}",
          email:"${email}",
          password:"${password}"
        },
        userId:"${id}"
)
 

        
        
        }
  `;
  try {
    yield graphQLClient().request(query);
    yield put(
      CompanyDetailsActions.backOfficeModifyUserServiceRequestSuccess()
    );
    yield put(
      CompanyDetailsActions.backOfficeGetServiceUsersRequest(
        companyId,
        serviceId
      )
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      CompanyDetailsActions.backOfficeModifyServiceRequestFailure
    );
    if (_.isFunction(callback)) callback(error);
  }
}

function* backOfficeDeleteCompanyUserAsyncRequest({
  companyUserId,
  companyId,
  callback,
}) {
  const query = `
  mutation {

    BackOfficeDeleteCompanyUser(userId:"${companyUserId}")
    
    }
  `;
  try {
    yield graphQLClient().request(query);

    yield put(
      CompanyDetailsActions.backOfficeDeleteUserCompanyRequestSuccess()
    );
    yield put(CompanyDetailsActions.backOfficeCompanyUsersRequest(companyId));

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      CompanyDetailsActions.backOfficeDeleteUserCompanyRequestFailure
    );

    if (_.isFunction(callback)) callback(error);
  }
}

function* backOfficeDeleteServiceUserAsyncRequest({
  serviceUserId,
  companyId,
  serviceId,
  callback,
}) {
  const query = `
  mutation {

    BackOfficeDeleteServiceUser(userId:"${serviceUserId}")
    
    }
  `;
  try {
    yield graphQLClient().request(query);

    yield put(
      CompanyDetailsActions.backOfficeDeleteUserServiceRequestSuccess()
    );
    yield put(
      CompanyDetailsActions.backOfficeGetServiceUsersRequest(
        companyId,
        serviceId
      )
    );

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      CompanyDetailsActions.backOfficeDeleteUserServiceRequestFailure
    );

    if (_.isFunction(callback)) callback(error);
  }
}

function* BackOfficeServiceAddTransporterToTheWhiteListAsyncRequest({
  companyId,
  serviceId,
  transporterId,
  isAdd,
}) {
  const query = `
    mutation {
      BackOfficeServiceAddTransporterToTheWhiteList(
        companyId:"${companyId}",
        serviceId:"${serviceId}",
        transporterId:"${transporterId}",
        isAdd:${isAdd}
      )  
    }
  `;
  try {
    const { BackOfficeServiceAddTransporterToTheWhiteList: response } =
      yield graphQLClient().request(query);
    console.log(response);
  } catch (error) {
    // const { message = "" } = error.response.errors[0]
    console.error(error);
  }
}

export default [
  takeLatest(
    CompanyDetailsType.BACK_OFFICE_MODIFY_SERVICE_SETTINGS_REQUEST,
    backOfficeModifyServiceSettingsAsyncRequest
  ),
  takeLatest(
    CompanyDetailsType.BACK_OFFICE_SERVICE_ADD_TRANSPORTER_TO_THE_WHITE_LIST_REQUEST,
    BackOfficeServiceAddTransporterToTheWhiteListAsyncRequest
  ),
  takeLatest(
    CompanyDetailsType.BACK_OFFICE_GET_COMPANY_DETAILS_REQUEST,
    backOfficeGetCompanyDetailsAsyncRequest
  ),
  takeLatest(
    CompanyDetailsType.BACK_OFFICE_COMPANY_USERS_REQUEST,
    backOfficeCompanyUsersAsyncRequest
  ),
  takeLatest(
    CompanyDetailsType.BACK_OFFICE_GET_SERVICE_DETAILS_REQUEST,
    backOfficeGetServiceDetailsRequest
  ),
  takeLatest(
    CompanyDetailsType.BACK_OFFICE_GET_SERVICE_USERS_REQUEST,
    backOfficeGetServiceUsersRequest
  ),

  takeLatest(
    CompanyDetailsType.BACK_OFFICE_MODIFY_USER_COMPANY_REQUEST,
    backOfficeModifyCompanyUserAsyncRequest
  ),
  takeLatest(
    CompanyDetailsType.BACK_OFFICE_DELETE_USER_COMPANY_REQUEST,
    backOfficeDeleteCompanyUserAsyncRequest
  ),
  takeLatest(
    CompanyDetailsType.BACK_OFFICE_DELETE_USER_SERVICE_REQUEST,
    backOfficeDeleteServiceUserAsyncRequest
  ),
  takeLatest(
    CompanyDetailsType.BACK_OFFICE_MODIFY_USER_SERVICE_REQUEST,
    backOfficeModifyUserServiceAsyncRequest
  ),
];

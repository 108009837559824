import { put, takeLatest } from "redux-saga/effects";
import AddTransporterVehicleActions, {
  AddTransporterVehicleTypes,
} from "reducers/transporter/add-transporter-vehicle";
import AddTransporterActions from "reducers/transporter/transporter-details";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* backOfficeCreateTransporterVehicleAsyncRequest({
  vehicle,
  companyId,
  callback,
}) {
  const { model = "", plate = "", make = "", category, options } = vehicle;
  const query = `
    mutation {
        BackOfficeTransporterAddVehicle(vehicle: {
        plate: "${plate}",
        model: "${model}",
        make: "${make}",
        category: ${category},  
        options:[${options}],
      },
      companyId: "${companyId}") {
        _id
        plate
        model
        make
        category
        options
        inService
        inUse
        createdAt
        updatedAt
    
      
      }
      }
  `;
  try {
    yield graphQLClient().request(query);

    yield put(
      AddTransporterVehicleActions.backOfficeCreateVehicleRequestSuccess()
    );
    yield put(
      AddTransporterActions.backOfficeTransporterVehiclesRequest(
        companyId,
        1,
        10
      )
    );

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      AddTransporterVehicleActions.backOfficeCreateVehicleRequestFailure
    );

    if (_.isFunction(callback)) callback(error);
  }
}
export default [
  takeLatest(
    AddTransporterVehicleTypes.BACK_OFFICE_CREATE_VEHICLE_REQUEST,
    backOfficeCreateTransporterVehicleAsyncRequest
  ),
];

import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import CompanyDetailsActions from "reducers/company/company-details";

class FavorisTab extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  modifyFavorisFromService(prop, isAdd) {
    const {
      service,
      companyId,
      BackOfficeServiceAddTransporterToTheWhiteListRequest,
    } = this.props;
    const transporterId = prop.original._id;
    BackOfficeServiceAddTransporterToTheWhiteListRequest(
      companyId,
      service._id,
      transporterId,
      isAdd
    );
  }

  renderAdmins() {
    const { transporters, service } = this.props;

    return (
      <ReactTable
        data={transporters}
        filterable
        columns={[
          {
            Header: "Nom",
            accessor: "name",
          },
          {
            Header: "Adresse",
            accessor: "address.address",
          },
          {
            Header: "Favoris",
            Cell: (prop) => {
              let arr = [];
              if (service.hasOwnProperty("whitelist")) {
                arr = service.whitelist;
              }
              const isFavoris = arr.includes(prop.original._id);
              return isFavoris ? <i className="fa fa-check" /> : null;
            },
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false,
            Cell: (prop) => {
              return (
                <div className="actions-right">
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="view"
                    onClick={() => this.modifyFavorisFromService(prop, true)}
                  >
                    <i className="fa fa-plus" />
                  </Button>{" "}
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="edit"
                    onClick={() => this.modifyFavorisFromService(prop, false)}
                  >
                    <i className="fa fa-remove" />
                  </Button>{" "}
                </div>
              );
            },
          },
        ]}
        showPaginationTop={false}
        showPaginationBottom
        className="-striped -highlight"
      />
    );
  }

  render() {
    return (
      <Card title="" category="" content={<div>{this.renderAdmins()}</div>} />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    BackOfficeServiceAddTransporterToTheWhiteListRequest: (...args) =>
      dispatch(
        CompanyDetailsActions.BackOfficeServiceAddTransporterToTheWhiteListRequest(
          ...args
        )
      ),
  };
};

const CompanyUsersRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(FavorisTab);
export default withTranslation(["Private", "Common"], { wait: true })(
  CompanyUsersRedux
);

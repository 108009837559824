import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { version } from "../../../package.json";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";

// image for avatar in Sidebar
//import avatar from "assets/img/default-avatar.png";

// logo for sidebar
import logo from "assets/img/resaam-logo-blanc.png";

import routes from "routes";
import _ from "lodash";
import { withTranslation } from "react-i18next";

var ps;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getCollapseStates(routes),
      openAvatar: false,
      isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
      width: window.innerWidth,
    };
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    const { t, i18n } = this.props;

    return routes.map((prop, key) => {
      if (prop.layout !== "/private") return null;

      if (prop.redirect || prop.hidden) {
        return null;
      }

      let linkStyle = "";
      if (prop.style === "primary") {
        linkStyle = "text-info";
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              <i className={prop.icon} />

              <p>
                {prop.name}
                <b
                  className={
                    this.state[prop.state] ? "caret rotate-180" : "caret"
                  }
                />
              </p>
            </a>
            <Collapse in={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.path)} key={key}>
          <NavLink to={prop.path} className="nav-link" activeClassName="active">
            {prop.icon ? (
              _.isFunction(prop.icon) ? (
                prop.icon(
                  prop.iconSource,
                  <> {t(`Sidebar.${prop.name}`, { lng: i18n.language })}</>
                )
              ) : (
                <>
                  {/*}
              <img src={prop.icon} />
              {*/}
                  <i className={`${prop.icon} ${linkStyle}`} />
                  <p
                    className={`${linkStyle}`}
                    style={{ display: "inline-block", marginLeft: 10 }}
                  >
                    {t(`Sidebar.${prop.name}`, { lng: i18n.language })}
                  </p>{" "}
                  {/* le style ici ne sert a rien ?  */}
                </>
              )
            ) : (
              <>
                <span className="sidebar-mini">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidUpdate() {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
  }
  componentDidMount() {
    this.updateDimensions();
    // add event listener for windows resize
    window.addEventListener("resize", this.updateDimensions.bind(this));
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.color}
        data-image={this.props.image}
      >
        {this.props.hasImage ? (
          <div
            className="sidebar-background"
            style={{
              backgroundImage: "url(" + this.props.image + ")",
              filter: "grayscale(100%)",
            }}
          />
        ) : (
          ""
        )}

        <div className="logo" style={{ paddingLeft: 20 }}>
          <img src={logo} alt="react-logo" height={30} />
        </div>
        <div className="sidebar-wrapper" ref="sidebarWrapper">
          <ul className="nav">
            {/*
              If we are on responsive, we want both links from navbar and sidebar
              to appear in sidebar, so we render here HeaderLinks
            */}
            {this.state.width <= 992 ? <AdminNavbarLinks /> : null}
            {/*
              here we render the links in the sidebar if the link is simple,
              we make a simple link, if not, we have to create a collapsible group,
              with the speciffic parent button and with it's children which are the links
            */}
            {this.createLinks(routes)}
          </ul>
        </div>
        <footer className="footer footer-transparent">
          <div
            className="copyright"
            style={{
              position: "absolute",
              bottom: 10,
              zIndex: 100,
              color: "gray",
            }}
          >
            <p>
              &copy; {1900 + new Date().getYear()}{" "}
              <a href="https://www.resaam.com?ref=lbdpr-footer" target="_blank">
                Resaam
              </a>{" "}
              v{`${version}`}
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(Sidebar);

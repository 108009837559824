import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Alert,
  Col,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
} from "react-bootstrap";
import _ from "lodash";
import { Formik } from "formik";
import { string, object } from "yup";
import Button from "components/CustomButton/CustomButton.jsx";

const validationSchema = object().shape({
  sectorName: string().required("required"),
  cities: string().required("required"),
});

class SamuSectorModal extends Component {
  constructor(props) {
    super(props);

    this.formikProps = null;
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { sector } = this.props;
    if (sector !== prevProps.sector) {
      // this.formikProps.resetForm();
      if (sector)
        this.formikProps.setValues({
          sectorName: sector.sectorName,
          cities: sector.cities.join(", "),
        });
    }
  }

  onSubmitForm(values, { resetForm, setSubmitting }) {
    const {
      sector: isEdit,
      companyId,
      onHide,
      createRequest,
      updateRequest,
    } = this.props;

    const sector = {
      sectorName: values.sectorName,
      cities: values.cities.split(",").map((c) => c.trim()),
    };

    const callback = (error) => {
      if (error) {
        console.error(error);
        return;
      }
      setSubmitting(false);
      resetForm();
      onHide();
    };

    if (isEdit) updateRequest(isEdit._id, sector, callback);
    else createRequest(companyId, sector, callback);
  }

  render() {
    const { t, i18n, action, sector } = this.props;
    const { error } = action;

    return (
      <Formik
        innerRef={(ref) => (this.formikProps = ref)}
        initialValues={{
          sectorName: "",
          cities: [],
        }}
        validationSchema={validationSchema}
        onSubmit={this.onSubmitForm}
      >
        {(props) => {
          const { errors, touched } = props;
          return (
            <Modal
              {...this.props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              backdrop="static"
            >
              {error !== "" && (
                <Alert bsStyle="danger">
                  <span>{error}</span>
                </Alert>
              )}

              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t(
                    `samuSectorModalPage.${
                      sector ? "update_headline" : "add_headline"
                    }`,
                    { lng: i18n.language }
                  )}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form horizontal>
                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {" "}
                      {t("samuSectorModalPage.sector_name_label", {
                        lng: i18n.language,
                      })}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "samuSectorModalPage.sector_name_placeholder",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "sectorName") &&
                          _.has(touched, "sectorName")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("sectorName")}
                        onInput={() =>
                          props.setFieldTouched("sectorName", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("sectorName", value, true);
                        }}
                        value={props.values.sectorName}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {" "}
                      {t("samuSectorModalPage.sector_cities_label", {
                        lng: i18n.language,
                      })}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "samuSectorModalPage.sector_cities_placeholder",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "cities") && _.has(touched, "cities")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("cities")}
                        onInput={() =>
                          props.setFieldTouched("cities", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("cities", value, true);
                        }}
                        value={props.values.cities}
                        required
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  // disabled={props.isSubmitting}
                  onClick={() => {
                    // props.resetForm()
                    // this.props.clearError()
                    this.props.onHide();
                  }}
                >
                  {" "}
                  {t("Common:footerPage.close", { lng: i18n.language })}
                </Button>
                <Button
                  bsStyle="success"
                  fill
                  disabled={_.isEmpty(errors) ? false : true}
                  onClick={props.handleSubmit}
                >
                  {t("Common:footerPage.save_changes", { lng: i18n.language })}
                </Button>
              </Modal.Footer>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(
  SamuSectorModal
);

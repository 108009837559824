import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Col, Table } from "react-bootstrap";

import Card from "components/Card/Card";

import _ from "lodash";
import { withTranslation } from "react-i18next";

class InformationsTab extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  renderDriver(details) {
    const { t, i18n } = this.props;

    if (_.isNull(details)) return null;
    const { _id, firstname, lastname, email, status } = details;

    let statusReal = "";
    if (status == "disconnected") {
      statusReal = "Hors ligne";
    }
    return (
      <Table responsive="sm">
        <thead>
          <tr>
            <th colSpan={2}>
              {t("taxiInfosPage.taxi_infos_chauffeur_title", {
                lng: i18n.language,
              })}{" "}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              {t("taxiInfosPage.taxi_infos_chauffeur_label", {
                lng: i18n.language,
              })}{" "}
            </th>
            <td>
              {firstname}
              {lastname}
            </td>
          </tr>
          <tr>
            <th>
              {t("taxiInfosPage.taxi_infos_email_label", {
                lng: i18n.language,
              })}
            </th>
            <td>{email}</td>
          </tr>
          <tr>
            <th>
              {t("taxiInfosPage.taxi_infos_status_label", {
                lng: i18n.language,
              })}
            </th>
            <td>{statusReal}</td>
          </tr>
        </tbody>
      </Table>
    );
  }

  renderVehicleDetails(details) {
    const { t, i18n } = this.props;

    if (_.isNull(details)) return null;

    const { vehicle } = details;

    if (_.isNull(vehicle)) return null;

    const { plate, model, make } = vehicle;
    const plateMajuscule = plate.toUpperCase();
    const modelMajuscule = model.toUpperCase();
    const makeMajuscule = make.toUpperCase();

    return (
      <Table responsive="sm">
        <thead>
          <tr>
            <th colSpan={2}>
              {t("taxiInfosPage.taxi_infos_details_title", {
                lng: i18n.language,
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              {t("taxiInfosPage.taxi_infos_make_label", { lng: i18n.language })}
            </th>
            <td>{makeMajuscule}</td>
          </tr>
          <tr>
            <th>
              {" "}
              {t("taxiInfosPage.taxi_infos_model_label", {
                lng: i18n.language,
              })}
            </th>
            <td>{modelMajuscule}</td>
          </tr>

          <tr>
            <th>
              {t("taxiInfosPage.taxi_infos_plate_label", {
                lng: i18n.language,
              })}
            </th>
            <td>{plateMajuscule}</td>
          </tr>
        </tbody>
      </Table>
    );
  }

  render() {
    const { details } = this.props;
    const { t, i18n } = this.props;

    return (
      <Card
        title=""
        category=""
        content={
          <div>
            <Row>
              <Col md="6">{this.renderVehicleDetails(details)}</Col>
              <Col md="6">{this.renderDriver(details)}</Col>
            </Row>
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const taxiInformationInfosRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationsTab);
export default withTranslation(["Private", "Common"], { wait: true })(
  taxiInformationInfosRedux
);

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeCreateCompanyAdminRequest: ["admin", "companyId", "callback"],
  backOfficeCreateCompanyAdminRequestSuccess: ["admin"],
  backOfficeCreateCompanyAdminRequestFailure: ["error"],
  backOfficeClearError: null,
});

export const AddCompanyAdminTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  company: {
    users: [],
  },

  action: {
    loading: false,
    error: "",
  },
});

export const backOfficeClearError = (state, action) => {
  return Immutable.setIn(state, ["action", "error"], "");
};

export const backOfficeCreateCompanyAdminRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeCreateCompanyAdminRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["company", "users"],
    [...state.company.users, action.admin]
  );
};

export const backOfficeCreateCompanyAdminRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_CREATE_COMPANY_ADMIN_REQUEST]:
    backOfficeCreateCompanyAdminRequest,
  [Types.BACK_OFFICE_CREATE_COMPANY_ADMIN_REQUEST_SUCCESS]:
    backOfficeCreateCompanyAdminRequestSuccess,
  [Types.BACK_OFFICE_CREATE_COMPANY_ADMIN_REQUEST_FAILURE]:
    backOfficeCreateCompanyAdminRequestFailure,
  [Types.BACK_OFFICE_CLEAR_ERROR]: backOfficeClearError,
});

import { put, takeLatest } from "redux-saga/effects";
import AddSamuActions, { AddSamuTypes } from "reducers/samu/add-samu";
import errorHandler from "sagas/error-handler";

import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* backOfficeCreateSamuAsyncRequest({
  siret,
  name,
  address,
  billing_address,
  email,
  phones,
  web,
  contact,
  billing,
  rib,
  callback,
}) {
  const query = `
        mutation{
            BackOfficeCreateSamuCompany(
                company:
                {
                siret: "${siret}",
                name: "${name}",
                address:{
                  address: "${address.address}",
                  longitude: ${address.location.longitude},
                  latitude: ${address.location.latitude},
                },
                billing_address:{
                  address: "${billing_address.address}",
                  longitude: ${billing_address.location.longitude},
                  latitude: ${billing_address.location.latitude},
                },
                email:"${email}",
                phones: {
                  office: "${phones.office}"
                },
                web: "${web}",
                contact: {
                  firstname: "${contact.firstname}",
                  lastname: "${contact.lastname}",
                  email: "${contact.email}",
                  phones:{
                    mobile : "${contact.phones.mobile}"
                  }
                },
                billing:{
                  amount: ${billing.amount},
                  business_model: ${billing.business_model}
                },
                rib:{
                  number: "${rib.number}",
                  src: "${rib.src}"
                }
              }){
                _id
              }
        }
    `;

  try {
    yield graphQLClient().request(query);
    yield put(AddSamuActions.backOfficeCreateSamuRequestSuccess());

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      AddSamuActions.backOfficeCreateSamuRequestFailure
    );
  }
}
export default [
  takeLatest(
    AddSamuTypes.BACK_OFFICE_CREATE_SAMU_REQUEST,
    backOfficeCreateSamuAsyncRequest
  ),
];

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeTransportersResetState: null,
  backOfficeTransportersListRequest: ["page", "limit"],
  backOfficeTransportersListRequestSuccess: ["transporters"],
  backOfficeTransportersListRequestFailure: ["error"],
});

export const TransportersTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  list: {
    page: 1,
    limit: 10,
    companies: [],
  },

  action: {
    loading: false,
    error: "ddd",
  },
});

/// GENERAL
export const backOfficeTransportersResetState = (state, action) => {
  return INITIAL_STATE;
};

export const backOfficeTransportersListRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeTransportersListRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeTransportersListRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["list"], action.transporters);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_TRANSPORTERS_RESET_STATE]:
    backOfficeTransportersResetState,
  [Types.BACK_OFFICE_TRANSPORTERS_LIST_REQUEST]:
    backOfficeTransportersListRequest,
  [Types.BACK_OFFICE_TRANSPORTERS_LIST_REQUEST_SUCCESS]:
    backOfficeTransportersListRequestSuccess,
  [Types.BACK_OFFICE_TRANSPORTERS_LIST_REQUEST_FAILURE]:
    backOfficeTransportersListRequestFailure,
});

import { put, takeLatest } from "redux-saga/effects";
import AddCompanyServiceActions, {
  CompanyDetailsType,
} from "reducers/company/company-details";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* backOfficeCreateCompanyServiceAsyncRequest({
  service,
  companyId,
  callback,
}) {
  const { name, contact, address } = service;
  const query = `
    mutation {
        BackOfficeCreateService(service: {
        name: "${name}",   
        address: {
          address: "${address.address}",
          longitude: ${address.location.longitude},
          latitude: ${address.location.latitude},
        },
         contact:{
          firstname: "${contact.firstname}",
          lastname: "${contact.lastname}",
          email: "${contact.email}",
          phones:{
              mobile : "${contact.phones.mobile}"
          }
        }
        
      }, 
        companyId: "${companyId}") {
        _id
        name
       address {
         address
         longitude
         latitude
       }
        contact {
          firstname
          lastname
          email
          phones
        }
        
      }
      }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const { BackOfficeCreateService } = data;
    yield put(
      AddCompanyServiceActions.backOfficeCreateCompanyServiceRequestSuccess(
        BackOfficeCreateService
      )
    );

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      AddCompanyServiceActions.backOfficeCreateCompanyServiceRequestFailure
    );
  }
}

export default [
  takeLatest(
    CompanyDetailsType.BACK_OFFICE_CREATE_COMPANY_SERVICE_REQUEST,
    backOfficeCreateCompanyServiceAsyncRequest
  ),
];

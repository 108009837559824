import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import Radio from "components/CustomRadio/CustomRadio.jsx";

import SweetAlert from "react-bootstrap-sweetalert";
import AddCompaniesActions from "reducers/company/add-company";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import "react-google-places-autocomplete/dist/index.min.css";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Form,
  Alert,
} from "react-bootstrap";

import Card from "components/Card/Card";
import Button from "components/CustomButton/CustomButton";
import Loader from "react-loader-spinner";
import { Formik } from "formik";
import { string, object, number } from "yup";

const validationSchema = object().shape({
  siret: string().required("required"),
  name: string().required("required"),
  email: string().required("required"),
  web: string(),
  phones: object().shape({
    office: string().required("required"),
  }),
  address: object().shape({
    address: string().required("required"),
    location: object().shape({
      latitude: number().required(""),
      longitude: number().required(""),
    }),
  }),
  billing_address: object().shape({
    address: string().required("required"),
    location: object().shape({
      latitude: number().required(""),
      longitude: number().required(""),
    }),
  }),
  billing: object().shape({
    amount: string().required("required"),
  }),
  rib: object().shape({
    number: string(),
    src: string(),
  }),
  contact: object().shape({
    firstname: string(),
    lastname: string(),
    email: string(),
    phones: object().shape({
      mobile: string(),
    }),
  }),
});

class AddCompany extends Component {
  constructor(props) {
    super(props);

    this.Address = createRef();
    this.billingAddress = createRef();
    this.formikProps = null;
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.successAlert = this.successAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);

    this.state = {
      alert: null,
    };
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  successAlert() {
    const { t, i18n } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {t("AddCompanyPage.company_add_with_success", { lng: i18n.language })}
        </SweetAlert>
      ),
    });
  }

  onSubmitForm(values, { resetForm, setSubmitting }) {
    const {
      siret,
      name,
      address,
      billing_address,
      email,
      phones,
      web,
      contact,
      billing,
      rib,
    } = values;

    const { backOfficeCreateCompanyRequest } = this.props;

    backOfficeCreateCompanyRequest(
      siret,
      name,
      address,
      billing_address,
      email,
      phones,
      web,
      contact,
      billing,
      rib,
      () => {
        setSubmitting(false);
        resetForm();

        this.Address.current.clearValue();
        this.billingAddress.current.clearValue();
        this.successAlert();
      }
    );
  }

  componentDidMount() {}

  render() {
    const { action } = this.props;
    const { error, loading } = action;
    const { t, i18n } = this.props;

    return (
      <div className="main-content">
        {this.state.alert}
        <Grid fluid>
          <Row className="text-center">
            <Col md={6} sm={6} mdOffset={3} smOffset={3}>
              {error !== "" ? (
                <Alert bsStyle="danger">
                  <span>{error}</span>
                </Alert>
              ) : null}
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={100}
                width={100}
                visible={loading}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title={
                  <legend>
                    {t("AddCompanyPage.add_company_page_headline", {
                      lng: i18n.language,
                    })}
                  </legend>
                }
                content={
                  <Formik
                    initialValues={{
                      siret: "",
                      name: "",
                      email: "",
                      phones: {
                        office: "",
                      },
                      web: "",
                      address: {
                        location: {
                          longitude: 0,
                          latitude: 0,
                        },
                        address: "",
                      },
                      billing_address: {
                        location: {
                          longitude: 0,
                          latitude: 0,
                        },
                        address: "",
                      },
                      billing: {
                        amount: 0,
                        business_model: "PERCENTAGE",
                      },
                      rib: {
                        number: "",
                        src: " ",
                      },
                      contact: {
                        firstname: " ",
                        lastname: "",
                        email: "",
                        phones: {
                          mobile: "",
                        },
                      },
                    }}
                    validationSchema={validationSchema}
                    onSubmit={this.onSubmitForm}
                  >
                    {(props) => {
                      const { errors, touched } = props;

                      return (
                        <Form horizontal>
                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t("AddCompanyPage.add_company_siret_label", {
                                  lng: i18n.language,
                                })}
                              </ControlLabel>
                              <Col sm={10}>
                                <FormControl
                                  className={
                                    _.has(errors, "siret") &&
                                    _.has(touched, "siret")
                                      ? "error"
                                      : ""
                                  }
                                  type="text"
                                  onChange={props.handleChange("siret")}
                                  onInput={() =>
                                    props.setFieldTouched("siret", true, true)
                                  }
                                  onBlur={(e) => {
                                    const { value } = e.currentTarget;
                                    props.setFieldValue("siret", value, true);
                                  }}
                                  value={props.values.siret}
                                  required
                                />
                                <HelpBlock></HelpBlock>
                              </Col>
                            </FormGroup>
                          </fieldset>

                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t("AddCompanyPage.add_company_nss_label", {
                                  lng: i18n.language,
                                })}
                              </ControlLabel>
                              <Col sm={10}>
                                <FormControl
                                  type="text"
                                  className={
                                    _.has(errors, "name") &&
                                    _.has(touched, "name")
                                      ? "error"
                                      : ""
                                  }
                                  onChange={props.handleChange("name")}
                                  onInput={() =>
                                    props.setFieldTouched("name", true, true)
                                  }
                                  onBlur={(e) => {
                                    const { value } = e.currentTarget;
                                    props.setFieldValue("name", value, true);
                                  }}
                                  value={props.values.name}
                                  required
                                />
                                <HelpBlock></HelpBlock>
                              </Col>
                            </FormGroup>
                          </fieldset>

                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t("AddCompanyPage.add_company_email_label", {
                                  lng: i18n.language,
                                })}
                              </ControlLabel>
                              <Col sm={10}>
                                <FormControl
                                  type="text"
                                  className={
                                    _.has(errors, "email") &&
                                    _.has(touched, "email")
                                      ? "error"
                                      : ""
                                  }
                                  onChange={props.handleChange("email")}
                                  onInput={() =>
                                    props.setFieldTouched("email", true, true)
                                  }
                                  onBlur={(e) => {
                                    const { value } = e.currentTarget;
                                    props.setFieldValue("email", value, true);
                                  }}
                                  value={props.values.email}
                                  required
                                />
                                <HelpBlock></HelpBlock>
                              </Col>
                            </FormGroup>
                          </fieldset>

                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t("AddCompanyPage.add_company_phone_label", {
                                  lng: i18n.language,
                                })}
                              </ControlLabel>
                              <Col sm={10}>
                                <FormControl
                                  type="text"
                                  className={
                                    _.has(errors, "errors.phones") &&
                                    _.has(errors, "errors.phones.office") &&
                                    _.has(touched, "errors.phones") &&
                                    _.has(touched, "errors.phones.office")
                                      ? "error"
                                      : ""
                                  }
                                  onChange={props.handleChange("phones.office")}
                                  onInput={() =>
                                    props.setFieldTouched(
                                      "phones.office",
                                      true,
                                      true
                                    )
                                  }
                                  onBlur={(e) => {
                                    const { value } = e.currentTarget;
                                    props.setFieldValue(
                                      "phones.office",
                                      value,
                                      true
                                    );
                                  }}
                                  value={props.values.phones.office}
                                  required
                                />
                                <HelpBlock></HelpBlock>
                              </Col>
                            </FormGroup>
                          </fieldset>

                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t(
                                  "AddCompanyPage.add_company_web_site_label",
                                  { lng: i18n.language }
                                )}
                              </ControlLabel>
                              <Col sm={10}>
                                <FormControl
                                  type="text"
                                  className={
                                    _.has(errors, "web") &&
                                    _.has(touched, "web")
                                      ? "error"
                                      : ""
                                  }
                                  onChange={props.handleChange("web")}
                                  onInput={() =>
                                    props.setFieldTouched("web", true, true)
                                  }
                                  onBlur={(e) => {
                                    const { value } = e.currentTarget;
                                    props.setFieldValue("web", value, true);
                                  }}
                                  value={props.values.web}
                                />
                                <HelpBlock></HelpBlock>
                              </Col>
                            </FormGroup>
                          </fieldset>

                          <legend>
                            {" "}
                            {t("AddCompanyPage.add_company_address_label", {
                              lng: i18n.language,
                            })}
                          </legend>

                          <fieldset>
                            <FormGroup>
                              <Col sm={12}>
                                <GooglePlacesAutocomplete
                                  ref={this.Address}
                                  placeholder={t(
                                    "AddCompanyPage.add_company_address_placeholder",
                                    { lng: i18n.language }
                                  )}
                                  suggestionsStyles={{
                                    suggestion: {
                                      fontSize: 15,
                                    },
                                  }}
                                  inputStyle={{
                                    backgroundColor: "#ffffff",
                                    borderColor:
                                      _.has(errors, "address.address") &&
                                      _.has(touched, "address.address")
                                        ? "#fb404b"
                                        : "#e3e3e3",
                                    color: "#565656",
                                    borderWidth: 1,
                                    borderRadius: 4,
                                    boxShadow: "none",
                                    height: 40,
                                    borderStyle: "solid",
                                    paddingHorizontal: 12,
                                    paddingVertical: 8,
                                    width: "100%",
                                  }}
                                  autocompletionRequest={
                                    {
                                      // types: ['postal_code'],
                                    }
                                  }
                                  onSelect={(result) => {
                                    const { place_id, description } = result;

                                    props.setFieldValue(
                                      "address.address",
                                      description,
                                      true
                                    );

                                    geocodeByPlaceId(place_id)
                                      .then((results) => {
                                        return getLatLng(results[0]);
                                      })
                                      .then(({ lat, lng }) => {
                                        props.setFieldValue(
                                          "address.location.latitude",
                                          lat,
                                          true
                                        );
                                        props.setFieldValue(
                                          "address.location.longitude",
                                          lng,
                                          true
                                        );
                                      });
                                  }}
                                  onLoadFailed={(error) =>
                                    console.error(
                                      "Could not inject Google script"
                                    )
                                  }
                                />
                              </Col>
                            </FormGroup>
                          </fieldset>

                          <legend>
                            {t(
                              "AddCompanyPage.add_company_address_facture_label",
                              { lng: i18n.language }
                            )}
                          </legend>
                          <fieldset>
                            <FormGroup>
                              <Col sm={12}>
                                <GooglePlacesAutocomplete
                                  ref={this.billingAddress}
                                  placeholder={t(
                                    "AddCompanyPage.add_company_address_facture__placeholder",
                                    { lng: i18n.language }
                                  )}
                                  suggestionsStyles={{
                                    suggestion: {
                                      fontSize: 15,
                                    },
                                  }}
                                  inputStyle={{
                                    backgroundColor: "#ffffff",
                                    borderColor:
                                      _.has(
                                        errors,
                                        "billing_address.address"
                                      ) &&
                                      _.has(touched, "billing_address.address")
                                        ? "#fb404b"
                                        : "#e3e3e3",
                                    color: "#565656",
                                    borderWidth: 1,
                                    borderRadius: 4,
                                    boxShadow: "none",
                                    height: 40,
                                    borderStyle: "solid",
                                    paddingHorizontal: 12,
                                    paddingVertical: 8,
                                    width: "100%",
                                  }}
                                  autocompletionRequest={
                                    {
                                      // types: ['postal_code'],
                                    }
                                  }
                                  onSelect={(result) => {
                                    const { place_id, description } = result;

                                    props.setFieldValue(
                                      "billing_address.address",
                                      description,
                                      true
                                    );
                                    geocodeByPlaceId(place_id)
                                      .then((results) => {
                                        return getLatLng(results[0]);
                                      })
                                      .then(({ lat, lng }) => {
                                        props.setFieldValue(
                                          "billing_address.location.latitude",
                                          lat,
                                          true
                                        );
                                        props.setFieldValue(
                                          "billing_address.location.longitude",
                                          lng,
                                          true
                                        );
                                      });
                                  }}
                                  onLoadFailed={(error) =>
                                    console.error(
                                      "Could not inject Google script"
                                    )
                                  }
                                />
                              </Col>
                            </FormGroup>
                          </fieldset>

                          <legend>
                            {t("AddCompanyPage.add_company_facturation_label", {
                              lng: i18n.language,
                            })}
                          </legend>
                          {/*
                                                        }
                                                        <fieldset>
                                                            <FormGroup>
                                                                <ControlLabel className="col-sm-2">
                                                                    SOUS CONTRAT
                                                                </ControlLabel>
                                                                <Col sm={10}>
                                                                    <Col md={1}>
                                                                        <Radio
                                                                            number="1"
                                                                            option="1"
                                                                            name="radioVariant"
                                                                            //onChange={this.handleRadio}
                                                                            checked={this.state.radioVariant === "2"}
                                                                            label="Oui"
                                                                        />
                                                                    </Col>

                                                                    <Col md={2}>
                                                                        <Radio
                                                                            number="2"
                                                                            option="2"
                                                                            name="radioVariant"
                                                                            //onChange={this.handleRadio}
                                                                            //checked={this.state.radioVariant === "2"}
                                                                            label="Non"
                                                                        />
                                                                    </Col>

                                                                </Col>
                                                            </FormGroup>
                                                        </fieldset>
                                                        {
                                                            */}
                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t(
                                  "AddCompanyPage.add_company_billing_type_label",
                                  { lng: i18n.language }
                                )}
                              </ControlLabel>
                              <Col sm={10}>
                                <Col md={1}>
                                  <Radio
                                    number="3"
                                    option="3"
                                    name="radioVariant2"
                                    onChange={(e) => {
                                      props.handleChange(
                                        "billing.business_model"
                                      );
                                      props.setFieldValue(
                                        "billing.business_model",
                                        "FLAT",
                                        true
                                      );
                                    }}
                                    checked={
                                      props.values.billing.business_model ===
                                      "FLAT"
                                    }
                                    label="Fixe"
                                  />
                                </Col>

                                <Col md={1}>
                                  <Radio
                                    number="4"
                                    option="4"
                                    name="radioVariant2"
                                    onChange={(e) => {
                                      props.handleChange(
                                        "billing.business_model"
                                      );
                                      props.setFieldValue(
                                        "billing.business_model",
                                        "PERCENTAGE",
                                        true
                                      );
                                    }}
                                    checked={
                                      props.values.billing.business_model ===
                                      "PERCENTAGE"
                                    }
                                    label="%"
                                  />
                                </Col>
                              </Col>
                            </FormGroup>
                          </fieldset>
                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t(
                                  "AddCompanyPage.add_company_billing_amount_label",
                                  { lng: i18n.language }
                                )}
                              </ControlLabel>
                              <Col sm={10}>
                                <FormControl
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    _.has(errors, "billing") &&
                                    _.has(errors, "billing.amount") &&
                                    _.has(touched, "billing") &&
                                    _.has(touched, "billing.amount")
                                      ? "error"
                                      : ""
                                  }
                                  onChange={props.handleChange(
                                    "billing.amount"
                                  )}
                                  onInput={() =>
                                    props.setFieldTouched(
                                      "billing.amount",
                                      true,
                                      true
                                    )
                                  }
                                  onBlur={(e) => {
                                    const { value } = e.currentTarget;
                                    props.setFieldValue(
                                      "billing.amount",
                                      value,
                                      true
                                    );
                                  }}
                                  value={props.values.billing.amount}
                                />
                              </Col>
                            </FormGroup>
                          </fieldset>

                          <legend>
                            {" "}
                            {t("AddCompanyPage.add_company_rib_title", {
                              lng: i18n.language,
                            })}
                          </legend>
                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t(
                                  "AddCompanyPage.add_company_rib_number_label",
                                  { lng: i18n.language }
                                )}
                              </ControlLabel>
                              <Col sm={10}>
                                <FormControl
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    _.has(errors, "rib") &&
                                    _.has(errors, "rib.number") &&
                                    _.has(touched, "rib") &&
                                    _.has(touched, "rib.number")
                                      ? "error"
                                      : ""
                                  }
                                  onChange={props.handleChange("rib.number")}
                                  onInput={() =>
                                    props.setFieldTouched(
                                      "rib.number",
                                      true,
                                      true
                                    )
                                  }
                                  onBlur={(e) => {
                                    const { value } = e.currentTarget;
                                    props.setFieldValue(
                                      "rib.number",
                                      value,
                                      true
                                    );
                                  }}
                                  value={props.values.rib.number}
                                />
                              </Col>
                            </FormGroup>
                          </fieldset>
                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t("AddCompanyPage.add_company_rib_src_label", {
                                  lng: i18n.language,
                                })}
                              </ControlLabel>
                              <Col sm={10}>
                                <FormControl
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    _.has(errors, "rib") &&
                                    _.has(errors, "rib.src") &&
                                    _.has(touched, "rib") &&
                                    _.has(touched, "rib.src")
                                      ? "error"
                                      : ""
                                  }
                                  onChange={props.handleChange("rib.src")}
                                  onInput={() =>
                                    props.setFieldTouched("rib.src", true, true)
                                  }
                                  onBlur={(e) => {
                                    const { value } = e.currentTarget;
                                    props.setFieldValue("rib.src", value, true);
                                  }}
                                  value={props.values.rib.src}
                                />
                              </Col>
                            </FormGroup>
                          </fieldset>

                          <legend>
                            {" "}
                            {t("AddCompanyPage.add_company_contact_title", {
                              lng: i18n.language,
                            })}
                          </legend>
                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t(
                                  "AddCompanyPage.add_company_contact_firstname_label",
                                  { lng: i18n.language }
                                )}
                              </ControlLabel>
                              <Col sm={10}>
                                <FormControl
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    _.has(errors, "contact") &&
                                    _.has(errors, "contact.firstname") &&
                                    _.has(touched, "contact") &&
                                    _.has(touched, "contact.firstname")
                                      ? "error"
                                      : ""
                                  }
                                  onChange={props.handleChange(
                                    "contact.firstname"
                                  )}
                                  onInput={() =>
                                    props.setFieldTouched(
                                      "contact.firstname",
                                      true,
                                      true
                                    )
                                  }
                                  onBlur={(e) => {
                                    const { value } = e.currentTarget;
                                    props.setFieldValue(
                                      "contact.firstname",
                                      value,
                                      true
                                    );
                                  }}
                                  value={props.values.contact.firstname}
                                />
                              </Col>
                            </FormGroup>
                          </fieldset>
                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t(
                                  "AddCompanyPage.add_company_contact_lastname_label",
                                  { lng: i18n.language }
                                )}
                              </ControlLabel>
                              <Col sm={10}>
                                <FormControl
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    _.has(errors, "contact") &&
                                    _.has(errors, "contact.lastname") &&
                                    _.has(touched, "contact") &&
                                    _.has(touched, "contact.lastname")
                                      ? "error"
                                      : ""
                                  }
                                  onChange={props.handleChange(
                                    "contact.lastname"
                                  )}
                                  onInput={() =>
                                    props.setFieldTouched(
                                      "contact.lastname",
                                      true,
                                      true
                                    )
                                  }
                                  onBlur={(e) => {
                                    const { value } = e.currentTarget;
                                    props.setFieldValue(
                                      "contact.lastname",
                                      value,
                                      true
                                    );
                                  }}
                                  value={props.values.contact.lastname}
                                />
                              </Col>
                            </FormGroup>
                          </fieldset>
                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t(
                                  "AddCompanyPage.add_company_contact_phone_label",
                                  { lng: i18n.language }
                                )}
                              </ControlLabel>
                              <Col sm={10}>
                                <FormControl
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    _.has(errors, "contact") &&
                                    _.has(errors, "contact.phones") &&
                                    _.has(errors, "contact.phones.mobile") &&
                                    _.has(touched, "contact") &&
                                    _.has(touched, "contact.phones") &&
                                    _.has(touched, "contact.phones.mobile")
                                      ? "error"
                                      : ""
                                  }
                                  onChange={props.handleChange(
                                    "contact.phones.mobile"
                                  )}
                                  onInput={() =>
                                    props.setFieldTouched(
                                      "contact.phones.mobile",
                                      true,
                                      true
                                    )
                                  }
                                  onBlur={(e) => {
                                    const { value } = e.currentTarget;
                                    props.setFieldValue(
                                      "contact.phones.mobile",
                                      value,
                                      true
                                    );
                                  }}
                                  value={props.values.contact.phones.mobile}
                                />
                              </Col>
                            </FormGroup>
                          </fieldset>
                          <fieldset>
                            <FormGroup>
                              <ControlLabel className="col-sm-2">
                                {t("AddCompanyPage.add_company_email_label", {
                                  lng: i18n.language,
                                })}
                              </ControlLabel>
                              <Col sm={10}>
                                <FormControl
                                  type="text"
                                  autoComplete="off"
                                  className={
                                    _.has(errors, "contact") &&
                                    _.has(errors, "contact.email") &&
                                    _.has(touched, "contact") &&
                                    _.has(touched, "contact.email")
                                      ? "error"
                                      : ""
                                  }
                                  onChange={props.handleChange("contact.email")}
                                  onInput={() =>
                                    props.setFieldTouched(
                                      "contact.email",
                                      true,
                                      true
                                    )
                                  }
                                  onBlur={(e) => {
                                    const { value } = e.currentTarget;
                                    props.setFieldValue(
                                      "contact.email",
                                      value,
                                      true
                                    );
                                  }}
                                  value={props.values.contact.email}
                                />
                              </Col>
                            </FormGroup>
                          </fieldset>

                          <Button
                            bsStyle="info"
                            disabled={props.isSubmitting}
                            fill
                            onClick={() => {
                              props.handleSubmit();
                            }}
                          >
                            {t("AddCompanyPage.add_company_save_button", {
                              lng: i18n.language,
                            })}
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                }
              ></Card>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    action: state.backOfficeCreateCompany.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeCreateCompanyRequest: (...args) =>
      dispatch(AddCompaniesActions.backOfficeCreateCompanyRequest(...args)),
  };
};

const AddCompanyRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCompany);
export default withTranslation(["Private", "Common"], { wait: true })(
  AddCompanyRedux
);

import React from "react";

import LoginPage from "views/public/LoginPage";

// import Page1 from 'views/public/page1'

import Companies from "views/private/companies";
import AddCompany from "views/private/add-company";
import HopitalInfos from "views/private/company-infos/index";
import prestatiresIcon from "assets/img/prestatires-icon.svg";
import ambulanceIcon from "assets/img/ambulance-icon.svg";
import Transporters from "views/private/transporters";
import Transporter from "views/private/transporter-infos/index";
import NewTransporter from "views/private/add-transporter";
import Taxi from "views/private/taxi/TaxiMainView";
import NewTaxi from "views/private/taxi/add-taxi";

import ServiceDetails from "views/private/company-service/index";

import TaxiInfos from "views/private/taxi/taxi-infos/index";

import Samu from "views/private/Samu";
import NewSamu from "views/private/samu/add-samu";
import samuDetails from "views/private/samu/index";
import Rides from "views/private/Rides";

const DrawSVGIcon = (svg, name) => {
  return (
    <>
      <img src={svg} alt="" />
      <p style={{ display: "inline-block", marginLeft: 10 }}>{name}</p>
    </>
  );
};

export const backOfficeMainRoute = "backoffice";

const routes = [
  {
    path: "/hopitaux",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Établissement",
    iconSource: prestatiresIcon,
    icon: DrawSVGIcon,
    component: Companies,
  },
  {
    path: "/add-hospital",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Ajouter le prestatire",
    // icon: patientIcon,
    hidden: true,
    component: AddCompany,
  },
  {
    path: "/hosp-company/:id",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Détails",
    // icon: patientIcon,
    hidden: true,
    component: HopitalInfos,
  },
  {
    path: "/hosp-service/:id",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Détails",
    // icon: patientIcon,
    hidden: true,
    component: ServiceDetails,
  },
  {
    path: "/transporteurs",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Transporteurs",
    iconSource: ambulanceIcon,
    // icon: ambulanceIcon,
    icon: DrawSVGIcon,
    component: Transporters,
  },
  {
    path: "/taxi",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Taxi",
    //icon: ambulanceIcon,
    icon: "pe-7s-car",
    component: Taxi,
  },
  {
    path: "/amb-company/:id",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Détails",
    // icon: patientIcon,
    hidden: true,
    component: Transporter,
  },
  {
    path: "/add-prestatire",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Ajouter un prestatire",
    // icon: patientIcon,
    hidden: true,
    component: NewTransporter,
  },
  {
    path: "/add-taxi",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Ajouter un Taxi",
    // icon: patientIcon,
    hidden: true,
    component: NewTaxi,
  },
  {
    path: "/taxi-infos/:id",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "TaxiDétails",
    hidden: true,
    component: TaxiInfos,
  },
  {
    path: "/samu",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Samu",
    iconSource: prestatiresIcon,
    icon: DrawSVGIcon,
    component: Samu,
  },
  {
    path: "/rides",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Rides",
    iconSource: prestatiresIcon,
    icon: "pe-7s-note2",
    component: Rides,
  },
  {
    path: "/add-samu",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Ajouter un samu",
    // icon: patientIcon,
    hidden: true,
    component: NewSamu,
  },
  {
    path: "/samu-info/:id",
    layout: "/private",
    main: `/${backOfficeMainRoute}`,
    name: "Détails",
    // icon: patientIcon,
    hidden: true,
    component: samuDetails,
  },
  {
    redirect: true,
    layout: "/private",
    path: `/`,
    pathTo: "/hopitaux",
  },
  {
    collapse: true,
    path: `/pages`,
    name: "Pages",
    state: "openPages",
    icon: "pe-7s-gift",
    views: [
      {
        path: "/login-page",
        layout: "/public",
        name: "Page login",
        mini: "LP",
        component: LoginPage,
      },

      {
        redirect: true,
        layout: "/public",
        path: `/`,
        pathTo: "/login-page",
      },
    ],
  },
];

export default routes;

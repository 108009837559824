import React, { Component } from "react";
import { connect } from "react-redux";
import CompanyDetailsActions from "reducers/company/company-details";

import { Grid, Row, Col, Tab, Nav, NavItem } from "react-bootstrap";

import Loader from "react-loader-spinner";
import _ from "lodash";

import InformationsTab from "./company-details";
// import PatiensTab from "./company-patient"
// import FacturesTab from "./company-facture"
// import HistoriqueTab from "./company-historique"
import UsersTab from "./company-users";
import ServicesTab from "./company-service-infos";
import { withTranslation } from "react-i18next";

class HopitalInfos extends Component {
  constructor(props) {
    super(props);
  }

  renderHospital() {
    const { history } = this.props;

    const companyId = this.props.match.params.id;
    const { details, users } = this.props.company;
    const { name = "", services } = details;
    const { action } = this.props;
    const { t, i18n } = this.props;

    const { error } = action;
    return (
      <Row>
        <Col md={12}>
          <h1 className="title text-center">{name}</h1>
          <Tab.Container id="nav-with-icons" defaultActiveKey="informations">
            <div>
              <div className="nav-container">
                <Nav bsStyle="tabs" bsClass="nav nav-icons">
                  <NavItem eventKey="informations">
                    <i className="fa fa-info-circle" />
                    <br />{" "}
                    {t("companyDetailsPage.company_details_info_nav_title", {
                      lng: i18n.language,
                    })}
                  </NavItem>
                  <NavItem eventKey="users">
                    <i className="fa fa-users" />
                    <br />{" "}
                    {t("companyDetailsPage.company_details_users_nav_title", {
                      lng: i18n.language,
                    })}
                  </NavItem>
                  <NavItem eventKey="services">
                    <i className="fa fa-hospital-o" />
                    <br />{" "}
                    {t(
                      "companyDetailsPage.company_details_services_nav_title",
                      { lng: i18n.language }
                    )}
                  </NavItem>

                  {/*   <NavItem eventKey="patiens">
                                        <i className="fa fa-wheelchair" />
                                        <br /> Patients
                                    </NavItem>
                                    <NavItem eventKey="factures">
                                        <i className="fa fa-file" />
                                        <br /> Factures
                                    </NavItem>
                                    <NavItem eventKey="historique">
                                        <i className="fa fa-history" />
                                        <br /> Historique
                                    </NavItem> */}
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="informations">
                  <InformationsTab {...{ details }} />
                </Tab.Pane>
                <Tab.Pane eventKey="users">
                  <UsersTab
                    {...{ companyId, history }}
                    {...{ admins: users }}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="services">
                  <ServicesTab {...{ companyId, services, history, error }} />
                </Tab.Pane>

                {/*     <Tab.Pane eventKey="patiens">
                                    <PatiensTab />
                                </Tab.Pane>
                                <Tab.Pane eventKey="factures">
                                    <FacturesTab />
                                </Tab.Pane>
                                <Tab.Pane eventKey="historique">
                                    <HistoriqueTab />
                                </Tab.Pane> */}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Col>
      </Row>
    );
  }

  componentDidMount() {
    const {
      match,
      backOfficeGetCompanyDetailsRequest,
      backOfficeCompanyUsersRequest,
    } = this.props;
    const companyId = match.params.id;

    backOfficeGetCompanyDetailsRequest(companyId);
    backOfficeCompanyUsersRequest(companyId);
  }

  render() {
    const { action, company } = this.props;
    const { loading } = action;
    return (
      <div className="main-content">
        <Grid fluid>
          <Row className="text-center">
            <Col md={6} sm={6} mdOffset={3} smOffset={3}>
              {/* {
                                error != "" ? <Alert bsStyle="danger">
                                    <span>{error}</span>
                                </Alert> : null
                            } */}
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={100}
                width={100}
                visible={loading}
              />
            </Col>
          </Row>

          {_.isNull(company.details) ? null : this.renderHospital()}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.backOfficeGetDetailsCompany.company.asMutable({
      deep: true,
    }),
    action: state.backOfficeGetDetailsCompany.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeGetCompanyDetailsRequest: (...args) =>
      dispatch(
        CompanyDetailsActions.backOfficeGetCompanyDetailsRequest(...args)
      ),
    backOfficeCompanyUsersRequest: (...args) =>
      dispatch(CompanyDetailsActions.backOfficeCompanyUsersRequest(...args)),
  };
};

const CompanyDetailsRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(HopitalInfos);
export default withTranslation(["Private", "Common"], { wait: true })(
  CompanyDetailsRedux
);

import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactTable from "react-table";
import _ from "lodash";
import { Row, Col, Tab, Nav, NavItem } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton";
import SamuSectorsActions from "reducers/samu/samu-sectors";
import SamuSectorModal from "./samu-sector-modal";
import moment from "moment";

class SectorsTab extends Component {
  constructor(props) {
    super(props);

    this.onPressDeleteUserButton = this.onPressDeleteUserButton.bind(this);

    this.state = {
      alert: null,
      showSectorModal: false,
      selectedSector: null,
    };
  }

  componentDidMount() {
    this.props.sectorsRequest(this.props.companyId);
  }

  onPressDeleteUserButton(props) {
    const { backOfficeDeleteUserCompanyRequest } = this.props;
    const { companyId } = this.props;

    backOfficeDeleteUserCompanyRequest(props._id, companyId);

    this.hideAlert();
  }

  warningWithConfirmAndCancelMessage(props) {
    const { t, i18n } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Suppression"
          onConfirm={() => this.onPressDeleteUserButton(props)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Oui supprimer"
          cancelBtnText="Non"
          showCancel
        >
          {t("samuSectorsPage.delete_company_user_message", {
            lng: i18n.language,
          })}
        </SweetAlert>
      ),
    });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  renderSectors() {
    const { t, i18n, sectors } = this.props;

    return (
      <ReactTable
        // filterable
        data={sectors}
        columns={[
          {
            Header: (
              <>
                {" "}
                {t("samuSectorsPage.sector_name_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "sectorName",
          },
          {
            Header: (
              <>
                {" "}
                {t("samuSectorsPage.cities_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "cities",
            Cell: (prop) => (
              <ul>
                {prop.original.cities.map((city, index) => (
                  <li key={index}>{city}</li>
                ))}
              </ul>
            ),
          },
          {
            Header: (
              <>
                {" "}
                {t("samuSectorsPage.created_at_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "createdAt",
            Cell: (prop) => (
              <div>
                {moment(new Date(prop.original.createdAt)).format("DD/MM/YYYY")}
              </div>
            ),
          },
          {
            Header: (
              <> {t("Common:ReactTable.action", { lng: i18n.language })}</>
            ),
            accessor: "actions",
            sortable: false,
            filterable: false,
            Cell: (prop) => {
              return (
                <div className="actions-right">
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="view"
                    //onClick={() => history.push(`amb-company/${_id}/`)}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="edit"
                    onClick={() =>
                      this.setState({
                        showSectorModal: true,
                        selectedSector: prop.original,
                      })
                    }
                  >
                    <i className="fa fa-pencil" />
                  </Button>{" "}
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="delete"
                    onClick={() => {
                      this.warningWithConfirmAndCancelMessage(prop.original);
                    }}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                </div>
              );
            },
          },
        ]}
        previousText={t("Common:ReactTable.previous_text_label", {
          lng: i18n.language,
        })}
        nextText={t("Common:ReactTable.next_text_label", {
          lng: i18n.language,
        })}
        ofText={t("Common:ReactTable.off_Text_label", { lng: i18n.language })}
        rowsText={t("Common:ReactTable.rows_Text_label", {
          lng: i18n.language,
        })}
        loadingText={t("Common:ReactTable.loading_Text_label", {
          lng: i18n.language,
        })}
        noDataText={t("Common:ReactTable.no_data_Text_label", {
          lng: i18n.language,
        })}
        showPaginationTop={false}
        showPaginationBottom
        className="-striped -highlight"
      />
    );
  }

  render() {
    const { t, i18n } = this.props;
    const { showSectorModal, selectedSector } = this.state;

    return (
      <Card
        title=""
        category=""
        content={
          <Tab.Container id="tabs-with-dropdown" defaultActiveKey="admins">
            <Row className="clearfix">
              <Col sm={12}>
                <Nav bsStyle="tabs">
                  {this.state.alert}
                  <NavItem eventKey="admins">
                    {t("samuSectorsPage.company_nav_sector_label", {
                      lng: i18n.language,
                    })}
                  </NavItem>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content animation>
                  <Tab.Pane eventKey="admins">
                    <SamuSectorModal
                      {...{ ...this.props }}
                      show={showSectorModal}
                      onHide={() => {
                        this.setState({ showSectorModal: false });
                      }}
                      sector={selectedSector}
                    />
                    <Button
                      bsStyle="success"
                      fill
                      wd
                      onClick={() => {
                        this.setState({
                          showSectorModal: true,
                          selectedSector: null,
                        });
                      }}
                    >
                      {t("samuSectorsPage.samu_sectors_add_sector_button", {
                        lng: i18n.language,
                      })}
                    </Button>

                    {this.renderSectors()}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    action: state.backOfficeSectors.action.asMutable({ deep: true }),
    sectors: state.backOfficeSectors.sectors.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sectorsRequest: (...args) =>
      dispatch(SamuSectorsActions.sectorsRequest(...args)),
    createRequest: (...args) =>
      dispatch(SamuSectorsActions.createRequest(...args)),
    updateRequest: (...args) =>
      dispatch(SamuSectorsActions.updateRequest(...args)),
    // addCityRequest: (...args) => dispatch(SamuSectorsActions.addCityRequest(...args)),
    // removeCityRequest: (...args) => dispatch(SamuSectorsActions.removeCityRequest(...args)),
    // modifyNameRequest: (...args) => dispatch(SamuSectorsActions.modifyNameRequest(...args)),
  };
};

const CompanySectorsRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(SectorsTab);
export default withTranslation(["Private", "Common"], { wait: true })(
  CompanySectorsRedux
);

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeCreateVehicleRequest: ["vehicle", "companyId", "callback"],
  backOfficeCreateVehicleRequestSuccess: ["vehicle"],
  backOfficeCreateVehicleRequestFailure: ["error"],
  backOfficeClearError: null,
});

export const AddTransporterVehicleTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  action: {
    loading: false,
    error: "",
  },
});

export const backOfficeClearError = (state, action) => {
  return Immutable.setIn(state, ["action", "error"], "");
};

export const backOfficeCreateVehicleRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeCreateVehicleRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const backOfficeCreateVehicleRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_CREATE_VEHICLE_REQUEST]: backOfficeCreateVehicleRequest,
  [Types.BACK_OFFICE_CREATE_VEHICLE_REQUEST_SUCCESS]:
    backOfficeCreateVehicleRequestSuccess,
  [Types.BACK_OFFICE_CREATE_VEHICLE_REQUEST_FAILURE]:
    backOfficeCreateVehicleRequestFailure,
});

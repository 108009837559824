import { put, takeLatest } from "redux-saga/effects";
import GetRidesActions, { GetRidesTypes } from "reducers/ride";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* getRidesAsyncRequest({ page, limit, filters }) {
  const query = `
  query {
    BackofficeRides(page: ${page}, limit: ${limit}, filters: ${JSON.stringify(
    filters
  ).replaceAll('"', "")})
  }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const rides = data.BackofficeRides;
    yield put(GetRidesActions.getRidesRequestSuccess(rides));
  } catch (error) {
    const { message = "" } = error.response.errors[0];

    yield errorHandler(message, GetRidesActions.getRidesRequestFailure);
  }
}
export default [
  takeLatest(GetRidesTypes.GET_RIDES_REQUEST, getRidesAsyncRequest),
];

import React, { Component } from "react";
import { connect } from "react-redux";
import TransporterDetailsActions from "reducers/transporter/transporter-details";
import Select from "react-select";
import {
  Row,
  Col,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import _ from "lodash";
import { Formik } from "formik";
import { string, object } from "yup";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import { withTranslation } from "react-i18next";

const validationSchema = object().shape({
  plate: string().required("required"),
  model: string().required("required"),
  make: string().required("required"),
  category: string().required("required"),
});

class NewVehicle extends Component {
  constructor(props) {
    super(props);

    this.formikProps = null;
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {}

  onSubmitForm(values, { resetForm, setSubmitting }) {
    const { vehicle, companyId } = this.props;
    let id = null;
    if (!_.isNull(vehicle)) {
      const { _id } = vehicle;
      id = _id;
    }
    const { backOfficeModifyVehicleRequest, onHide } = this.props;
    const { options, ...rest } = values;
    const _vehicleOptions = _.reduce(
      options,
      (acc, v, k) => {
        return v ? [...acc, k] : acc;
      },
      []
    );

    backOfficeModifyVehicleRequest(
      { options: _vehicleOptions, ...rest },
      id,
      companyId,
      (error) => {
        resetForm();
        setSubmitting(false);
        onHide();
      }
    );
  }

  render() {
    const { t, i18n } = this.props;
    const { vehicle } = this.props;
    let modelVehicle = "";
    let makeVehicle = "";
    let plateVehicle = "";
    let optionsVehicle = [];
    let categoryVehicle = "";
    let oxygenVehicle = false;
    let childSafetySeatVehicle = false;
    let perfusionVehicle = false;
    let infectieuxVehicle = false;
    let tracheoVehicle = false;
    let pousseSeringueVehicle = false;
    let isolementVehicle = false;

    if (!_.isNull(vehicle)) {
      const { model, make, plate, options, category } = vehicle;
      modelVehicle = model;
      makeVehicle = make;
      plateVehicle = plate;
      optionsVehicle = options;
      categoryVehicle = category;
    }

    const _vehicleOptions = _.reduce(
      optionsVehicle,
      (acc, v, k) => {
        if (v === "OXYGEN") {
          oxygenVehicle = true;
        }
        if (v === "CHILD_SAFETY_SEAT") {
          childSafetySeatVehicle = true;
        }
        if (v === "PERFUSION") {
          perfusionVehicle = true;
        }
        if (v === "INFECTIEUX") {
          infectieuxVehicle = true;
        }
        if (v === "TRACHEO") {
          tracheoVehicle = true;
        }
        if (v === "POUSSE_SERINGUE") {
          pousseSeringueVehicle = true;
        }
        if (v === "ISOLEMENT") {
          isolementVehicle = true;
        }
      },
      []
    );
    return (
      <Formik
        enableReinitialize
        initialValues={{
          plate: plateVehicle,
          model: modelVehicle,
          make: makeVehicle,
          category: categoryVehicle,
          options: {
            OXYGEN: oxygenVehicle,
            CHILD_SAFETY_SEAT: childSafetySeatVehicle,
            PERFUSION: perfusionVehicle,
            INFECTIEUX: infectieuxVehicle,
            TRACHEO: tracheoVehicle,
            POUSSE_SERINGUE: pousseSeringueVehicle,
            ISOLEMENT: isolementVehicle,
          },
        }}
        validationSchema={validationSchema}
        onSubmit={this.onSubmitForm}
      >
        {(props) => {
          const { errors, touched } = props;

          return (
            <Modal
              {...this.props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered="true"
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t("transporterVehiclesPage.modify_popup_headline", {
                    lng: i18n.language,
                  })}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form horizontal>
                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {" "}
                      {t("transporterVehiclesPage.add_user_popup_make_label", {
                        lng: i18n.language,
                      })}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "transporterVehiclesPage.add_user_popup_placeholder_make",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "make") && _.has(touched, "make")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("make")}
                        onInput={() =>
                          props.setFieldTouched("make", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("make", value, true);
                        }}
                        value={props.values.make}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {t("transporterVehiclesPage.add_user_popup_model_label", {
                        lng: i18n.language,
                      })}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "transporterVehiclesPage.add_user_popup_placeholder_model",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "model") && _.has(touched, "model")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("model")}
                        onInput={() =>
                          props.setFieldTouched("model", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("model", value, true);
                        }}
                        value={props.values.model}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {t("transporterVehiclesPage.add_user_popup_plate_label", {
                        lng: i18n.language,
                      })}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "transporterVehiclesPage.add_user_popup_placeholder_plate",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "plate") && _.has(touched, "plate")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("plate")}
                        onInput={() =>
                          props.setFieldTouched("plate", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("plate", value, true);
                        }}
                        value={props.values.plate}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-sm-3">
                      {t(
                        "transporterVehiclesPage.add_user_popup_category_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <Select
                        className={
                          _.has(errors, "category") &&
                          _.has(touched, "category")
                            ? "react-select error"
                            : "react-select primary"
                        }
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={{
                          value: props.values.category,
                          label: props.values.category,
                        }}
                        onChange={({ value }) => {
                          props.handleChange("category");
                          props.setFieldValue("category", value, true);
                        }}
                        options={[
                          { value: "TAXI", label: "TAXI" },
                          { value: "VSL", label: "VSL" },
                          { value: "AMBULANCE", label: "AMBULANCE" },
                          { value: "BARIATRIQUE", label: "BARIATRIQUE" },
                          { value: "COUVEUSE", label: "COUVEUSE" },
                        ]}
                        placeholder={t(
                          "transporterVehiclesPage.add_user_popup_placeholder_category",
                          { lng: i18n.language }
                        )}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel className="col-sm-3">
                      {t(
                        "transporterVehiclesPage.add_user_popup_options_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <Row>
                        <Col md={3}>
                          <Checkbox
                            inline
                            number="0"
                            checked={props.values.options.OXYGEN}
                            label={t(
                              "transporterVehiclesPage.add_user_popup_OXYGEN_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.OXYGEN");
                              props.setFieldValue(
                                "options.OXYGEN",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Checkbox
                            inline
                            number="1"
                            checked={props.values.options.CHILD_SAFETY_SEAT}
                            label={t(
                              "transporterVehiclesPage.add_user_popup_CHILD_SAFETY_SEAT_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.CHILD_SAFETY_SEAT");
                              props.setFieldValue(
                                "options.CHILD_SAFETY_SEAT",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>

                        <Col md={3}>
                          <Checkbox
                            inline
                            number="2"
                            checked={props.values.options.PERFUSION}
                            label={t(
                              "transporterVehiclesPage.add_user_popup_PERFUSION_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.PERFUSION");
                              props.setFieldValue(
                                "options.PERFUSION",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>

                        <Col md={3}>
                          <Checkbox
                            inline
                            number="4"
                            checked={props.values.options.INFECTIEUX}
                            label={t(
                              "transporterVehiclesPage.add_user_popup_INFECTIEUX_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.INFECTIEUX");
                              props.setFieldValue(
                                "options.INFECTIEUX",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <Checkbox
                            inline
                            number="5"
                            checked={props.values.options.TRACHEO}
                            label={t(
                              "transporterVehiclesPage.add_user_popup_TRACHEO_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.TRACHEO");
                              props.setFieldValue(
                                "options.TRACHEO",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>

                        <Col md={3}>
                          <Checkbox
                            inline
                            number="6"
                            checked={props.values.options.POUSSE_SERINGUE}
                            label={t(
                              "transporterVehiclesPage.add_user_popup_POUSSE_SERINGUE_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.POUSSE_SERINGUE");
                              props.setFieldValue(
                                "options.POUSSE_SERINGUE",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>

                        <Col md={3}>
                          <Checkbox
                            inline
                            number="7"
                            checked={props.values.options.ISOLEMENT}
                            label={t(
                              "transporterVehiclesPage.add_user_popup_POUSSE_ISOLEMENT_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.ISOLEMENT");
                              props.setFieldValue(
                                "options.ISOLEMENT",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  disabled={props.isSubmitting}
                  onClick={this.props.onHide}
                >
                  Fermer
                </Button>
                <Button
                  bsStyle="success"
                  fill
                  disabled={props.isSubmitting}
                  onClick={() => {
                    props.handleSubmit();
                  }}
                >
                  Valider
                </Button>
              </Modal.Footer>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeModifyVehicleRequest: (...args) =>
      dispatch(
        TransporterDetailsActions.backOfficeModifyVehicleRequest(...args)
      ),
  };
};

const addVehiclePopUpRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewVehicle);
export default withTranslation(["Private", "Common"], { wait: true })(
  addVehiclePopUpRedux
);

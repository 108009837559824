import React, { Component } from "react";
import { connect } from "react-redux";
import AddTransporterVehicleActions from "reducers/transporter/add-transporter-vehicle";
import Select from "react-select";
import {
  Row,
  Alert,
  Col,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
} from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";
import _ from "lodash";
import { Formik } from "formik";
import { string, object } from "yup";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import { withTranslation } from "react-i18next";

const validationSchema = object().shape({
  plate: string().required("required"),
  model: string().required("required"),
  make: string().required("required"),
  category: string().required("required"),
});

class NewVehicle extends Component {
  constructor(props) {
    super(props);

    this.formikProps = null;
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {}

  onSubmitForm(values, { resetForm, setSubmitting }) {
    const { backOfficeCreateVehicleRequest, companyId, onHide } = this.props;
    const { options, ...rest } = values;

    const _vehicleOptions = _.reduce(
      options,
      (acc, v, k) => {
        return v ? [...acc, k] : acc;
      },
      []
    );

    backOfficeCreateVehicleRequest(
      { options: _vehicleOptions, ...rest },
      companyId,
      (error) => {
        if (error) {
          return;
        }

        setSubmitting(false);
        resetForm();

        onHide();
      }
    );
  }

  render() {
    const { t, i18n } = this.props;

    const { action } = this.props;

    const { error } = action;
    return (
      <Formik
        initialValues={{
          plate: "",
          model: "",
          make: "",
          category: "",
          options: {
            oxygen: false,
            child_safety_seat: false,
            perfusion: false,
            infectieux: false,
            tracheo: false,
            pousse_seringue: false,
            isolement: false,
          },
        }}
        validationSchema={validationSchema}
        onSubmit={this.onSubmitForm}
      >
        {(props) => {
          const { errors, touched } = props;

          return (
            <Modal
              {...this.props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered="true"
              backdrop="static"
            >
              {error !== "" ? (
                <Alert bsStyle="danger">
                  <span>{error}</span>
                </Alert>
              ) : null}
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t(
                    "transporterAddVehiclePopUp.add_vehicle_popup_title_headline",
                    { lng: i18n.language }
                  )}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form horizontal>
                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {" "}
                      {t(
                        "transporterAddVehiclePopUp.add_user_popup_make_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "transporterAddVehiclePopUp.add_user_popup_placeholder_make",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "make") && _.has(touched, "make")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("make")}
                        onInput={() =>
                          props.setFieldTouched("make", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("make", value, true);
                        }}
                        value={props.values.make}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {t(
                        "transporterAddVehiclePopUp.add_user_popup_model_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "transporterAddVehiclePopUp.add_user_popup_placeholder_model",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "model") && _.has(touched, "model")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("model")}
                        onInput={() =>
                          props.setFieldTouched("model", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("model", value, true);
                        }}
                        value={props.values.model}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {t(
                        "transporterAddVehiclePopUp.add_user_popup_plate_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "transporterAddVehiclePopUp.add_user_popup_placeholder_plate",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "plate") && _.has(touched, "plate")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("plate")}
                        onInput={() =>
                          props.setFieldTouched("plate", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("plate", value, true);
                        }}
                        value={props.values.plate}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-sm-3">
                      {t(
                        "transporterAddVehiclePopUp.add_user_popup_category_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <Select
                        className={
                          _.has(errors, "category") &&
                          _.has(touched, "category")
                            ? "react-select error"
                            : "react-select primary"
                        }
                        classNamePrefix="react-select"
                        name="singleSelect"
                        value={{
                          value: props.values.category,
                          label: props.values.category,
                        }}
                        onChange={({ value }) => {
                          props.handleChange("category");
                          props.setFieldValue("category", value, true);
                        }}
                        options={[
                          { value: "TAXI", label: "TAXI" },
                          { value: "VSL", label: "VSL" },
                          { value: "AMBULANCE", label: "AMBULANCE" },
                          { value: "BARIATRIQUE", label: "BARIATRIQUE" },
                          { value: "COUVEUSE", label: "COUVEUSE" },
                        ]}
                        placeholder={t(
                          "transporterAddVehiclePopUp.add_user_popup_placeholder_category",
                          { lng: i18n.language }
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-sm-3">
                      {t(
                        "transporterAddVehiclePopUp.add_user_popup_options_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <Row>
                        <Col md={3}>
                          <Checkbox
                            inline
                            number="0"
                            checked={props.values.options.OXYGEN}
                            label={t(
                              "transporterAddVehiclePopUp.add_user_popup_OXYGEN_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.OXYGEN");
                              props.setFieldValue(
                                "options.OXYGEN",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Checkbox
                            inline
                            number="1"
                            checked={props.values.options.CHILD_SAFETY_SEAT}
                            label={t(
                              "transporterAddVehiclePopUp.add_user_popup_CHILD_SAFETY_SEAT_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.CHILD_SAFETY_SEAT");
                              props.setFieldValue(
                                "options.CHILD_SAFETY_SEAT",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>

                        <Col md={3}>
                          <Checkbox
                            inline
                            number="2"
                            checked={props.values.options.PERFUSION}
                            label={t(
                              "transporterAddVehiclePopUp.add_user_popup_PERFUSION_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.PERFUSION");
                              props.setFieldValue(
                                "options.PERFUSION",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>

                        <Col md={3}>
                          <Checkbox
                            inline
                            number="4"
                            checked={props.values.options.INFECTIEUX}
                            label={t(
                              "transporterAddVehiclePopUp.add_user_popup_INFECTIEUX_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.INFECTIEUX");
                              props.setFieldValue(
                                "options.INFECTIEUX",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <Checkbox
                            inline
                            number="5"
                            checked={props.values.options.TRACHEO}
                            label={t(
                              "transporterAddVehiclePopUp.add_user_popup_TRACHEO_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.TRACHEO");
                              props.setFieldValue(
                                "options.TRACHEO",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>

                        <Col md={3}>
                          <Checkbox
                            inline
                            number="6"
                            checked={props.values.options.POUSSE_SERINGUE}
                            label={t(
                              "transporterAddVehiclePopUp.add_user_popup_POUSSE_SERINGUE_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.POUSSE_SERINGUE");
                              props.setFieldValue(
                                "options.POUSSE_SERINGUE",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>

                        <Col md={3}>
                          <Checkbox
                            inline
                            number="7"
                            checked={props.values.options.ISOLEMENT}
                            label={t(
                              "transporterAddVehiclePopUp.add_user_popup_POUSSE_ISOLEMENT_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.ISOLEMENT");
                              props.setFieldValue(
                                "options.ISOLEMENT",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  // disabled={props.isSubmitting}
                  onClick={() => {
                    props.resetForm();
                    this.props.backOfficeClearError();
                    this.props.onHide();
                  }}
                >
                  {" "}
                  {t("Common:footerPage.close", { lng: i18n.language })}
                </Button>
                <Button
                  bsStyle="success"
                  fill
                  // disabled={props.isSubmitting}
                  onClick={() => {
                    props.handleSubmit();
                  }}
                >
                  {t("Common:footerPage.save_changes", { lng: i18n.language })}
                </Button>
              </Modal.Footer>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    action: state.backOfficeAddVehicle.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeCreateVehicleRequest: (...args) =>
      dispatch(
        AddTransporterVehicleActions.backOfficeCreateVehicleRequest(...args)
      ),
    backOfficeClearError: (...args) =>
      dispatch(AddTransporterVehicleActions.backOfficeClearError(...args)),
  };
};

const tarnsporterVehiclesRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewVehicle);
export default withTranslation(["Private", "Common"], { wait: true })(
  tarnsporterVehiclesRedux
);

import React, { Component } from "react";
import { connect } from "react-redux";
import TransporterDetailsActions from "reducers/transporter/transporter-details";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import NewAmbuVehicle from "./add-transporter-vehicle";
import { withTranslation } from "react-i18next";
import ModifyVehicleReg from "./transporter-modify-vehicle";
import SweetAlert from "react-bootstrap-sweetalert";

class VehiclesTab extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.onPressDeleteUserButton = this.onPressDeleteUserButton.bind(this);

    this.state = {
      showAddNewVehicleModal: false,
      showModifyVehicleReg: false,
      vehicleToUpdate: null,
      alert: null,
    };
  }
  onPressDeleteUserButton(props) {
    const { backOfficeDeleteVehicleRequest } = this.props;
    const { companyId } = this.props;

    backOfficeDeleteVehicleRequest(props._id, companyId);

    this.hideAlert();
  }
  warningWithConfirmAndCancelMessage(props) {
    const { t, i18n } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Suppression"
          onConfirm={() => this.onPressDeleteUserButton(props)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Oui supprimer"
          cancelBtnText="Non"
          showCancel
        >
          {t("transporterUsersPage.delete_company_user_message", {
            lng: i18n.language,
          })}
        </SweetAlert>
      ),
    });
  }
  fetchData(state, instance) {
    const { page, pageSize } = state;
    const { backOfficeTransporterVehiclesRequest, companyId } = this.props;
    backOfficeTransporterVehiclesRequest(companyId, page + 1, pageSize);
  }
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  componentDidMount() {
    const { backOfficeTransporterVehiclesRequest, companyId, vehicles } =
      this.props;

    const { page, limit } = vehicles;

    backOfficeTransporterVehiclesRequest(companyId, page, limit);
  }

  renderVehicules() {
    const { vehicles, totalPages = 0, limit } = this.props.vehicles;

    const { t, i18n } = this.props;

    return (
      <ReactTable
        data={vehicles}
        manual
        onFetchData={this.fetchData.bind(this)}
        filterable
        columns={[
          {
            Header: (
              <>
                {" "}
                {t(
                  "transporterVehiclesPage.make_model_react_table_column_name",
                  { lng: i18n.language }
                )}
              </>
            ),
            accessor: "",
            Cell: (prop) => {
              const { make, model } = prop.original;

              return `${make} - ${model}`;
            },
          },
          {
            Header: (
              <>
                {" "}
                {t("transporterVehiclesPage.plate_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "",
            Cell: (prop) => {
              const { plate } = prop.original;
              const plateMajuscule = plate.toUpperCase();
              return `${plateMajuscule}`;
            },
          },
          {
            Header: (
              <>
                {" "}
                {t("transporterVehiclesPage.plate_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "category",
          },

          {
            Header: (
              <>
                {" "}
                {t(
                  "transporterVehiclesPage.in_service_react_table_column_name",
                  { lng: i18n.language }
                )}
              </>
            ),
            accessor: "",
            Cell: (prop) => {
              const { inService } = prop.original;

              return inService ? (
                <i className="fa fa-check-square text-success" />
              ) : (
                <i className=" fa fa-square-o text-danger" />
              );
            },
          },

          {
            Header: (
              <>
                {" "}
                {t(
                  "transporterVehiclesPage.vehicleOptions_react_table_column_name",
                  { lng: i18n.language }
                )}
              </>
            ),
            accessor: "",
            Cell: (prop) => {
              const { inUse } = prop.original;

              return inUse ? (
                <i className="fa fa-check-square text-success" />
              ) : (
                <i className=" fa fa-square-o text-danger" />
              );
            },
          },
          {
            Header: (
              <> {t("Common:ReactTable.action", { lng: i18n.language })}</>
            ),
            accessor: "actions",
            sortable: false,
            filterable: false,
            Cell: (prop) => {
              return (
                <div className="actions-right">
                  <Button bsStyle="dark" simple icon title="view">
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="edit"
                    onClick={() => {
                      this.setState(
                        Object.assign({}, this.state, {
                          showModifyVehicleReg: true,
                          vehicleToUpdate: prop.original,
                        })
                      );
                    }}
                  >
                    <i className="fa fa-pencil" />
                  </Button>{" "}
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="delete"
                    onClick={() => {
                      this.warningWithConfirmAndCancelMessage(prop.original);
                    }}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                </div>
              );
            },
          },
        ]}
        previousText={t("Common:ReactTable.previous_text_label", {
          lng: i18n.language,
        })}
        nextText={t("Common:ReactTable.next_text_label", {
          lng: i18n.language,
        })}
        ofText={t("Common:ReactTable.off_Text_label", { lng: i18n.language })}
        rowsText={t("Common:ReactTable.rows_Text_label", {
          lng: i18n.language,
        })}
        loadingText={t("Common:ReactTable.loading_Text_label", {
          lng: i18n.language,
        })}
        noDataText={t("Common:ReactTable.no_data_Text_label", {
          lng: i18n.language,
        })}
        defaultPageSize={limit}
        pages={totalPages}
        showPaginationTop={false}
        showPaginationBottom
        className="-striped -highlight"
      />
    );
  }

  render() {
    const { t, i18n } = this.props;
    const { companyId } = this.props;

    return (
      <Card
        title=""
        category=""
        content={
          <div>
            {this.state.alert}

            <NewAmbuVehicle
              {...{ ...this.props }}
              show={this.state.showAddNewVehicleModal}
              onHide={() => {
                this.setState(
                  Object.assign({}, this.state, {
                    showAddNewVehicleModal: false,
                  })
                );
              }}
            />
            <ModifyVehicleReg
              vehicle={this.state.vehicleToUpdate}
              companyId={companyId}
              show={this.state.showModifyVehicleReg}
              onHide={() => {
                this.setState(
                  Object.assign({}, this.state, { showModifyVehicleReg: false })
                );
              }}
            />
            <Button
              bsStyle="primary"
              fill
              wd
              onClick={() => {
                this.setState(
                  Object.assign({}, this.state, {
                    showAddNewVehicleModal: true,
                  })
                );
              }}
            >
              {t("transporterVehiclesPage.add_vehicle_button", {
                lng: i18n.language,
              })}
            </Button>
            {this.renderVehicules()}
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeTransporterVehiclesRequest: (...args) =>
      dispatch(
        TransporterDetailsActions.backOfficeTransporterVehiclesRequest(...args)
      ),
    backOfficeDeleteVehicleRequest: (...args) =>
      dispatch(
        TransporterDetailsActions.backOfficeDeleteVehicleRequest(...args)
      ),
  };
};

const tarnsporterVehiclesRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehiclesTab);
export default withTranslation(["Private", "Common"], { wait: true })(
  tarnsporterVehiclesRedux
);

import _ from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import SamuDetailsActions, {
  SamuDetailsType,
} from "reducers/samu/samu-details";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* backOfficeGetSamuDetailsAsyncRequest({ companyId }) {
  const query = `
    {
      BackOfficeSamuWithId(companyId:"${companyId}") {
        _id
        siret
        active
        name
        email
        web
        billing { business_model }
        billing{ amount }
      }
    }
  `;
  try {
    const { BackOfficeSamuWithId } = yield graphQLClient().request(query);
    yield put(
      SamuDetailsActions.backOfficeGetSamuDetailsRequestSuccess(
        BackOfficeSamuWithId
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      SamuDetailsActions.backOfficeGetSamuDetailsRequestFailure
    );
  }
}

function* backOfficeSamuUsersAsyncRequest({ companyId }) {
  const query = `
    {
      BackOfficeSamuUsers(companyId: "${companyId}")
      { _id firstname lastname email }
    }
  `;
  try {
    const { BackOfficeSamuUsers } = yield graphQLClient().request(query);
    yield put(
      SamuDetailsActions.backOfficeSamuUsersRequestSuccess(BackOfficeSamuUsers)
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      SamuDetailsActions.backOfficeSamuUsersRequestFailure
    );
  }
}

export default [
  takeLatest(
    SamuDetailsType.BACK_OFFICE_GET_SAMU_DETAILS_REQUEST,
    backOfficeGetSamuDetailsAsyncRequest
  ),
  takeLatest(
    SamuDetailsType.BACK_OFFICE_SAMU_USERS_REQUEST,
    backOfficeSamuUsersAsyncRequest
  ),
];

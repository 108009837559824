import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import ImmutablePersistenceTransform from "./ImmutablePersistenceTransform";

import SagasRoot from "../sagas";
import AppReducers from "reducers";

// middleware that logs actions
const loggerMiddleware = createLogger({
  predicate: (getState, action) => process.env.NODE_ENV,
});
const sagaMiddleware = createSagaMiddleware();

const rootPersistConfig = {
  key: "root.0.10",
  storage,
  blacklist: ["productsStore", "productInReview"],
  transforms: [ImmutablePersistenceTransform],
};

const persistedAppReducers = persistReducer(rootPersistConfig, AppReducers);

function configureStore(initialState) {
  let store = null;

  if (
    (process.env.REACT_APP_STAGE === "dev") |
    (process.env.REACT_APP_STAGE === "prod")
  )
    store = compose(
      applyMiddleware(
        sagaMiddleware
        //loggerMiddleware ,
      )
    )(createStore)(persistedAppReducers);
  else
    store = compose(
      applyMiddleware(
        sagaMiddleware
        //loggerMiddleware ,
      )
    )(createStore)(persistedAppReducers);

  sagaMiddleware.run(SagasRoot);

  return store;
}

export const Store = configureStore({});
export const Persistor = persistStore(Store);

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeCompanyResetState: null,
  backOfficeCompanyListRequest: ["page", "limit"],
  backOfficeCompanyListRequestSuccess: ["companies"],
  backOfficeCompanyListRequestFailure: ["error"],
});

export const CompaniesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  list: {
    page: 1,
    limit: 10,
    companies: [],
  },

  action: {
    loading: false,
    error: "",
  },
});

/// GENERAL
export const backOfficeCompanyResetState = (state, action) => {
  return INITIAL_STATE;
};

export const backOfficeCompanyListRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeCompanyListRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["list"], action.companies);
};
export const backOfficeCompanyListRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_COMPANY_RESET_STATE]: backOfficeCompanyResetState,
  [Types.BACK_OFFICE_COMPANY_LIST_REQUEST]: backOfficeCompanyListRequest,
  [Types.BACK_OFFICE_COMPANY_LIST_REQUEST_SUCCESS]:
    backOfficeCompanyListRequestSuccess,
  [Types.BACK_OFFICE_COMPANY_LIST_REQUEST_FAILURE]:
    backOfficeCompanyListRequestFailure,
});

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeModifyServiceSettingsRequest: [
    "firstCycleTimeOut",
    "secondCycleTimeOut",
    "companyId",
    "serviceId",
    "callback",
  ],
  backOfficeModifyServiceSettingsRequestSuccess: null,
  backOfficeModifyServiceSettingsRequestFailure: ["error"],

  backOfficeGetCompanyDetailsResetState: null,
  backOfficeGetCompanyDetailsRequest: ["companyId"],
  backOfficeGetCompanyDetailsRequestSuccess: ["details"],
  backOfficeGetCompanyDetailsRequestFailure: ["error"],

  BackOfficeServiceAddTransporterToTheWhiteListRequest: [
    "companyId",
    "serviceId",
    "transporterId",
    "isAdd",
  ],
  // BackOfficeServiceAddTransporterToTheWhiteListSuccess: ["companyId", "serviceId", "transporterId", "isAdd"],
  // BackOfficeServiceAddTransporterToTheWhiteListFailure: ["companyId", "serviceId", "transporterId", "isAdd"],

  backOfficeCompanyUsersRequest: ["companyId"],
  backOfficeCompanyUsersRequestSuccess: ["users"],
  backOfficeCompanyUsersRequestFailure: ["error"],

  backOfficeCreateCompanyServiceRequest: ["service", "companyId", "callback"],
  backOfficeCreateCompanyServiceRequestFailure: ["error"],
  backOfficeCreateCompanyServiceRequestSuccess: ["service"],

  backOfficeGetServiceDetailsRequest: ["companyId", "serviceId"],
  backOfficeGetServiceDetailsRequestSuccess: ["details"],
  backOfficeGetServiceDetailsRequestFailure: ["error"],

  backOfficeGetServiceUsersRequest: ["companyId", "serviceId"],
  backOfficeGetServiceUsersRequestSuccess: ["users"],
  backOfficeGetServiceUsersRequestFailure: ["error"],

  backOfficeModifyUserCompanyRequest: [
    "companyUser",
    "id",
    "companyId",
    "callback",
  ],
  backOfficeModifyUserCompanyRequestSuccess: null,
  backOfficeModifyUserCompanyRequestFailure: ["error"],

  backOfficeModifyUserServiceRequest: [
    "serviceUser",
    "id",
    "companyId",
    "serviceId",
    "callback",
  ],
  backOfficeModifyUserServiceRequestSuccess: null,
  backOfficeModifyUserServiceRequestFailure: ["error"],

  backOfficeDeleteUserCompanyRequest: [
    "companyUserId",
    "companyId",
    "callback",
  ],
  backOfficeDeleteUserCompanyRequestSuccess: null,
  backOfficeDeleteUserCompanyRequestFailure: ["error"],

  backOfficeDeleteUserServiceRequest: [
    "serviceUserId",
    "companyId",
    "serviceId",
    "callback",
  ],
  backOfficeDeleteUserServiceRequestSuccess: null,
  backOfficeDeleteUserServiceRequestFailure: ["error"],

  backOfficeClearError: null,
});

export const CompanyDetailsType = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  company: {
    details: null,
    users: [],
  },

  service: {
    details: null,
    users: [],
  },

  action: {
    loading: false,
    error: "",
  },
});

/// GENERAL
export const backOfficeGetCompanyDetailsResetState = (state, action) => {
  return INITIAL_STATE;
};

export const backOfficeClearError = (state, action) => {
  return Immutable.setIn(state, ["action", "error"], "");
};

export const backOfficeGetCompanyDetailsRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["company", "details"], null);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeGetCompanyDetailsRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["company", "details"], action.details);
};

export const backOfficeGetCompanyDetailsRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeCompanyUsersRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["company", "users"], []);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeCompanyUsersRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["company", "users"], action.users);
};

export const backOfficeCompanyUsersRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeCreateCompanyServiceRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeCreateCompanyServiceRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["company", "details", "services"],
    [...state.company.details.services, action.service]
  );
};

export const backOfficeCreateCompanyServiceRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeGetServiceDetailsRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["service", "details"], null);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const boGetHospServiceDetailsRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["service", "details"], action.details);
};

export const backOfficeGetServiceDetailsRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeGetServiceUsersRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["service", "users"], []);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeGetServiceUsersRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["service", "users"], action.users);
};

export const backOfficeGetServiceUsersRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeModifyUserCompanyRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeModifyUserCompanyRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const backOfficeModifyUserCompanyRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeModifyUserServiceRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeModifyUserServiceRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const backOfficeModifyUserServiceRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeDeleteUserCompanyRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeDeleteUserCompanyRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const backOfficeDeleteUserCompanyRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeDeleteUserServiceRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeDeleteUserServiceRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const backOfficeDeleteUserServiceRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};
export const BackOfficeServiceAddTransporterToTheWhiteListRequest = (
  state,
  action
) => state;

export const backOfficeModifyServiceSettingsRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeModifyServiceSettingsRequestSuccess = (
  state,
  action
) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const backOfficeModifyServiceSettingsRequestFailure = (
  state,
  action
) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};
export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_MODIFY_SERVICE_SETTINGS_REQUEST]:
    backOfficeModifyServiceSettingsRequest,
  [Types.BACK_OFFICE_MODIFY_SERVICE_SETTINGS_REQUEST_FAILURE]:
    backOfficeModifyServiceSettingsRequestFailure,
  [Types.BACK_OFFICE_MODIFY_SERVICE_SETTINGS_REQUEST_SUCCESS]:
    backOfficeModifyServiceSettingsRequestSuccess,

  [Types.BACK_OFFICE_GET_COMPANY_DETAILS_RESET_STATE]:
    backOfficeGetCompanyDetailsResetState,

  [Types.BACK_OFFICE_SERVICE_ADD_TRANSPORTER_TO_THE_WHITE_LIST_REQUEST]:
    BackOfficeServiceAddTransporterToTheWhiteListRequest,

  [Types.BACK_OFFICE_GET_COMPANY_DETAILS_REQUEST]:
    backOfficeGetCompanyDetailsRequest,
  [Types.BACK_OFFICE_GET_COMPANY_DETAILS_REQUEST_SUCCESS]:
    backOfficeGetCompanyDetailsRequestSuccess,
  [Types.BACK_OFFICE_GET_COMPANY_DETAILS_REQUEST_FAILURE]:
    backOfficeGetCompanyDetailsRequestFailure,
  [Types.BACK_OFFICE_COMPANY_USERS_REQUEST]: backOfficeCompanyUsersRequest,
  [Types.BACK_OFFICE_COMPANY_USERS_REQUEST_FAILURE]:
    backOfficeCompanyUsersRequestFailure,
  [Types.BACK_OFFICE_COMPANY_USERS_REQUEST_SUCCESS]:
    backOfficeCompanyUsersRequestSuccess,

  [Types.BACK_OFFICE_CREATE_COMPANY_SERVICE_REQUEST]:
    backOfficeCreateCompanyServiceRequest,
  [Types.BACK_OFFICE_CREATE_COMPANY_SERVICE_REQUEST_SUCCESS]:
    backOfficeCreateCompanyServiceRequestSuccess,
  [Types.BACK_OFFICE_CREATE_COMPANY_SERVICE_REQUEST_FAILURE]:
    backOfficeCreateCompanyServiceRequestFailure,

  [Types.BACK_OFFICE_GET_SERVICE_DETAILS_REQUEST]:
    backOfficeGetServiceDetailsRequest,
  [Types.BACK_OFFICE_GET_SERVICE_DETAILS_REQUEST_SUCCESS]:
    boGetHospServiceDetailsRequestSuccess,
  [Types.BACK_OFFICE_GET_SERVICE_DETAILS_REQUEST_FAILURE]:
    backOfficeGetServiceDetailsRequestFailure,
  [Types.BACK_OFFICE_GET_SERVICE_USERS_REQUEST]:
    backOfficeGetServiceUsersRequest,
  [Types.BACK_OFFICE_GET_SERVICE_USERS_REQUEST_FAILURE]:
    backOfficeGetServiceUsersRequestFailure,
  [Types.BACK_OFFICE_GET_SERVICE_USERS_REQUEST_SUCCESS]:
    backOfficeGetServiceUsersRequestSuccess,

  [Types.BACK_OFFICE_CLEAR_ERROR]: backOfficeClearError,

  [Types.BACK_OFFICE_MODIFY_USER_COMPANY_REQUEST]:
    backOfficeModifyUserCompanyRequest,
  [Types.BACK_OFFICE_MODIFY_USER_COMPANY_REQUEST_FAILURE]:
    backOfficeModifyUserCompanyRequestSuccess,
  [Types.BACK_OFFICE_MODIFY_USER_COMPANY_REQUEST_SUCCESS]:
    backOfficeModifyUserCompanyRequestFailure,

  [Types.BACK_OFFICE_MODIFY_USER_SERVICE_REQUEST]:
    backOfficeModifyUserServiceRequest,
  [Types.BACK_OFFICE_MODIFY_USER_SERVICE_REQUEST_FAILURE]:
    backOfficeModifyUserServiceRequestFailure,
  [Types.BACK_OFFICE_MODIFY_USER_SERVICE_REQUEST_SUCCESS]:
    backOfficeModifyUserServiceRequestSuccess,

  [Types.BACK_OFFICE_DELETE_USER_COMPANY_REQUEST]:
    backOfficeDeleteUserCompanyRequest,
  [Types.BACK_OFFICE_DELETE_USER_COMPANY_REQUEST_FAILURE]:
    backOfficeDeleteUserCompanyRequestSuccess,
  [Types.BACK_OFFICE_DELETE_USER_COMPANY_REQUEST_SUCCESS]:
    backOfficeDeleteUserCompanyRequestFailure,

  [Types.BACK_OFFICE_DELETE_USER_SERVICE_REQUEST]:
    backOfficeDeleteUserServiceRequest,
  [Types.BACK_OFFICE_DELETE_USER_SERVICE_REQUEST_FAILURE]:
    backOfficeDeleteUserServiceRequestSuccess,
  [Types.BACK_OFFICE_DELETE_USER_SERVICE_REQUEST_SUCCESS]:
    backOfficeDeleteUserServiceRequestFailure,
});

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeGetTransporterDetailsResetState: null,
  backOfficeGetTransporterDetailsRequest: ["companyId"],
  backOfficeGetTransporterDetailsRequestSuccess: ["details"],
  backOfficeGetTransporterDetailsRequestFailure: ["error"],

  backOfficeTransporterUsersRequest: ["companyId"],
  backOfficeTransporterUseCreated: ["user"],
  backOfficeTransporterUsersRequestSuccess: ["users"],
  backOfficeTransporterUsersRequestFailure: ["error"],

  backOfficeCreateDriverRequest: ["driver", "companyId", "callback"],
  backOfficeCreateDriverRequestSuccess: ["driver"],
  backOfficeCreateDriverRequestFailure: ["error"],

  backOfficeTransporterDriversRequest: ["companyId"],
  backOfficeTransporterDriversRequestSuccess: ["drivers"],
  backOfficeTransporterDriversRequestFailure: ["error"],

  backOfficeTransporterVehiclesRequest: ["companyId", "page", "limit"],
  backOfficeTransporterVehiclesRequestSuccess: ["vehicles"],
  backOfficeTransporterVehiclesRequestFailure: ["error"],
  backOfficeClearError: null,

  backOfficeModifyTransporterUserRequest: [
    "transporterUser",
    "id",
    "companyId",
    "callback",
  ],
  backOfficeModifyTransporterUserRequestSuccess: null,
  backOfficeModifyTransporterUserRequestFailure: ["error"],

  backOfficeModifyTransporterDriverRequest: [
    "driverUser",
    "id",
    "driverId",
    "callback",
  ],
  backOfficeModifyTransporterDriverRequestSuccess: null,
  backOfficeModifyTransporterDriverRequestFailure: ["error"],

  backOfficeDeleteUserTransporterRequest: [
    "transporterUser",
    "companyId",
    "callback",
  ],
  backOfficeDeleteUserTransporterRequestSuccess: null,
  backOfficeDeleteUserTransporterRequestFailure: ["error"],

  backOfficeDeleteDriverTransporterRequest: [
    "driverId",
    "companyId",
    "callback",
  ],
  backOfficeDeleteDriverTransporterRequestSuccess: null,
  backOfficeDeleteDriverTransporterRequestFailure: ["error"],

  backOfficeModifyVehicleRequest: ["vehicle", "id", "companyId", "callback"],
  backOfficeModifyVehicleRequestSuccess: null,
  backOfficeModifyVehicleRequestFailure: ["error"],

  backOfficeDeleteVehicleRequest: ["vehiculeId"],
  backOfficeDeleteVehicleRequestSuccess: null,
  backOfficeDeleteVehicleRequestFailure: ["error"],

  backOfficeTransporterToggleActivationRequest: ["companyId", "callback"],
  backOfficeTransporterToggleActivationSuccess: ["active"],
  backOfficeTransporterToggleActivationFailure: ["error"],
});

export const TransporterDetailsType = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  company: {
    details: null,
    users: {
      drivers: [],
      admins: [],
    },
    vehicles: {
      page: 1,
      limit: 10,
      vehicles: [],
    },
  },

  action: {
    loading: false,
    error: "",
  },
});

/// GENERAL
export const backOfficeGetTransporterDetailsResetState = (state, action) => {
  return INITIAL_STATE;
};

export const backOfficeClearError = (state, action) => {
  return Immutable.setIn(state, ["action", "error"], "");
};

export const backOfficeGetTransporterDetailsRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["company", "details"], null);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeGetTransporterDetailsRequestSuccess = (
  state,
  action
) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["company", "details"], action.details);
};

export const backOfficeGetTransporterDetailsRequestFailure = (
  state,
  action
) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeCreateDriverRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeCreateDriverRequestSuccess = (state, action) => {
  let drivers = state.company.users.drivers.asMutable({ deep: true });
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["company", "users", "drivers"],
    [...drivers, action.driver]
  );
};

export const backOfficeCreateDriverRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeTransporterUsersRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["company", "users", "admins"], []);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeTransporterUsersRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);

  return Immutable.setIn(
    nextSate,
    ["company", "users", "admins"],
    action.users
  );
};

export const backOfficeTransporterUseCreated = (state, action) => {
  const users = state.company.users.admins.asMutable({ deep: true });
  return Immutable.setIn(
    state,
    ["company", "users", "admins"],
    [action.user, ...users]
  );
};

export const backOfficeTransporterUsersRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeTransporterDriversRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["company", "users", "drivers"], []);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeTransporterDriversRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);

  return Immutable.setIn(
    nextSate,
    ["company", "users", "drivers"],
    action.drivers
  );
};

export const backOfficeTransporterDriversRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeTransporterVehiclesRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["company", "vehicles"], {
    page: 1,
    limit: 10,
    vehicles: [],
  });
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeTransporterVehiclesRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["company", "vehicles"], action.vehicles);
};

export const backOfficeTransporterVehiclesRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeModifyTransporterUserRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeModifyTransporterUserRequestSuccess = (
  state,
  action
) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const backOfficeModifyTransporterUserRequestFailure = (
  state,
  action
) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeDeleteUserTransporterRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeDeleteUserTransporterRequestSuccess = (
  state,
  action
) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const backOfficeDeleteUserTransporterRequestFailure = (
  state,
  action
) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeModifyTransporterDriverRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeModifyTransporterDriverRequestSuccess = (
  state,
  action
) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const backOfficeModifyTransporterDriverRequestFailure = (
  state,
  action
) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeDeleteDriverTransporterRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeDeleteDriverTransporterRequestSuccess = (
  state,
  action
) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const backOfficeDeleteDriverTransporterRequestFailure = (
  state,
  action
) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeModifyVehicleRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeModifyVehicleRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const backOfficeModifyVehicleRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeDeleteVehicleRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeDeleteVehicleRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const backOfficeDeleteVehicleRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};


export const backOfficeTransporterToggleActivationRequest = backOfficeCreateDriverRequest;

export const backOfficeTransporterToggleActivationSuccess = (state, action) => {
  const details = state.company.details.asMutable({ deep: true });
  details.active = action.active;
  console.log("details", details);
  let nextState = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextState, ["company", "details"], details);
};

export const backOfficeTransporterToggleActivationFailure = backOfficeCreateDriverRequestFailure;


export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_GET_TRANSPORTER_DETAILS_RESET_STATE]:
    backOfficeGetTransporterDetailsResetState,
  [Types.BACK_OFFICE_GET_TRANSPORTER_DETAILS_REQUEST]:
    backOfficeGetTransporterDetailsRequest,
  [Types.BACK_OFFICE_GET_TRANSPORTER_DETAILS_REQUEST_SUCCESS]:
    backOfficeGetTransporterDetailsRequestSuccess,
  [Types.BACK_OFFICE_GET_TRANSPORTER_DETAILS_REQUEST_FAILURE]:
    backOfficeGetTransporterDetailsRequestFailure,
  [Types.BACK_OFFICE_TRANSPORTER_USERS_REQUEST]:
    backOfficeTransporterUsersRequest,
  [Types.BACK_OFFICE_TRANSPORTER_USERS_REQUEST_SUCCESS]:
    backOfficeTransporterUsersRequestSuccess,
  [Types.BACK_OFFICE_TRANSPORTER_USERS_REQUEST_FAILURE]:
    backOfficeTransporterUsersRequestFailure,
  [Types.BACK_OFFICE_CREATE_DRIVER_REQUEST]: backOfficeCreateDriverRequest,
  [Types.BACK_OFFICE_CREATE_DRIVER_REQUEST_SUCCESS]:
    backOfficeCreateDriverRequestSuccess,
  [Types.BACK_OFFICE_CREATE_DRIVER_REQUEST_FAILURE]:
    backOfficeCreateDriverRequestFailure,
  [Types.BACK_OFFICE_TRANSPORTER_DRIVERS_REQUEST]:
    backOfficeTransporterDriversRequest,
  [Types.BACK_OFFICE_TRANSPORTER_DRIVERS_REQUEST_SUCCESS]:
    backOfficeTransporterDriversRequestSuccess,
  [Types.BACK_OFFICE_TRANSPORTER_DRIVERS_REQUEST_FAILURE]:
    backOfficeTransporterDriversRequestFailure,
  [Types.BACK_OFFICE_TRANSPORTER_USE_CREATED]: backOfficeTransporterUseCreated,
  [Types.BACK_OFFICE_TRANSPORTER_VEHICLES_REQUEST]:
    backOfficeTransporterVehiclesRequest,
  [Types.BACK_OFFICE_TRANSPORTER_VEHICLES_REQUEST_SUCCESS]:
    backOfficeTransporterVehiclesRequestSuccess,
  [Types.BACK_OFFICE_TRANSPORTER_VEHICLES_REQUEST_FAILURE]:
    backOfficeTransporterVehiclesRequestFailure,

  [Types.BACK_OFFICE_MODIFY_TRANSPORTER_USER_REQUEST]:
    backOfficeModifyTransporterUserRequest,
  [Types.BACK_OFFICE_MODIFY_TRANSPORTER_USER_REQUEST_SUCCESS]:
    backOfficeModifyTransporterUserRequestSuccess,
  [Types.BACK_OFFICE_MODIFY_TRANSPORTER_USER_REQUEST_FAILURE]:
    backOfficeModifyTransporterUserRequestFailure,

  [Types.BACK_OFFICE_MODIFY_TRANSPORTER_DRIVER_REQUEST]:
    backOfficeModifyTransporterDriverRequest,
  [Types.BACK_OFFICE_MODIFY_TRANSPORTER_DRIVER_REQUEST_SUCCESS]:
    backOfficeModifyTransporterDriverRequestSuccess,
  [Types.BACK_OFFICE_MODIFY_TRANSPORTER_DRIVER_REQUEST_FAILURE]:
    backOfficeModifyTransporterDriverRequestFailure,

  [Types.BACK_OFFICE_DELETE_USER_TRANSPORTER_REQUEST]:
    backOfficeDeleteUserTransporterRequest,
  [Types.BACK_OFFICE_DELETE_USER_TRANSPORTER_REQUEST_SUCCESS]:
    backOfficeDeleteUserTransporterRequestSuccess,
  [Types.BACK_OFFICE_DELETE_USER_TRANSPORTER_REQUEST_FAILURE]:
    backOfficeDeleteUserTransporterRequestFailure,
  [Types.BACK_OFFICE_CLEAR_ERROR]: backOfficeClearError,

  [Types.BACK_OFFICE_DELETE_DRIVER_TRANSPORTER_REQUEST]:
    backOfficeDeleteDriverTransporterRequest,
  [Types.BACK_OFFICE_DELETE_DRIVER_TRANSPORTER_REQUEST_SUCCESS]:
    backOfficeDeleteVehicleRequestSuccess,
  [Types.BACK_OFFICE_DELETE_DRIVER_TRANSPORTER_REQUEST_FAILURE]:
    backOfficeDeleteVehicleRequestFailure,

  [Types.BACK_OFFICE_MODIFY_VEHICLE_REQUEST]: backOfficeModifyVehicleRequest,
  [Types.BACK_OFFICE_MODIFY_VEHICLE_REQUEST_SUCCESS]:
    backOfficeModifyVehicleRequestSuccess,
  [Types.BACK_OFFICE_MODIFY_VEHICLE_REQUEST_FAILURE]:
    backOfficeModifyVehicleRequestFailure,

  [Types.BACK_OFFICE_DELETE_VEHICLE_REQUEST]: backOfficeDeleteVehicleRequest,
  [Types.BACK_OFFICE_DELETE_VEHICLE_REQUEST_SUCCESS]:
    backOfficeDeleteVehicleRequestSuccess,
  [Types.BACK_OFFICE_DELETE_VEHICLE_REQUEST_FAILURE]:
    backOfficeDeleteVehicleRequestFailure,

  [Types.BACK_OFFICE_TRANSPORTER_TOGGLE_ACTIVATION_REQUEST]: backOfficeTransporterToggleActivationRequest,
  [Types.BACK_OFFICE_TRANSPORTER_TOGGLE_ACTIVATION_SUCCESS]: backOfficeTransporterToggleActivationSuccess,
  [Types.BACK_OFFICE_TRANSPORTER_TOGGLE_ACTIVATION_FAILURE]: backOfficeTransporterToggleActivationFailure,
});

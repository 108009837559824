import React, { Component } from "react";
import { connect } from "react-redux";
import taxiDetailsActions from "reducers/taxi/get-taxi-details";

import { Grid, Row, Col, Alert, Tab, Nav, NavItem } from "react-bootstrap";

import Loader from "react-loader-spinner";
import _ from "lodash";
import InformationsTab from "./taxi-details";
import VehiclesTab from "./taxi-vehicles";
import { withTranslation } from "react-i18next";

class TaxiInfos extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { match, taxiGetDetailsRequest } = this.props;
    const taxiId = match.params.id;
    taxiGetDetailsRequest(taxiId);
  }

  renderTaxi(details) {
    const { t, i18n } = this.props;

    const { match } = this.props;
    const taxiId = match.params.id;
    return (
      <Row>
        <Col md={12}>
          <h1 className="title text-center">{}</h1>
          <Tab.Container id="nav-with-icons" defaultActiveKey="informations">
            <div>
              <div className="nav-container">
                <Nav bsStyle="tabs" bsClass="nav nav-icons">
                  <NavItem eventKey="informations">
                    <i className="fa fa-info-circle" />
                    <br />{" "}
                    {t("taxiDetailsBarPage.taxi_details_info_nav_title", {
                      lng: i18n.language,
                    })}
                  </NavItem>

                  <NavItem eventKey="vehicles">
                    <i className="fa fa-ambulance" />
                    <br />{" "}
                    {t("taxiDetailsBarPage.taxi_details_vehicules_nav_title", {
                      lng: i18n.language,
                    })}
                  </NavItem>
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="informations">
                  {<InformationsTab {...{ details }} />}
                </Tab.Pane>

                <Tab.Pane eventKey="vehicles">
                  {<VehiclesTab {...{ taxiId }} />}
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </Col>
      </Row>
    );
  }

  render() {
    const { action, taxi } = this.props;
    const { details } = taxi;
    const { t, i18n } = this.props;

    const { error, loading } = action;
    return (
      <div className="main-content">
        <Grid fluid>
          <Row className="text-center">
            <Col md={6} sm={6} mdOffset={3} smOffset={3}>
              {error !== "" ? (
                <Alert bsStyle="danger">
                  <span>{error}</span>
                </Alert>
              ) : null}
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={100}
                width={100}
                visible={loading}
              />
            </Col>
          </Row>

          {
            // _.isNull(company.details) ? null :
            this.renderTaxi(details)
          }
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    taxi: state.taxiDetailsTaxis.taxi.asMutable({ deep: true }),
    action: state.taxiDetailsTaxis.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    taxiGetDetailsRequest: (...args) =>
      dispatch(taxiDetailsActions.taxiGetDetailsRequest(...args)),
  };
};

const taxiIndexRedux = connect(mapStateToProps, mapDispatchToProps)(TaxiInfos);
export default withTranslation(["Private", "Common"], { wait: true })(
  taxiIndexRedux
);

import React, { Component } from "react";
import { connect } from "react-redux";
import AddSamuAdminActions from "reducers/samu/add-samu-admin";

import { withTranslation } from "react-i18next";

import {
  Alert,
  Col,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import _ from "lodash";
import { Formik } from "formik";
import { string, object } from "yup";

const validationSchema = object().shape({
  email: string().required("required"),
  password: string().required("required"),
  firstname: string().required("required"),
  lastname: string().required("required"),
});

class NewUser extends Component {
  constructor(props) {
    super(props);

    this.formikProps = null;
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {}

  onSubmitForm(values, { resetForm, setSubmitting }) {
    const { backOfficeCreateSamuAdminRequest, companyId, onHide } = this.props;

    backOfficeCreateSamuAdminRequest(values, companyId, (error) => {
      if (error) {
        console.log(error);
        return;
      }
      setSubmitting(false);
      resetForm();
      onHide();
    });
  }

  render() {
    const { action } = this.props;
    const { t, i18n } = this.props;
    const { error } = action;
    return (
      <Formik
        initialValues={{
          email: "",
          password: "",
          firstname: "",
          lastname: "",
        }}
        validationSchema={validationSchema}
        onSubmit={this.onSubmitForm}
      >
        {(props) => {
          const { errors, touched } = props;

          return (
            <Modal
              {...this.props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              backdrop="static"
            >
              {error !== "" ? (
                <Alert bsStyle="danger">
                  <span>{error}</span>
                </Alert>
              ) : null}
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t("transporterAddUserPage.transporter_add_user_headline", {
                    lng: i18n.language,
                  })}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form horizontal>
                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {" "}
                      {t(
                        "transporterAddUserPage.transporter_add_user_lastname_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "transporterAddUserPage.transporter_add_user_placeholder_lastname",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "firstname") &&
                          _.has(touched, "firstname")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("firstname")}
                        onInput={() =>
                          props.setFieldTouched("firstname", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("firstname", value, true);
                        }}
                        value={props.values.firstname}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {t(
                        "transporterAddUserPage.transporter_add_user_firstname_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "transporterAddUserPage.transporter_add_user_placeholder_firstname",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "lastname") &&
                          _.has(touched, "lastname")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("lastname")}
                        onInput={() =>
                          props.setFieldTouched("lastname", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("lastname", value, true);
                        }}
                        value={props.values.lastname}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {t(
                        "transporterAddUserPage.transporter_add_user_email_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "transporterAddUserPage.transporter_add_user_placeholder_email",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "email") && _.has(touched, "email")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("email")}
                        onInput={() =>
                          props.setFieldTouched("email", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("email", value, true);
                        }}
                        value={props.values.email}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {t(
                        "transporterAddUserPage.transporter_add_user_password_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "transporterAddUserPage.transporter_add_user_placeholder_password",
                          { lng: i18n.language }
                        )}
                        type="password"
                        autoComplete="off"
                        className={
                          _.has(errors, "password") &&
                          _.has(touched, "password")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("password")}
                        onInput={() =>
                          props.setFieldTouched("password", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("password", value, true);
                        }}
                        value={props.values.password}
                        required
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  // disabled={props.isSubmitting}
                  onClick={() => {
                    props.resetForm();
                    this.props.backOfficeClearError();
                    this.props.onHide();
                  }}
                >
                  {" "}
                  {t("Common:footerPage.close", { lng: i18n.language })}
                </Button>
                <Button
                  bsStyle="success"
                  fill
                  // disabled={props.isSubmitting}
                  onClick={() => {
                    props.handleSubmit();
                  }}
                >
                  {t("Common:footerPage.save_changes", { lng: i18n.language })}
                </Button>
              </Modal.Footer>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    action: state.backOfficeCreateAdminSamu.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeCreateSamuAdminRequest: (...args) =>
      dispatch(AddSamuAdminActions.backOfficeCreateSamuAdminRequest(...args)),
    backOfficeClearError: (...args) =>
      dispatch(AddSamuAdminActions.backOfficeClearError(...args)),
  };
};

const samuUsersRedux = connect(mapStateToProps, mapDispatchToProps)(NewUser);
export default withTranslation(["Private", "Common"], { wait: true })(
  samuUsersRedux
);

import { all } from "redux-saga/effects";
import AccountSagas from "./account";
import GetCompaniesSaga from "./company/back-office-get-companies";
import AddCompanySaga from "./company/add-company";
import CompanyDtailsSaga from "./company/company-details";
import companyAdminSaga from "./company/add-company-admin";
import companyServiceSaga from "./company/add-company-service";
import GetTransportersSaga from "./transporter/get-transporters";
import TransporterDtailsSaga from "./transporter/transporter-details";
import TransporterAdminSaga from "./transporter/add-transporter-admin";
import TransporterCreateSaga from "./transporter/add-transporter";
import CompanyAddServiceAdminSaga from "./company/add-user-service";
import TransporterAddDriverSaga from "./transporter/add-driver";
import TransporterAddVehicleSaga from "./transporter/add-transporter-vehicle";
import TaxiSaga from "./taxi/taxi-getTaxis";
import TaxiDetailsSaga from "./taxi/taxi-getDetails";
import TaxiVehiclesSaga from "./taxi/taxi-vehicles";
import SamusSaga from "./samu/get-samus";
import SamuCreateSaga from "./samu/add-samu";
import SamuDetails from "./samu/samu-details";
import SamuAddUserAdmin from "./samu/add-samu-admin";
import SamuSectors from "./samu/samu-sectors";
import Rides from "./ride/rides";

export default function* root() {
  yield all([
    ...AccountSagas,
    ...GetCompaniesSaga,
    ...AddCompanySaga,
    ...CompanyDtailsSaga,
    ...companyAdminSaga,
    ...companyServiceSaga,
    ...GetTransportersSaga,
    ...TransporterDtailsSaga,
    ...TransporterAdminSaga,
    ...TransporterCreateSaga,
    ...CompanyAddServiceAdminSaga,
    ...TransporterAddDriverSaga,
    ...TransporterAddVehicleSaga,
    ...TaxiSaga,
    ...TaxiDetailsSaga,
    ...TaxiVehiclesSaga,
    ...SamusSaga,
    ...SamuCreateSaga,
    ...SamuDetails,
    ...SamuAddUserAdmin,
    ...SamuSectors,
    ...Rides,
  ]);
}

import _ from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import errorHandler from "sagas/error-handler";
import TransportersActions, {
  TransportersTypes,
} from "reducers/transporter/get-transporters";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* backOfficeTranspotersListAsyncRequest({ page, limit }) {
  const query = `
		{
			BackOfficeTransporters(page: ${page}, limit: ${limit}) {
        limit,
        page,
        totalDocs,
        totalPages,
        companies {
          _id
					name
					shortName
					address { address }
					active
				}
			}
		}
  `;
  try {
    const { BackOfficeTransporters } = yield graphQLClient().request(query);
    yield put(
      TransportersActions.backOfficeTransportersListRequestSuccess(
        BackOfficeTransporters
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransportersActions.backOfficeTransportersListRequestFailure
    );
  }
}
export default [
  takeLatest(
    TransportersTypes.BACK_OFFICE_TRANSPORTERS_LIST_REQUEST,
    backOfficeTranspotersListAsyncRequest
  ),
];

import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Col, Table, Button } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import CompanyUpdateServiceSettings from "./update-settings";

import _ from "lodash";
import { withTranslation } from "react-i18next";

class InformationsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpdateSettingsModale: false,
    };
  }

  componentDidMount() {}

  renderSettings(settings) {
    return (
      <Table responsive="sm">
        <thead>
          <tr>
            <th>Dispatching Settings (minutes) </th>
            <th>
              <Button
                bsStyle="danger"
                fill
                simple
                bsSize="xs"
                onClick={() => {
                  this.setState(
                    Object.assign({}, this.state, {
                      showUpdateSettingsModale: true,
                    })
                  );
                }}
              >
                <i className="fa fa-pencil" />
              </Button>{" "}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>First cycle timeout</th>
            <td>
              {settings && settings.dispatching1stCycleTimeOut
                ? settings.dispatching1stCycleTimeOut
                : 5}
            </td>
          </tr>
          <tr>
            <th>Second cycle timeout</th>
            <td>
              {settings && settings.dispatching2ndCycleTimeOut
                ? settings.dispatching2ndCycleTimeOut
                : 15}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  renderDetails(details) {
    const { address } = details;
    const { t, i18n } = this.props;

    return (
      <Table responsive="sm">
        <thead>
          <tr>
            <th colSpan={2}>
              {t(
                "companyServiceDetailsInfosPage.company_service_details_info_adresse_label",
                { lng: i18n.language }
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>
            <td>{address.address}</td>
          </tr>
        </tbody>
      </Table>
    );
  }

  renderContact(details) {
    const { firstname, lastname, phones, email } = details.contact;
    const { t, i18n } = this.props;

    return (
      <Table responsive="sm">
        <thead>
          <tr>
            <th colSpan={2}>
              {t(
                "companyServiceDetailsInfosPage.company_service_details_info_contact_label",
                { lng: i18n.language }
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              {t(
                "companyServiceDetailsInfosPage.company_service_details_info_firstname_label",
                { lng: i18n.language }
              )}
            </th>
            <td>{lastname}</td>
          </tr>
          <tr>
            <th>
              {t(
                "companyServiceDetailsInfosPage.company_service_details_info_lastname_label",
                { lng: i18n.language }
              )}
            </th>
            <td>{firstname}</td>
          </tr>
          <tr>
            <th>
              {t(
                "companyServiceDetailsInfosPage.company_service_details_info_email_label",
                { lng: i18n.language }
              )}
            </th>
            <td>{email}</td>
          </tr>
          <tr>
            <th>
              {t(
                "companyServiceDetailsInfosPage.company_service_details_info_phone_label",
                { lng: i18n.language }
              )}
            </th>
            <td>{phones.mobile}</td>
          </tr>
        </tbody>
      </Table>
    );
  }

  render() {
    const { details, company } = this.props;
    const { showUpdateSettingsModale } = this.state;
    return (
      <>
        <CompanyUpdateServiceSettings
          companyId={company.details._id}
          serviceId={details._id}
          settings={details.settings}
          {...{ ...this.props }}
          show={showUpdateSettingsModale}
          onHide={() => {
            this.setState(
              Object.assign({}, this.state, { showUpdateSettingsModale: false })
            );
          }}
        />
        <Card
          title=""
          category=""
          content={
            <div>
              <Row>
                <Col md="6">{this.renderDetails(details)}</Col>
                <Col md="6">{this.renderContact(details)}</Col>
              </Row>
            </div>
          }
        />

        <Card
          title=""
          category=""
          content={
            <div>
              <Row>
                <Col md="6">{this.renderSettings(details.settings)}</Col>
              </Row>
            </div>
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const CompanyServiceDetailsInfoRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationsTab);
export default withTranslation(["Private", "Common"], { wait: true })(
  CompanyServiceDetailsInfoRedux
);

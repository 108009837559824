import React, { Component } from "react";
import { connect } from "react-redux";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { Grid, Row, Col, Alert, FormGroup } from "react-bootstrap";
import Card from "components/Card/Card";
import Button from "components/CustomButton/CustomButton";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import RidesActions from "reducers/ride";
import moment from "moment";

/***
 query {
  BackofficeRides(filters: {status: [canceled, incoming]})
}
 */
class RidesPage extends Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.state = {
      filters: {
        status: [],
      },
    };
  }
  componentDidMount() {
    const { getRidesRequest, rides } = this.props;
    const { page, limit } = rides;
    const { filters } = this.state;

    getRidesRequest(page, limit, filters);
  }

  onFilterChange(e) {
    const value = e.target.id;
    const { status } = this.state.filters;
    if (status.includes(value)) {
      this.setState(
        Object.assign({}, this.state, {
          filters: {
            status: status.filter(function (e) {
              return e !== value;
            }),
          },
        }),
        () => {
          const { rides, getRidesRequest } = this.props;
          const { page, limit } = rides;
          getRidesRequest(page, limit, this.state.filters);
        }
      );
    } else {
      this.setState(
        Object.assign({}, this.state, {
          filters: { status: [...status, value] },
        }),
        () => {
          const { rides, getRidesRequest } = this.props;
          const { page, limit } = rides;
          getRidesRequest(page, limit, this.state.filters);
        }
      );
    }
  }

  fetchData(state, instance) {
    const { page, pageSize } = state;

    const { getRidesRequest } = this.props;
    const { filters } = this.state;
    getRidesRequest(page + 1, pageSize, filters);
  }

  render() {
    const { t, i18n } = this.props;
    const { action, rides } = this.props;
    const { docs, totalDocs = 0, totalPages = 0, limit } = rides;
    const { error, loading } = action;
    const { status } = this.state.filters;
    return (
      <>
        <div className="main-content">
          <Grid fluid>
            <Row className="text-center">
              <Col md={6} sm={6} mdOffset={3} smOffset={3}>
                {error !== "" ? (
                  <Alert bsStyle="danger">
                    <span>{error}</span>
                  </Alert>
                ) : null}
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  visible={loading}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card
                  title={
                    <Row>
                      <Col md={4}>
                        <h3 className="d-inline">Rides</h3>
                      </Col>
                      <Col md={8} className="text-right ride-filter">
                        <Checkbox
                          inline
                          checked={status.includes("incoming")}
                          number="incoming"
                          label="INCOMING"
                          onChange={this.onFilterChange}
                        />
                        {/*
                        }
                        <Checkbox
                          inline
                          checked={status.includes('anoffer')}
                          number='anoffer'
                          label='ANOFFER'
                          onChange={this.onFilterChange}
                        />
                        {
                          */}
                        <Checkbox
                          inline
                          checked={status.includes("pending")}
                          number="pending"
                          label="PENDING"
                          onChange={this.onFilterChange}
                        />
                        <Checkbox
                          inline
                          checked={status.includes("onway")}
                          number="onway"
                          label="ONWAY"
                          onChange={this.onFilterChange}
                        />
                        <Checkbox
                          inline
                          checked={status.includes("waiting")}
                          number="waiting"
                          label="WAITING"
                          onChange={this.onFilterChange}
                        />
                        <Checkbox
                          inline
                          checked={status.includes("onboard")}
                          number="onboard"
                          label="ONBOARD"
                          onChange={this.onFilterChange}
                        />
                        <Checkbox
                          inline
                          checked={status.includes("completed")}
                          number="completed"
                          label="COMPLETED"
                          onChange={this.onFilterChange}
                        />
                        <Checkbox
                          inline
                          checked={status.includes("unavailable")}
                          number="unavailable"
                          label="UNAVAILABLE"
                          onChange={this.onFilterChange}
                        />
                        <Checkbox
                          inline
                          checked={status.includes("canceled")}
                          number="canceled"
                          label="CANCELED"
                          onChange={this.onFilterChange}
                        />
                      </Col>
                    </Row>
                  }
                  content={
                    <ReactTable
                      data={docs}
                      manual
                      onFetchData={this.fetchData.bind(this)}
                      filterable
                      columns={[
                        {
                          Header: "Ride Info",
                          Cell: (prop) => {
                            const ride = prop.original;
                            return (
                              <div
                                key={ride._id}
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                }}
                              >
                                <span>
                                  <strong>Service: </strong>{" "}
                                  {(ride.service && ride.service.name) || ""}
                                </span>
                                <span>
                                  <strong>Ride Number: </strong>{" "}
                                  {ride.rideNumber || ""}
                                </span>
                                <span>
                                  <strong>Status: </strong>
                                  {ride.status || ""}
                                </span>
                                <span>
                                  <strong>Type: </strong>
                                  {ride.rideType || ""}
                                </span>
                                <span>
                                  <strong>Départ: </strong>
                                  {ride.pickup.address || ""}
                                </span>
                                <span>
                                  <strong>Destination: </strong>
                                  {(ride.dropoff && ride.dropoff.address) || ""}
                                </span>
                                <span>
                                  <strong>Date création: </strong>
                                  {moment(ride.createdAt).format(
                                    "YYYY-MM-DD HH:mm"
                                  ) || ""}
                                </span>
                              </div>
                            );
                          },
                        },
                        {
                          Header: "Patient Info",
                          Cell: (prop) => {
                            const ride = prop.original;
                            return (
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                }}
                              >
                                <span>
                                  <strong>Nom: </strong>
                                  {ride.patient.lastname || ""}
                                </span>
                                <span>
                                  <strong>Status: </strong>
                                  {ride.patient.status || ""}
                                </span>
                                <span>
                                  <strong>Note: </strong>
                                  {ride.note || ""}
                                </span>
                                <span>
                                  <strong>Date prise en charge: </strong>
                                  {moment(ride.datetime).format(
                                    "YYYY-MM-DD HH:mm"
                                  ) || ""}
                                </span>
                              </div>
                            );
                          },
                        },
                        {
                          Header: "Vehicle Info",
                          Cell: (prop) => {
                            const ride = prop.original;
                            return (
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                }}
                              >
                                <span>
                                  <strong>Transporter: </strong>
                                  {(ride.transporter &&
                                    ride.transporter.company &&
                                    ride.transporter.company.name) ||
                                    ""}
                                  {(ride.taxi &&
                                    ride.taxi.driver &&
                                    ride.taxi.driver.firstname) ||
                                    ""}
                                </span>
                                <span>
                                  <strong>Category: </strong>
                                  {(ride.transporter &&
                                    ride.transporter.vehicle &&
                                    ride.transporter.vehicle.category) ||
                                    ""}
                                  {(ride.taxi &&
                                    ride.taxi.vehicle &&
                                    ride.taxi.vehicle.category) ||
                                    ""}
                                </span>
                                <span>
                                  <strong>Color: </strong>
                                  {(ride.transporter &&
                                    ride.transporter.vehicle &&
                                    ride.transporter.vehicle.color) ||
                                    ""}
                                  {(ride.taxi &&
                                    ride.taxi.vehicle &&
                                    ride.taxi.vehicle.color) ||
                                    ""}
                                </span>
                                <span>
                                  <strong>Plate: </strong>
                                  {(ride.transporter &&
                                    ride.transporter.vehicle &&
                                    ride.transporter.vehicle.plate) ||
                                    ""}
                                  {(ride.taxi &&
                                    ride.taxi.vehicle &&
                                    ride.taxi.vehicle.plate) ||
                                    ""}
                                </span>
                                <span>
                                  <strong>Model: </strong>
                                  {(ride.transporter &&
                                    ride.transporter.vehicle &&
                                    ride.transporter.vehicle.model) ||
                                    ""}
                                  {(ride.taxi &&
                                    ride.taxi.vehicle &&
                                    ride.taxi.vehicle.model) ||
                                    ""}
                                </span>
                                <span>
                                  <strong>Make: </strong>
                                  {(ride.transporter &&
                                    ride.transporter.vehicle &&
                                    ride.transporter.vehicle.make) ||
                                    ""}
                                  {(ride.taxi &&
                                    ride.taxi.vehicle &&
                                    ride.taxi.vehicle.make) ||
                                    ""}
                                </span>
                              </div>
                            );
                          },
                        },
                        {
                          Header: "Dispatching Info (Transporter | Cycle)",
                          Cell: (prop) => {
                            const ride = prop.original;
                            return (
                              <div
                                style={{
                                  flexDirection: "column",
                                  display: "flex",
                                }}
                              >
                                {(ride.invokedCompanies &&
                                  ride.invokedCompanies.map((e) => (
                                    <span>
                                      <strong> {e.name || ""}</strong>
                                      {" ==> "}
                                      {e.dispatchingCycle || ""}
                                    </span>
                                  ))) ||
                                  ""}

                                {(ride.invokedTaxis &&
                                  ride.invokedTaxis.map((e) => (
                                    <span>
                                      <strong>
                                        {" "}
                                        {e.firstname || ""} {e.lastname || ""}
                                      </strong>
                                    </span>
                                  ))) ||
                                  ""}
                              </div>
                            );
                          },
                        },
                      ]}
                      previousText={t("Common:ReactTable.previous_text_label", {
                        lng: i18n.language,
                      })}
                      nextText={t("Common:ReactTable.next_text_label", {
                        lng: i18n.language,
                      })}
                      ofText={t("Common:ReactTable.off_Text_label", {
                        lng: i18n.language,
                      })}
                      rowsText={t("Common:ReactTable.rows_Text_label", {
                        lng: i18n.language,
                      })}
                      loadingText={t("Common:ReactTable.loading_Text_label", {
                        lng: i18n.language,
                      })}
                      noDataText={t("Common:ReactTable.no_data_Text_label", {
                        lng: i18n.language,
                      })}
                      defaultPageSize={limit}
                      pages={totalPages}
                      showPaginationTop={false}
                      showPaginationBottom
                      className="-striped -highlight"
                    />
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rides: state.rides.rides.asMutable({ deep: true }),
    action: state.rides.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRidesRequest: (...args) =>
      dispatch(RidesActions.getRidesRequest(...args)),
  };
};

const RidesPageRedux = connect(mapStateToProps, mapDispatchToProps)(RidesPage);
export default withTranslation(["Private", "Common"], { wait: true })(
  RidesPageRedux
);

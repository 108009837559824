import { combineReducers } from "redux";

const AppReducers = combineReducers({
  account: require("./account").reducer,
  backOfficeCompanies: require("./company/get-companies").reducer,
  backOfficeCreateCompany: require("./company/add-company").reducer,
  backOfficeGetDetailsCompany: require("./company/company-details").reducer,
  backOfficeCreateAdminCompany: require("./company/add-company-admin").reducer,
  backOfficeTransporters: require("./transporter/get-transporters").reducer,
  backOfficeTransporterDetails: require("./transporter/transporter-details")
    .reducer,
  backOfficeCreateAdminTransporter:
    require("./transporter/add-transporter-admin").reducer,
  backOfficeCreateTransporter: require("./transporter/add-transporter").reducer,
  backOfficeAddAdminService: require("./company/add-user-service").reducer,
  backOfficeAddVehicle: require("./transporter/add-transporter-vehicle")
    .reducer,
  taxiGetTaxis: require("./taxi/taxi-getTaxis").reducer,
  taxiDetailsTaxis: require("./taxi/get-taxi-details").reducer,
  taxiVehicles: require("./taxi/taxi-vehicles").reducer,

  // SAMU
  backOfficeSamus: require("./samu/get-samus").reducer,
  backOfficeSectors: require("./samu/samu-sectors").reducer,
  backOfficeCreateSamu: require("./samu/add-samu").reducer,
  backOfficeSamuDetails: require("./samu/samu-details").reducer,
  backOfficeCreateAdminSamu: require("./samu/add-samu-admin").reducer,

  // RIDES
  rides: require("./ride").reducer,
});

export default AppReducers;

import React, { Component } from "react";
import { connect } from "react-redux";
import TaxisActions from "reducers/taxi/taxi-getTaxis";
import SweetAlert from "react-bootstrap-sweetalert";

// react component for creating dynamic tables
import ReactTable from "react-table";
import { Grid, Row, Col, Alert, Badge } from "react-bootstrap";
import AddNewTaxi from "./add-taxi";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { DRIVER_STATUS } from "global";
import ModifyTaxiUser from "./taxi-infos/taxi-modify-user";

class TaxiMainView extends Component {
  constructor(props) {
    super(props);
    //this.fetchData = this.fetchData.bind(this);
    this.state = {
      userToUpdate: null,
      alert: null,
      showModifyTaxiUserDriverPopUp: false,
      showAddNewTaxiModale: false,
    };

    this.onPressDeleteTaxiButton = this.onPressDeleteTaxiButton.bind(this);
  }

  warningWithConfirmAndCancelMessage(props) {
    const { t, i18n } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Suppression"
          onConfirm={() => this.onPressDeleteTaxiButton(props)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Oui supprimer"
          cancelBtnText="Non"
          showCancel
        >
          {t("transporterUsersPage.delete_company_user_message", {
            lng: i18n.language,
          })}
        </SweetAlert>
      ),
    });
  }
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  onPressDeleteTaxiButton(props) {
    const { taxiDeleteTaxiUserRequest } = this.props;

    taxiDeleteTaxiUserRequest(props._id);

    this.hideAlert();
  }
  componentDidMount() {
    const { taxiGetListRequest, list } = this.props;
    const { page, limit } = list;

    taxiGetListRequest(page, limit);
  }

  fetchData(state, instance) {
    const { page, pageSize } = state;
    const { taxiGetListRequest } = this.props;
    taxiGetListRequest(page + 1, pageSize);
  }

  render() {
    const { showAddNewTaxiModale } = this.state;

    const { action, history } = this.props;
    const {
      vehiclesDrivers,
      totalDocs = 0,
      totalPages = 0,
      limit,
    } = this.props.list;
    const { error, loading } = action;
    const { t, i18n } = this.props;

    return (
      <div className="main-content">
        {this.state.alert}

        <Grid fluid>
          <Row className="text-center">
            <Col md={6} sm={6} mdOffset={3} smOffset={3}>
              {error !== "" ? (
                <Alert bsStyle="danger">
                  <span>{error}</span>
                </Alert>
              ) : null}
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={100}
                width={100}
                visible={loading}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title={
                  <Row>
                    <Col md={8}>
                      <h2 className="d-inline">
                        Liste des taxi{" "}
                        <Badge variant="secondary">{totalDocs}</Badge>
                      </h2>
                    </Col>
                    <Col md={4} className="text-right">
                      <AddNewTaxi
                        {...{ ...this.props }}
                        show={showAddNewTaxiModale}
                        onHide={() => {
                          this.setState(
                            Object.assign({}, this.state, {
                              showAddNewTaxiModale: false,
                            })
                          );
                        }}
                      />
                      <ModifyTaxiUser
                        taxiUser={this.state.userToUpdate}
                        show={this.state.showModifyTaxiUserDriverPopUp}
                        onHide={() => {
                          this.setState(
                            Object.assign({}, this.state, {
                              showModifyTaxiUserDriverPopUp: false,
                            })
                          );
                        }}
                      />
                      <Button
                        bsStyle="info"
                        fill
                        wd
                        onClick={() => {
                          this.setState(
                            Object.assign({}, this.state, {
                              showAddNewTaxiModale: true,
                            })
                          );
                        }}
                      >
                        {t("taxisPage.taxis_title_headline", {
                          lng: i18n.language,
                        })}
                      </Button>
                    </Col>
                  </Row>
                }
                content={
                  <ReactTable
                    data={vehiclesDrivers}
                    manual
                    onFetchData={this.fetchData.bind(this)}
                    filterable
                    columns={[
                      {
                        Header: (
                          <>
                            {" "}
                            {t(
                              "taxisPage.ambulancier_react_table_column_name",
                              { lng: i18n.language }
                            )}
                          </>
                        ),
                        accessor: "driver",
                        Cell: (prop) => {
                          const { firstname, lastname } = prop.original;
                          return `${firstname} - ${lastname}`;
                        },
                      },
                      {
                        Header: (
                          <>
                            {" "}
                            {t("taxisPage.email_react_table_column_name", {
                              lng: i18n.language,
                            })}
                          </>
                        ),
                        Cell: (prop) => {
                          const { email } = prop.original;

                          //   desc.shift()
                          //   desc = _.join(desc, ",");

                          return `${email} `;
                        },
                      },
                      {
                        Header: (
                          <>
                            {" "}
                            {t("taxisPage.status_react_table_column_name", {
                              lng: i18n.language,
                            })}
                          </>
                        ),
                        accessor: "",
                        Cell: (prop) => {
                          const { status } = prop.original;

                          let icon =
                            status == "invoked"
                              ? "fa fa-spin fa-refresh"
                              : "fa fa-spin fa-spinner";
                          return (
                            <div className={`text-left ${status}`}>
                              {t(`Common:driver.Status.${status}`, {
                                lng: i18n.language,
                              })}{" "}
                              {status == "busy" || status == "invoked" ? (
                                <i className={`${icon} pull-right`} />
                              ) : null}
                            </div>
                          );
                        },
                      },
                      {
                        Header: (
                          <>
                            {" "}
                            {t("taxisPage.active_react_table_column_name", {
                              lng: i18n.language,
                            })}
                          </>
                        ),
                        accessor: "",
                        Cell: (prop) => {
                          const { active } = prop.original;

                          return active ? (
                            <i className="fa fa-check-square text-success" />
                          ) : (
                            <i className=" fa fa-square-o text-danger" />
                          );
                        },
                      },
                      {
                        Header: (
                          <>
                            {" "}
                            {t("taxisPage.make_model_react_table_column_name", {
                              lng: i18n.language,
                            })}
                          </>
                        ),
                        accessor: "",
                        Cell: (prop) => {
                          const { vehicle } = prop.original;
                          if (vehicle !== null) {
                            const { make, model } = vehicle;
                            const makeMajuscule = make.toUpperCase();

                            const modelMajuscule = model.toUpperCase();

                            return `${makeMajuscule} - ${modelMajuscule}`;
                          } else {
                            return null;
                          }
                        },
                      },

                      {
                        Header: (
                          <>
                            {" "}
                            {t("taxisPage.plate_react_table_column_name", {
                              lng: i18n.language,
                            })}
                          </>
                        ),
                        accessor: "",
                        Cell: (prop) => {
                          const { vehicle } = prop.original;
                          if (vehicle !== null) {
                            const { plate } = vehicle;
                            const plateMajuscule = plate.toUpperCase();
                            return `${plateMajuscule}`;
                          } else {
                            return null;
                          }
                        },
                      },

                      {
                        Header: (
                          <>
                            {" "}
                            {t("Common:ReactTable.action", {
                              lng: i18n.language,
                            })}
                          </>
                        ),
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        Cell: (prop) => {
                          const { _id } = prop.original;
                          return (
                            <div className="actions-right">
                              <Button
                                bsStyle="dark"
                                simple
                                icon
                                title="view"
                                onClick={() =>
                                  history.push(`taxi-infos/${_id}`)
                                }
                              >
                                <i className="fa fa-eye" />
                              </Button>{" "}
                              <Button
                                bsStyle="dark"
                                simple
                                icon
                                title="edit"
                                onClick={() => {
                                  this.setState(
                                    Object.assign({}, this.state, {
                                      showModifyTaxiUserDriverPopUp: true,
                                      userToUpdate: prop.original,
                                    })
                                  );
                                }}
                              >
                                <i className="fa fa-pencil" />
                              </Button>{" "}
                              <Button
                                bsStyle="dark"
                                simple
                                icon
                                title="delete"
                                onClick={() => {
                                  this.warningWithConfirmAndCancelMessage(
                                    prop.original
                                  );
                                }}
                              >
                                <i className="fa fa-trash" />
                              </Button>{" "}
                            </div>
                          );
                        },
                      },
                    ]}
                    previousText={t("Common:ReactTable.previous_text_label", {
                      lng: i18n.language,
                    })}
                    nextText={t("Common:ReactTable.next_text_label", {
                      lng: i18n.language,
                    })}
                    ofText={t("Common:ReactTable.off_Text_label", {
                      lng: i18n.language,
                    })}
                    rowsText={t("Common:ReactTable.rows_Text_label", {
                      lng: i18n.language,
                    })}
                    loadingText={t("Common:ReactTable.loading_Text_label", {
                      lng: i18n.language,
                    })}
                    noDataText={t("Common:ReactTable.no_data_Text_label", {
                      lng: i18n.language,
                    })}
                    defaultPageSize={limit}
                    pages={totalPages}
                    showPaginationTop={false}
                    showPaginationBottom
                    className="-striped -highlight"
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.taxiGetTaxis.list.asMutable({ deep: true }),
    action: state.taxiGetTaxis.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    taxiGetListRequest: (...args) =>
      dispatch(TaxisActions.taxiGetListRequest(...args)),
    taxiDeleteTaxiUserRequest: (...args) =>
      dispatch(TaxisActions.taxiDeleteTaxiUserRequest(...args)),
  };
};

const taxisRedux = connect(mapStateToProps, mapDispatchToProps)(TaxiMainView);
export default withTranslation(["Private", "Common"], { wait: true })(
  taxisRedux
);

import { put, takeLatest } from "redux-saga/effects";
import TaxisActions, { VehiclesTypes } from "reducers/taxi/taxi-vehicles";
import errorHandler from "sagas/error-handler";

import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* TaxiListVehiclesAsyncRequest({ taxiId, page, limit }) {
  const query = ` {
        TaxiVehiclesForTaxiUser(taxiUserId:"${taxiId}",page :${page},limit:${limit}) {
          page limit totalPages totalDocs
          vehicles{_id plate model options make  inService inUse}}
      }
    `;

  try {
    const data = yield graphQLClient().request(query);

    const vehicles = data.TaxiVehiclesForTaxiUser;
    yield put(TaxisActions.taxiGetVehiclesRequestSuccess(vehicles));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, TaxisActions.taxiGetVehiclesRequestFailure);
  }
}

function* taxiCreateTransporterVehicleAsyncRequest({
  vehicle,
  taxiId,
  callback,
}) {
  const { model = "", plate = "", make = "", color = "", options } = vehicle;
  const query = `
      mutation {
        BackOfficeTaxiAddVehicle(
          vehicle:{
            plate:"${plate}",
            model:"${model}",
            make:"${make}",
            color:"${color}",
            options:[${options}],
          }
          taxiDriverId:"${taxiId}"
        )
        { _id plate model make options inService inUse}
      }
    `;

  try {
    yield graphQLClient().request(query);

    yield put(TaxisActions.taxiCreateVehicleRequestSuccess());
    yield put(TaxisActions.taxiGetVehiclesRequest(taxiId, 1, 10));

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, TaxisActions.taxiCreateVehicleRequestFailure);

    if (_.isFunction(callback)) callback(error);
  }
}
export default [
  takeLatest(
    VehiclesTypes.TAXI_GET_VEHICLES_REQUEST,
    TaxiListVehiclesAsyncRequest
  ),
  takeLatest(
    VehiclesTypes.TAXI_CREATE_VEHICLE_REQUEST,
    taxiCreateTransporterVehicleAsyncRequest
  ),
];

import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton";
import _ from "lodash";
import NewCompanyAdmin from "./company-admin";
import { withTranslation } from "react-i18next";
import ModifyCompanyUser from "./company-modify-user";
import SweetAlert from "react-bootstrap-sweetalert";
import CompanyDetailsActions from "reducers/company/company-details";

class UsersTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModifyCompanyUserPopUp: false,
      userToUpdate: null,
      alert: null,
      showAddNewAdminModale: false,
    };
    this.onPressCancelRideButton = this.onPressCancelRideButton.bind(this);
  }

  componentDidMount() {}
  warningWithConfirmAndCancelMessage(props) {
    const { t, i18n } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Suppression"
          onConfirm={() => this.onPressCancelRideButton(props)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Oui supprimer"
          cancelBtnText="Non"
          showCancel
        >
          {t("transporterUsersPage.delete_company_user_message", {
            lng: i18n.language,
          })}
        </SweetAlert>
      ),
    });
  }
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  onPressCancelRideButton(props) {
    const { backOfficeDeleteUserCompanyRequest } = this.props;
    const { companyId } = this.props;

    backOfficeDeleteUserCompanyRequest(props._id, companyId);

    this.hideAlert();
  }
  renderAdmins() {
    const { admins } = this.props;
    const { t, i18n } = this.props;

    return (
      <ReactTable
        data={admins}
        filterable
        columns={[
          {
            Header: (
              <>
                {" "}
                {t("companyUsersPage.firstname_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "firstname",
          },
          {
            Header: (
              <>
                {" "}
                {t("companyUsersPage.lastname_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "lastname",
          },
          {
            Header: (
              <>
                {" "}
                {t("companyUsersPage.email_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "email",
          },
          {
            Header: (
              <> {t("Common:ReactTable.action", { lng: i18n.language })}</>
            ),
            accessor: "actions",
            sortable: false,
            filterable: false,
            Cell: (prop) => {
              return (
                <div className="actions-right">
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="view"
                    //onClick={() => history.push(`amb-company/${_id}/`)}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    onClick={() => {
                      this.setState(
                        Object.assign({}, this.state, {
                          showModifyCompanyUserPopUp: true,
                          userToUpdate: prop.original,
                        })
                      );
                    }}
                    title="edit"
                  >
                    <i className="fa fa-pencil" />
                  </Button>{" "}
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="delete"
                    onClick={() => {
                      this.warningWithConfirmAndCancelMessage(prop.original);
                    }}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                </div>
              );
            },
          },
        ]}
        previousText={t("Common:ReactTable.previous_text_label", {
          lng: i18n.language,
        })}
        nextText={t("Common:ReactTable.next_text_label", {
          lng: i18n.language,
        })}
        ofText={t("Common:ReactTable.off_Text_label", { lng: i18n.language })}
        rowsText={t("Common:ReactTable.rows_Text_label", {
          lng: i18n.language,
        })}
        loadingText={t("Common:ReactTable.loading_Text_label", {
          lng: i18n.language,
        })}
        noDataText={t("Common:ReactTable.no_data_Text_label", {
          lng: i18n.language,
        })}
        showPaginationTop={false}
        showPaginationBottom
        className="-striped -highlight"
      />
    );
  }

  render() {
    const { t, i18n } = this.props;
    const { companyId } = this.props;

    const { showAddNewAdminModale } = this.state;
    return (
      <Card
        title=""
        category=""
        content={
          <div>
            {this.state.alert}

            <NewCompanyAdmin
              {...{ ...this.props }}
              show={showAddNewAdminModale}
              onHide={() => {
                this.setState(
                  Object.assign({}, this.state, {
                    showAddNewAdminModale: false,
                  })
                );
              }}
            />
            <ModifyCompanyUser
              companyId={companyId}
              companyUser={this.state.userToUpdate}
              show={this.state.showModifyCompanyUserPopUp}
              onHide={() => {
                this.setState(
                  Object.assign({}, this.state, {
                    showModifyCompanyUserPopUp: false,
                  })
                );
              }}
            />
            <Button
              bsStyle="success"
              fill
              wd
              onClick={() => {
                this.setState(
                  Object.assign({}, this.state, { showAddNewAdminModale: true })
                );
              }}
            >
              {t("companyUsersPage.company_add_user_admin_button", {
                lng: i18n.language,
              })}
            </Button>
            {this.renderAdmins()}
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeDeleteUserCompanyRequest: (...args) =>
      dispatch(
        CompanyDetailsActions.backOfficeDeleteUserCompanyRequest(...args)
      ),
  };
};

const CompanyUsersRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTab);
export default withTranslation(["Private", "Common"], { wait: true })(
  CompanyUsersRedux
);

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeCreateSamuAdminRequest: ["admin", "companyId", "callback"],
  backOfficeCreateSamuAdminRequestSuccess: null,
  backOfficeCreateSamuAdminRequestFailure: ["error"],
  backOfficeClearError: null,
});

export const AddSamuAdminTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  action: {
    loading: false,
    error: "",
  },
});

export const backOfficeClearError = (state, action) => {
  return Immutable.setIn(state, ["action", "error"], "");
};

export const backOfficeCreateSamuAdminRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeCreateSamuAdminRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
  //return Immutable.setIn(nextSate, ["company", 'users'], [action.admin, ...state.company.users.admins])
};

export const backOfficeCreateSamuAdminRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_CREATE_SAMU_ADMIN_REQUEST]:
    backOfficeCreateSamuAdminRequest,
  [Types.BACK_OFFICE_CREATE_SAMU_ADMIN_REQUEST_SUCCESS]:
    backOfficeCreateSamuAdminRequestSuccess,
  [Types.BACK_OFFICE_CREATE_SAMU_ADMIN_REQUEST_FAILURE]:
    backOfficeCreateSamuAdminRequestFailure,
  [Types.BACK_OFFICE_CLEAR_ERROR]: backOfficeClearError,
});

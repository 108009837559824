import { put, takeLatest } from "redux-saga/effects";
import SamusActions, { SamusTypes } from "reducers/samu/get-samus";
import errorHandler from "sagas/error-handler";

import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* backOfficeTranspotersListAsyncRequest({ page, limit }) {
  const query = `
      query {
        BackOfficeSamus(page: ${page}, limit: ${limit})
        { 

        limit,
        page,
        totalDocs,
        totalPages,
        samus {
            _id name address{address} active}
        }
      }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const samus = data.BackOfficeSamus;
    yield put(SamusActions.backOfficeSamusListRequestSuccess(samus));
  } catch (error) {
    const { message = "" } = error.response.errors[0];

    yield errorHandler(message, SamusActions.backOfficeSamusListRequestFailure);
  }
}
export default [
  takeLatest(
    SamusTypes.BACK_OFFICE_SAMUS_LIST_REQUEST,
    backOfficeTranspotersListAsyncRequest
  ),
];

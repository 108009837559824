import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import NewTaxiVehicle from "./add-taxi-vehicle";
import TaxisActions from "reducers/taxi/taxi-vehicles";
import { withTranslation } from "react-i18next";
import _ from "lodash";
class VehiclesTab extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);

    this.state = {
      showAddNewVehicleModal: false,
    };
  }

  fetchData(state, instance) {
    const { page, pageSize } = state;
    const { taxiGetVehiclesRequest, taxiId } = this.props;
    taxiGetVehiclesRequest(taxiId, page + 1, pageSize);
  }

  componentDidMount() {
    const { taxiGetVehiclesRequest, taxiId } = this.props;

    const { page, limit } = this.props.list;

    taxiGetVehiclesRequest(taxiId, page, limit);
  }

  renderVehicules() {
    const { vehicles, totalPages = 0, limit } = this.props.list;

    const { t, i18n } = this.props;

    return (
      <ReactTable
        data={vehicles}
        manual
        onFetchData={this.fetchData.bind(this)}
        filterable
        columns={[
          {
            Header: (
              <>
                {" "}
                {t("taxiVehiclesPage.make_model_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "",
            Cell: (prop) => {
              const { make, model } = prop.original;
              const modelMajuscule = model.toUpperCase();
              const makeMajuscule = make.toUpperCase();

              return `${makeMajuscule} - ${modelMajuscule}`;
            },
          },
          {
            Header: (
              <>
                {" "}
                {t("taxiVehiclesPage.plate_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "",
            Cell: (prop) => {
              const { plate } = prop.original;
              const plateMajuscule = plate.toUpperCase();
              return `${plateMajuscule}`;
            },
          },
          {
            Header: "Options",
            Cell: (prop) => {
              const { options } = prop.original;

              const _options = _.reduce(
                options,
                (acc, v, k) => {
                  return v ? [...acc, v] : acc;
                },
                []
              );

              return _.reduce(
                _options,
                (acc, o) => {
                  return [
                    ...acc,
                    <div class="d-block">
                      {" "}
                      {t(`Common:vehicle.options.${o}`, {
                        lng: i18n.language,
                      })}{" "}
                    </div>,
                  ];
                },
                []
              );
            },
          },

          {
            Header: (
              <>
                {" "}
                {t("taxiVehiclesPage.in_service_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "",
            Cell: (prop) => {
              const { inService } = prop.original;

              return inService ? (
                <i className="fa fa-check-square text-success" />
              ) : (
                <i className=" fa fa-square-o text-danger" />
              );
            },
          },

          {
            Header: (
              <>
                {" "}
                {t("taxiVehiclesPage.in_Use_react_table_column_name", {
                  lng: i18n.language,
                })}
              </>
            ),
            accessor: "",
            Cell: (prop) => {
              const { inUse } = prop.original;

              return inUse ? (
                <i className="fa fa-check-square text-success" />
              ) : (
                <i className=" fa fa-square-o text-danger" />
              );
            },
          },
        ]}
        previousText={t("Common:ReactTable.previous_text_label", {
          lng: i18n.language,
        })}
        nextText={t("Common:ReactTable.next_text_label", {
          lng: i18n.language,
        })}
        ofText={t("Common:ReactTable.off_Text_label", { lng: i18n.language })}
        rowsText={t("Common:ReactTable.rows_Text_label", {
          lng: i18n.language,
        })}
        loadingText={t("Common:ReactTable.loading_Text_label", {
          lng: i18n.language,
        })}
        noDataText={t("Common:ReactTable.no_data_Text_label", {
          lng: i18n.language,
        })}
        showPaginationTop={false}
        showPaginationBottom
        className="-striped -highlight"
      />
    );
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <Card
        title=""
        category=""
        content={
          <div>
            <NewTaxiVehicle
              {...{ ...this.props }}
              show={this.state.showAddNewVehicleModal}
              onHide={() => {
                this.setState(
                  Object.assign({}, this.state, {
                    showAddNewVehicleModal: false,
                  })
                );
              }}
            />
            <Button
              bsStyle="primary"
              fill
              wd
              onClick={() => {
                this.setState(
                  Object.assign({}, this.state, {
                    showAddNewVehicleModal: true,
                  })
                );
              }}
            >
              {t("taxiVehiclesPage.taxi_vehicle_headline_title", {
                lng: i18n.language,
              })}
            </Button>
            {this.renderVehicules()}
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.taxiVehicles.list.asMutable({ deep: true }),
    action: state.taxiVehicles.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    taxiGetVehiclesRequest: (...args) =>
      dispatch(TaxisActions.taxiGetVehiclesRequest(...args)),
  };
};

const vehiclesInfosRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehiclesTab);
export default withTranslation(["Private", "Common"], { wait: true })(
  vehiclesInfosRedux
);

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeCreateSamuRequest: [
    "siret",
    "name",
    "address",
    "billing_address",
    "email",
    "phones",
    "web",
    "contact",
    "billing",
    "rib",
    "callback",
  ],
  backOfficeCreateSamuRequestFailure: ["error"],
  backOfficeCreateSamuRequestSuccess: null,
});

export const AddSamuTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  action: {
    loading: false,
    error: "",
  },
});

export const backOfficeCreateSamuRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeCreateSamuRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeCreateSamuRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_CREATE_SAMU_REQUEST]: backOfficeCreateSamuRequest,
  [Types.BACK_OFFICE_CREATE_SAMU_REQUEST_FAILURE]:
    backOfficeCreateSamuRequestFailure,
  [Types.BACK_OFFICE_CREATE_SAMU_REQUEST_SUCCESS]:
    backOfficeCreateSamuRequestSuccess,
});

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeSamusResetState: null,
  backOfficeSamusListRequest: ["page", "limit"],
  backOfficeSamusListRequestSuccess: ["samus"],
  backOfficeSamusListRequestFailure: ["error"],
});

export const SamusTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  list: {
    page: 1,
    limit: 10,
    samus: [],
  },

  action: {
    loading: false,
    error: "",
  },
});

/// GENERAL
export const backOfficeSamusResetState = (state, action) => {
  return INITIAL_STATE;
};

export const backOfficeSamusListRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeSamusListRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeSamusListRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["list"], action.samus);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_SAMUS_RESET_STATE]: backOfficeSamusResetState,
  [Types.BACK_OFFICE_SAMUS_LIST_REQUEST]: backOfficeSamusListRequest,
  [Types.BACK_OFFICE_SAMUS_LIST_REQUEST_SUCCESS]:
    backOfficeSamusListRequestSuccess,
  [Types.BACK_OFFICE_SAMUS_LIST_REQUEST_FAILURE]:
    backOfficeSamusListRequestFailure,
});

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  taxiGetListResetState: null,
  taxiGetVehiclesRequest: ["taxiId", "page", "limit"],
  taxiGetVehiclesRequestSuccess: ["vehicles"],
  taxiGetVehiclesRequestFailure: ["error"],

  taxiCreateVehicleRequest: ["vehicle", "taxiId", "callback"],
  taxiCreateVehicleRequestSuccess: ["vehicle"],
  taxiCreateVehicleRequestFailure: ["error"],
});

export const VehiclesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  list: {
    page: 1,
    limit: 10,
    vehicles: [],
  },

  action: {
    loading: false,
    error: "",
  },
});

/// GENERAL
export const taxiGetListResetState = (state, action) => {
  return INITIAL_STATE;
};

export const taxiGetVehiclesRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const taxiGetVehiclesRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["list"], action.vehicles);
};

export const taxiGetVehiclesRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const taxiCreateVehicleRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const taxiCreateVehicleRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const taxiCreateVehicleRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TAXI_GET_LIST_RESET_STATE]: taxiGetListResetState,
  [Types.TAXI_GET_VEHICLES_REQUEST]: taxiGetVehiclesRequest,
  [Types.TAXI_GET_VEHICLES_REQUEST_SUCCESS]: taxiGetVehiclesRequestSuccess,
  [Types.TAXI_GET_VEHICLES_REQUEST_FAILURE]: taxiGetVehiclesRequestFailure,

  [Types.TAXI_CREATE_VEHICLE_REQUEST]: taxiCreateVehicleRequest,
  [Types.TAXI_CREATE_VEHICLE_REQUEST_SUCCESS]: taxiCreateVehicleRequestSuccess,
  [Types.TAXI_CREATE_VEHICLE_REQUEST_FAILURE]: taxiCreateVehicleRequestFailure,
});

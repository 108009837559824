import { put, takeLatest } from "redux-saga/effects";
import TaxiDetailsActions, {
  TaxiDetailsType,
} from "reducers/taxi/get-taxi-details";
import errorHandler from "sagas/error-handler";

import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* TaxiGetDetailsAsyncRequest({ taxiId }) {
  const query = `
      {
        TaxiUserDetail(taxiDriverId:"${taxiId}")
        {
            _id firstname lastname avatar status email active vehicle {id plate model make}
        }
     }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const taxi = data.TaxiUserDetail;
    yield put(TaxiDetailsActions.taxiGetDetailsRequestSuccess(taxi));
  } catch (error) {
    const { message = "" } = error.response.errors[0];

    yield errorHandler(
      message,
      TaxiDetailsActions.taxiGetDetailsRequestFailure
    );
  }
}
export default [
  takeLatest(
    TaxiDetailsType.TAXI_GET_DETAILS_REQUEST,
    TaxiGetDetailsAsyncRequest
  ),
];

import React, { Component } from "react";
import { connect } from "react-redux";
import TaxisActions from "reducers/taxi/taxi-vehicles";
import {
  Row,
  Alert,
  Col,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
} from "react-bootstrap";
import { withTranslation } from "react-i18next";

import Button from "components/CustomButton/CustomButton.jsx";
import _ from "lodash";
import { Formik } from "formik";
import { string, object } from "yup";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";

const validationSchema = object().shape({
  plate: string().required("required"),
  model: string().required("required"),
  make: string().required("required"),
  color: string().required("required"),
});

class NewVehicle extends Component {
  constructor(props) {
    super(props);

    this.formikProps = null;
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {}

  onSubmitForm(values, { resetForm, setSubmitting }) {
    const { taxiCreateVehicleRequest, taxiId, onHide } = this.props;
    const { options, ...rest } = values;

    const _vehicleOptions = _.reduce(
      options,
      (acc, v, k) => {
        return v ? [...acc, k] : acc;
      },
      []
    );

    taxiCreateVehicleRequest(
      { options: _vehicleOptions, ...rest },
      taxiId,
      (error) => {
        if (error) {
          return;
        }

        setSubmitting(false);
        resetForm();

        onHide();
      }
    );
  }

  render() {
    const { action } = this.props;
    const { t, i18n } = this.props;

    const { error } = action;
    return (
      <Formik
        initialValues={{
          plate: "",
          model: "",
          make: "",
          color: "",
          options: {
            child_safety_seat: false,
          },
        }}
        validationSchema={validationSchema}
        onSubmit={this.onSubmitForm}
      >
        {(props) => {
          const { errors, touched } = props;

          return (
            <Modal
              {...this.props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered="true"
              backdrop="static"
            >
              {/* {
                                    error !== "" ? <Alert bsStyle="danger">
                                        <span>{error}</span>
                                    </Alert> : null
                                } */}
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t("taxiAddVehiclePopUp.add_vehicle_popup_title_headline", {
                    lng: i18n.language,
                  })}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form horizontal>
                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {t("taxiAddVehiclePopUp.add_user_popup_make_label", {
                        lng: i18n.language,
                      })}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "taxiAddVehiclePopUp.add_user_popup_placeholder_make",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "make") && _.has(touched, "make")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("make")}
                        onInput={() =>
                          props.setFieldTouched("make", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("make", value, true);
                        }}
                        value={props.values.make}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {t("taxiAddVehiclePopUp.add_user_popup_model_label", {
                        lng: i18n.language,
                      })}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "taxiAddVehiclePopUp.add_user_popup_placeholder_model",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "model") && _.has(touched, "model")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("model")}
                        onInput={() =>
                          props.setFieldTouched("model", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("model", value, true);
                        }}
                        value={props.values.model}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {t("taxiAddVehiclePopUp.add_user_popup_plate_label", {
                        lng: i18n.language,
                      })}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "taxiAddVehiclePopUp.add_user_popup_placeholder_plate",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "plate") && _.has(touched, "plate")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("plate")}
                        onInput={() =>
                          props.setFieldTouched("plate", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("plate", value, true);
                        }}
                        value={props.values.plate}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {t("taxiAddVehiclePopUp.add_user_popup_color_label", {
                        lng: i18n.language,
                      })}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder={t(
                          "taxiAddVehiclePopUp.add_user_popup_placeholder_color",
                          { lng: i18n.language }
                        )}
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "color") && _.has(touched, "color")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("color")}
                        onInput={() =>
                          props.setFieldTouched("color", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("color", value, true);
                        }}
                        value={props.values.color}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-sm-3">
                      {t("taxiAddVehiclePopUp.add_user_popup_options_label", {
                        lng: i18n.language,
                      })}
                    </ControlLabel>
                    <Col md={9}>
                      <Row>
                        <Col md={5}>
                          <Checkbox
                            inline
                            number="1"
                            checked={props.values.options.CHILD_SAFETY_SEAT}
                            label={t(
                              "taxiAddVehiclePopUp.add_user_popup_CHILD_SAFETY_SEAT_checkBox_label",
                              { lng: i18n.language }
                            )}
                            onChange={(e) => {
                              props.handleChange("options.CHILD_SAFETY_SEAT");
                              props.setFieldValue(
                                "options.CHILD_SAFETY_SEAT",
                                e.target.checked,
                                true
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  // disabled={props.isSubmitting}
                  onClick={() => {
                    // props.resetForm()
                    // this.props.backOfficeClearError()
                    this.props.onHide();
                  }}
                >
                  {t("Common:footerPage.close", { lng: i18n.language })}
                </Button>
                <Button
                  bsStyle="success"
                  fill
                  // disabled={props.isSubmitting}
                  onClick={() => {
                    props.handleSubmit();
                  }}
                >
                  {t("Common:footerPage.save_changes", { lng: i18n.language })}
                </Button>
              </Modal.Footer>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    taxiCreateVehicleRequest: (...args) =>
      dispatch(TaxisActions.taxiCreateVehicleRequest(...args)),
  };
};

const addTaxiVehicleRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewVehicle);
export default withTranslation(["Private", "Common"], { wait: true })(
  addTaxiVehicleRedux
);

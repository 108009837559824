import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Grid, Row, Col, Alert, Badge } from "react-bootstrap";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import TransportersActions from "reducers/transporter/get-transporters";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

class Prestataires extends Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    const { backOfficeTransportersListRequest, list } = this.props;
    const { page, limit } = list;

    backOfficeTransportersListRequest(page, limit);
  }

  fetchData(state, instance) {
    const { page, pageSize } = state;
    const { backOfficeTransportersListRequest } = this.props;
    backOfficeTransportersListRequest(page + 1, pageSize);
  }

  render() {
    const { t, i18n } = this.props;
    const { action, list, history } = this.props;
    const { companies, totalDocs = 0, totalPages = 0, limit } = list;
    const { error, loading } = action;

    return (
      <div className="main-content">
        <Grid fluid>
          <Row className="text-center">
            <Col md={6} sm={6} mdOffset={3} smOffset={3}>
              {error !== "" ? (
                <Alert bsStyle="danger">
                  <span>{error}</span>
                </Alert>
              ) : null}
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={100}
                width={100}
                visible={loading}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title={
                  <Row>
                    <Col md={8}>
                      <h2 className="d-inline">
                        {t("transportersPage.transporters_list_headline", {
                          lng: i18n.language,
                        })}{" "}
                        <Badge variant="secondary">{totalDocs}</Badge>
                      </h2>
                    </Col>
                    <Col md={4} className="text-right">
                      <Button bsStyle="info" fill wd href={`#/add-prestatire`}>
                        {t("transportersPage.transporters_add_company_button", {
                          lng: i18n.language,
                        })}
                      </Button>
                    </Col>
                  </Row>
                }
                content={
                  <ReactTable
                    data={companies}
                    manual
                    onFetchData={this.fetchData.bind(this)}
                    filterable
                    columns={[
                      {
                        Header: (
                          <>
                            {" "}
                            {t("transportersPage.nss_react_table_column_name", {
                              lng: i18n.language,
                            })}
                          </>
                        ),
                        accessor: "name",
                        Cell: (prop) => {
                          const { name } = prop.original;
                          return (
                            <>
                              <i className="fa fa-building-o" /> {name}
                            </>
                          );
                        },
                      },
                      {
                        Header: (
                          <>
                            {" "}
                            {t(
                              "transportersPage.nss_react_table_column_short_name",
                              { lng: i18n.language }
                            )}
                          </>
                        ),
                        accessor: "shortName",
                      },
                      {
                        Header: (
                          <>
                            {" "}
                            {t(
                              "transportersPage.address_react_table_column_name",
                              { lng: i18n.language }
                            )}
                          </>
                        ),
                        Cell: (prop) => {
                          const { address = "" } = prop.original.address;
                          let desc = _.split(address, ",");
                          //   desc.shift()
                          //   desc = _.join(desc, ",");
                          return (
                            <>
                              <i className="fa fa-map-marker" /> {`${desc}`}
                            </>
                          );
                        },
                      },
                      {
                        Header: (
                          <>
                            {" "}
                            {t(
                              "transportersPage.active_react_table_column_name",
                              { lng: i18n.language }
                            )}
                          </>
                        ),
                        Cell: (prop) => {
                          const { active = false } = prop.original;
                          return (
                            <Badge
                              className={
                                active ? "badge-success" : "badge-warning"
                              }
                            >
                              {active ? "active" : "disabled"}
                            </Badge>
                          );
                        },
                      },
                      {
                        Header: (
                          <>
                            {" "}
                            {t("Common:ReactTable.action", {
                              lng: i18n.language,
                            })}
                          </>
                        ),
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        Cell: (prop) => {
                          const { _id } = prop.original;
                          return (
                            <div className="actions-right">
                              <Button
                                bsStyle="dark"
                                simple
                                icon
                                title="view"
                                onClick={() =>
                                  history.push(`amb-company/${_id}`)
                                }
                              >
                                <i className="fa fa-eye" />
                              </Button>{" "}
                            </div>
                          );
                        },
                      },
                    ]}
                    previousText={t("Common:ReactTable.previous_text_label", {
                      lng: i18n.language,
                    })}
                    nextText={t("Common:ReactTable.next_text_label", {
                      lng: i18n.language,
                    })}
                    ofText={t("Common:ReactTable.off_Text_label", {
                      lng: i18n.language,
                    })}
                    rowsText={t("Common:ReactTable.rows_Text_label", {
                      lng: i18n.language,
                    })}
                    loadingText={t("Common:ReactTable.loading_Text_label", {
                      lng: i18n.language,
                    })}
                    noDataText={t("Common:ReactTable.no_data_Text_label", {
                      lng: i18n.language,
                    })}
                    defaultPageSize={limit}
                    pages={totalPages}
                    showPaginationTop={false}
                    showPaginationBottom
                    className="-striped -highlight"
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.backOfficeTransporters.list.asMutable({ deep: true }),
    action: state.backOfficeTransporters.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeTransportersListRequest: (...args) =>
      dispatch(TransportersActions.backOfficeTransportersListRequest(...args)),
  };
};

const transportersRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(Prestataires);
export default withTranslation(["Private", "Common"], { wait: true })(
  transportersRedux
);

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeCreateTransporterRequest: [
    "siret",
    "name",
    "shortName",
    "address",
    "billing_address",
    "email",
    "phones",
    "web",
    "contact",
    "billing",
    "rib",
    "callback",
  ],
  backOfficeCreateTransporterRequestFailure: ["error"],
  backOfficeCreateTransporterRequestSuccess: null,
});

export const AddTransporterTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  action: {
    loading: false,
    error: "",
  },
});

export const backOfficeCreateTransporterRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeCreateTransporterRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeCreateTransporterRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_CREATE_TRANSPORTER_REQUEST]:
    backOfficeCreateTransporterRequest,
  [Types.BACK_OFFICE_CREATE_TRANSPORTER_REQUEST_FAILURE]:
    backOfficeCreateTransporterRequestFailure,
  [Types.BACK_OFFICE_CREATE_TRANSPORTER_REQUEST_SUCCESS]:
    backOfficeCreateTransporterRequestSuccess,
});

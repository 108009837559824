import _ from "lodash";
import { put, takeLatest } from "redux-saga/effects";
import TransporterDetailsActions, {
  TransporterDetailsType,
} from "reducers/transporter/transporter-details";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* backOfficeGetTransporterDetailsAsyncRequest({ companyId }) {
  const query = `
    {
      BackOfficeTransporterWithId(companyId:"${companyId}") {
        _id
        siret
        active
        name
        shortName
        email
        web
        billing { business_model }
        billing{ amount }
        driverRegister { code expireAt createdAt }
        services {
          _id
          name
          address { address }
          contact { firstname lastname email phones }
        }
      }
    }
  `;
  try {
    const { BackOfficeTransporterWithId } = yield graphQLClient().request(
      query
    );
    yield put(
      TransporterDetailsActions.backOfficeGetTransporterDetailsRequestSuccess(
        BackOfficeTransporterWithId
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterDetailsActions.backOfficeGetTransporterDetailsRequestFailure
    );
  }
}

function* backOfficeTransporterUsersAsyncRequest({ companyId }) {
  const query = `
    {
      BackOfficeTransporterUsers(companyId: "${companyId}") {
        _id firstname lastname email
      }
    }
  `;
  try {
    const { BackOfficeTransporterUsers } = yield graphQLClient().request(query);
    yield put(
      TransporterDetailsActions.backOfficeTransporterUsersRequestSuccess(
        BackOfficeTransporterUsers
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterDetailsActions.backOfficeTransporterUsersRequestFailure
    );
  }
}

function* backOfficeTransporterDriversAsyncRequest({ companyId }) {
  const query = `
    {
      BackOfficeTransporterDrivers(companyId: "${companyId}") {
        _id firstname lastname phone
      }
    }
  `;

  try {
    const { BackOfficeTransporterDrivers } = yield graphQLClient().request(
      query
    );
    yield put(
      TransporterDetailsActions.backOfficeTransporterDriversRequestSuccess(
        BackOfficeTransporterDrivers
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterDetailsActions.backOfficeTransporterDriversRequestFailure
    );
  }
}

function* backOfficeTransporterVehiclesAsyncRequest({
  companyId,
  page,
  limit,
}) {
  const query = `
    {
      BackOfficeTransporterVehicles(companyId : "${companyId}", page: ${page}, limit: ${limit}) {
        page
        limit
        totalPages
        totalDocs
        vehicles {
          _id
          make
          model
          plate
          category
          options
          inService
          inUse
        }
      }
    }
  `;
  try {
    const { BackOfficeTransporterVehicles } = yield graphQLClient().request(
      query
    );
    yield put(
      TransporterDetailsActions.backOfficeTransporterVehiclesRequestSuccess(
        BackOfficeTransporterVehicles
      )
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterDetailsActions.backOfficeTransporterVehiclesRequestFailure
    );
  }
}

function* backOfficeTransporterModifyUserAsyncRequest({
  transporterUser,
  id,
  companyId,
  callback,
}) {
  const {
    firstname = "",
    lastname = "",
    email = "",
    password = "",
  } = transporterUser;
  const query = `
    mutation {
      BackOfficeModifyTransporterUser(
        userId: "${id}",
        user: { 
          firstname: "${firstname}",
          lastname: "${lastname}",
          email: "${email}",
          password: "${password}"
        }
      )
    }
  `;
  try {
    yield graphQLClient().request(query);

    yield put(
      TransporterDetailsActions.backOfficeModifyTransporterDriverRequestSuccess()
    );

    yield put(
      TransporterDetailsActions.backOfficeTransporterUsersRequest(companyId)
    );

    if (_.isFunction(callback)) callback();
  } catch (error) {
    // const { message = "" } = error.response.errors[0]
    yield errorHandler(
      error,
      TransporterDetailsActions.backOfficeModifyTranspoterDriverRequestFailure
    );
    if (_.isFunction(callback)) callback(error);
  }
}

function* backOfficeDeleteTransporterUserAsyncRequest({
  transporterUser,
  companyId,
  callback,
}) {
  const query = `
    mutation {
      BackOfficeDeleteTransporterUser(userId:"${transporterUser}")
    }
  `;
  try {
    yield graphQLClient().request(query);
    yield put(
      TransporterDetailsActions.backOfficeDeleteUserTransporterRequestSuccess()
    );
    yield put(
      TransporterDetailsActions.backOfficeTransporterUsersRequest(companyId)
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    // yield errorHandler(message,TransporterDetailsActions.backOfficeDeleteUserCompanyRequestFailure)
    if (_.isFunction(callback)) callback(error);
  }
}

function* backOfficeTransporterModifyDriverAsyncRequest({
  driverUser,
  id,
  driverId,
  callback,
}) {
  const {
    firstname = "",
    lastname = "",
    phone = "",
    password = "",
  } = driverUser;
  const query = `
    mutation {
      BackOfficeModifyTransporterDriver(
        userId: "${id}",
        user: {
          firstname: "${firstname}",
          lastname: "${lastname}",
          phone: "${phone}",
          password: "${password}"
        }
      )
    }
  `;
  try {
    yield graphQLClient().request(query);
    yield put(
      TransporterDetailsActions.backOfficeModifyTransporterDriverRequestSuccess()
    );
    yield put(
      TransporterDetailsActions.backOfficeTransporterDriversRequest(driverId)
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    // const { message = "" } = error.response.errors[0]
    yield errorHandler(
      error,
      TransporterDetailsActions.backOfficeModifyTranspoterDriverRequestFailure
    );
    if (_.isFunction(callback)) callback(error);
  }
}

function* backOfficeDeleteTransporterDriverAsyncRequest({
  driverId,
  companyId,
  callback,
}) {
  const query = `
    mutation {
      BackOfficeDeleteTransporterDriver(userId: "${driverId}")
    }
  `;
  try {
    yield graphQLClient().request(query);
    yield put(
      TransporterDetailsActions.backOfficeDeleteDriverTransporterRequestSuccess()
    );
    yield put(
      TransporterDetailsActions.backOfficeTransporterDriversRequest(driverId)
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    // yield errorHandler(message,TransporterDetailsActions.backOfficeDeleteUserCompanyRequestFailure)
    if (_.isFunction(callback)) callback(error);
  }
}

function* backOfficeModifyVehicleAsyncRequest({
  vehicle,
  id,
  companyId,
  callback,
}) {
  const { model = "", plate = "", make = "", category, options } = vehicle;
  const query = `
    mutation {
      BackOfficeTransporterModifyVehicle(
        vehicleId: "${id}",
        vehicle: {
          plate: "${plate}",
          model: " ${model}",
          make: "${make}",
          category: ${category},
          options: [${options}]
        }
      )
    }
  `;
  try {
    yield graphQLClient().request(query);
    yield put(
      TransporterDetailsActions.backOfficeModifyVehicleRequestSuccess()
    );
    yield put(
      TransporterDetailsActions.backOfficeTransporterVehiclesRequest(
        companyId,
        1,
        10
      )
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterDetailsActions.transporterModifyVehicleRequestFailure
    );
    if (_.isFunction(callback)) callback(error);
  }
}

function* backOfficeDeleteVehicleAsyncRequest({
  vehiculeId,
  companyId,
  callback,
}) {
  const query = `
    mutation {
      BackOfficeDeleteVehicleTransporter(vehicleId: "${vehiculeId}")
    }
  `;
  try {
    yield graphQLClient().request(query);
    yield put(
      TransporterDetailsActions.backOfficeDeleteVehicleRequestSuccess()
    );
    yield put(
      TransporterDetailsActions.backOfficeTransporterVehiclesRequest(
        companyId,
        1,
        10
      )
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterDetailsActions.backOfficeDeleteVehicleRequestFailure
    );
    if (_.isFunction(callback)) callback(error);
  }
}

function* backOfficeTransporterToggleActivationAsyncRequest({
  companyId,
  callback,
}) {
  const query = `
    mutation {
      BackOfficeTransporterToggleActivation(companyId: "${companyId}") {
        _id
        active
      }
    }
  `;
  try {
    const { BackOfficeTransporterToggleActivation } = yield graphQLClient().request(query);
    yield put(
      TransporterDetailsActions.backOfficeTransporterToggleActivationSuccess(BackOfficeTransporterToggleActivation.active)
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TransporterDetailsActions.backOfficeTransporterToggleActivationFailure
    );
  }
}


export default [
  takeLatest(
    TransporterDetailsType.BACK_OFFICE_GET_TRANSPORTER_DETAILS_REQUEST,
    backOfficeGetTransporterDetailsAsyncRequest
  ),
  takeLatest(
    TransporterDetailsType.BACK_OFFICE_TRANSPORTER_USERS_REQUEST,
    backOfficeTransporterUsersAsyncRequest
  ),
  takeLatest(
    TransporterDetailsType.BACK_OFFICE_TRANSPORTER_DRIVERS_REQUEST,
    backOfficeTransporterDriversAsyncRequest
  ),
  takeLatest(
    TransporterDetailsType.BACK_OFFICE_TRANSPORTER_VEHICLES_REQUEST,
    backOfficeTransporterVehiclesAsyncRequest
  ),
  takeLatest(
    TransporterDetailsType.BACK_OFFICE_MODIFY_TRANSPORTER_USER_REQUEST,
    backOfficeTransporterModifyUserAsyncRequest
  ),
  takeLatest(
    TransporterDetailsType.BACK_OFFICE_DELETE_USER_TRANSPORTER_REQUEST,
    backOfficeDeleteTransporterUserAsyncRequest
  ),
  takeLatest(
    TransporterDetailsType.BACK_OFFICE_MODIFY_TRANSPORTER_DRIVER_REQUEST,
    backOfficeTransporterModifyDriverAsyncRequest
  ),
  takeLatest(
    TransporterDetailsType.BACK_OFFICE_DELETE_DRIVER_TRANSPORTER_REQUEST,
    backOfficeDeleteTransporterDriverAsyncRequest
  ),
  takeLatest(
    TransporterDetailsType.BACK_OFFICE_MODIFY_VEHICLE_REQUEST,
    backOfficeModifyVehicleAsyncRequest
  ),
  takeLatest(
    TransporterDetailsType.BACK_OFFICE_DELETE_VEHICLE_REQUEST,
    backOfficeDeleteVehicleAsyncRequest
  ),
  takeLatest(
    TransporterDetailsType.BACK_OFFICE_TRANSPORTER_TOGGLE_ACTIVATION_REQUEST,
    backOfficeTransporterToggleActivationAsyncRequest
  ),
];

import React, { Component } from "react";
import { connect } from "react-redux";

import { Row, Col, Table } from "react-bootstrap";

import Card from "components/Card/Card";

import _ from "lodash";
import { withTranslation } from "react-i18next";

class InformationsTab extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  renderBusinessModel(details) {
    const { t, i18n } = this.props;

    const { amount, business_model } = details.billing;
    return (
      <Table responsive="sm">
        <thead>
          <tr>
            <th colSpan={2}>&nbsp; </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              {t("companyInfosPage.company_infos_bisness_model", {
                lng: i18n.language,
              })}
            </th>
            <td>{business_model === "flat" ? "FIXE" : "POURCENTAGE"}</td>
          </tr>
          <tr>
            <th>
              {t("companyInfosPage.company_infos_amount", {
                lng: i18n.language,
              })}
            </th>
            <td>{amount}</td>
          </tr>
        </tbody>
      </Table>
    );
  }

  renderDetails(details) {
    const { siret, name, email, web, active } = details;
    const { t, i18n } = this.props;

    return (
      <Table responsive="sm">
        <thead>
          <tr>
            <th colSpan={2}>
              {t("companyInfosPage.company_infos_details_title", {
                lng: i18n.language,
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              {t("companyInfosPage.company_infos_siret_name", {
                lng: i18n.language,
              })}
            </th>
            <td>{siret}</td>
          </tr>
          <tr>
            <th>
              {t("companyInfosPage.company_infos_company_name", {
                lng: i18n.language,
              })}
            </th>
            <td>{name}</td>
          </tr>
          <tr>
            <th>
              {t("companyInfosPage.company_infos_email", {
                lng: i18n.language,
              })}
            </th>
            <td>{email}</td>
          </tr>
          <tr>
            <th>
              {t("companyInfosPage.company_infos_web_site", {
                lng: i18n.language,
              })}
            </th>
            <td>{web}</td>
          </tr>
          <tr>
            <th>
              {t("companyInfosPage.company_infos_active", {
                lng: i18n.language,
              })}
            </th>
            <td>{active ? "OUI" : "NON"}</td>
          </tr>
        </tbody>
      </Table>
    );
  }

  render() {
    const { details } = this.props;

    return (
      <Card
        title=""
        category=""
        content={
          <div>
            <Row>
              <Col md="6">{this.renderDetails(details)}</Col>
              <Col md="6">{this.renderBusinessModel(details)}</Col>
            </Row>
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const CompanyInfosRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationsTab);
export default withTranslation(["Private", "Common"], { wait: true })(
  CompanyInfosRedux
);

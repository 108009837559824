import React, { Component } from "react";
import { connect } from "react-redux";
import GetServiceDetailsActions from "reducers/company/company-details";
import TransportersActions from "reducers/transporter/get-transporters";

import { Grid, Row, Col, Alert, Tab, Nav, NavItem } from "react-bootstrap";
import Loader from "react-loader-spinner";
import _ from "lodash";

import InformationsTab from "./service-details-infos";
// import PatiensTab from "./PatiensTab"
// import HistoriqueTab from "./HistoriqueTab"
import UsersTab from "./service-users";
import FavorisTab from "./service-favoris";
import { withTranslation } from "react-i18next";

class Hopitals extends Component {
  constructor(props) {
    super(props);
  }

  renderService() {
    const { t, i18n } = this.props;

    const serviceId = this.props.match.params.id;
    const { company = { details: "" }, service, transporters } = this.props;
    const { details, users } = service;
    const { name = "" } = details;

    return (
      <Row>
        <Col md={12}>
          <h1 className="title text-center">{name}</h1>
          <Tab.Container id="nav-with-icons" defaultActiveKey="informations">
            <div>
              <div className="nav-container">
                <Nav bsStyle="tabs" bsClass="nav nav-icons">
                  <NavItem eventKey="informations">
                    <i className="fa fa-info-circle" />
                    <br />{" "}
                    {t(
                      "companyServiceDetailsPage.company_service_details_info_nav_title",
                      { lng: i18n.language }
                    )}
                  </NavItem>
                  <NavItem eventKey="users">
                    <i className="fa fa-users" />
                    <br />{" "}
                    {t(
                      "companyServiceDetailsPage.company_service_details_users_nav_title",
                      { lng: i18n.language }
                    )}
                  </NavItem>
                  <NavItem eventKey="favoris">
                    <i className="fa fa-car" />
                    <br /> Favoris
                  </NavItem>

                  {/* <NavItem eventKey="patiens">
                                        <i className="fa fa-wheelchair" />
                                        <br /> Patients
                                    </NavItem>

                                    <NavItem eventKey="historique">
                                        <i className="fa fa-history" />
                                        <br /> Historique
                                    </NavItem> */}
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="informations">
                  <InformationsTab company={company} {...{ details }} />
                </Tab.Pane>
                <Tab.Pane eventKey="users">
                  <UsersTab
                    {...{ serviceId, companyId: company.details._id }}
                    {...{ admins: users }}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="favoris">
                  <FavorisTab
                    {...{
                      service: details,
                      companyId: company.details._id,
                      transporters: transporters.companies,
                    }}
                  />
                </Tab.Pane>
                {/* <Tab.Pane eventKey="patiens">
                                    <PatiensTab />
                                </Tab.Pane>

                                <Tab.Pane eventKey="historique">
                                    <HistoriqueTab />
                                </Tab.Pane> */}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Col>
      </Row>
    );
  }

  componentDidMount() {
    const {
      match,
      company,
      backOfficeGetServiceDetailsRequest,
      backOfficeGetServiceUsersRequest,
    } = this.props;

    const { details = { _id: "" } } = company;
    const serviceId = match.params.id;

    backOfficeGetServiceDetailsRequest(details._id, serviceId);
    backOfficeGetServiceUsersRequest(details._id, serviceId);

    const { backOfficeTransportersListRequest, transporters } = this.props;
    // const { page, limit } = transporters

    backOfficeTransportersListRequest(1, 100);
  }

  render() {
    const { action, service } = this.props;
    const { error, loading } = action;

    return (
      <div className="main-content">
        <Grid fluid>
          <Row className="text-center">
            <Col md={6} sm={6} mdOffset={3} smOffset={3}>
              {error !== "" ? (
                <Alert bsStyle="danger">
                  <span>{error}</span>
                </Alert>
              ) : null}
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={100}
                width={100}
                visible={loading}
              />
            </Col>
          </Row>

          {_.isNull(service.details) ? null : this.renderService()}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transporters: state.backOfficeTransporters.list.asMutable({ deep: true }),
    company: state.backOfficeGetDetailsCompany.company.asMutable({
      deep: true,
    }),
    service: state.backOfficeGetDetailsCompany.service.asMutable({
      deep: true,
    }),
    action: state.backOfficeGetDetailsCompany.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeGetServiceDetailsRequest: (...args) =>
      dispatch(
        GetServiceDetailsActions.backOfficeGetServiceDetailsRequest(...args)
      ),
    backOfficeGetServiceUsersRequest: (...args) =>
      dispatch(
        GetServiceDetailsActions.backOfficeGetServiceUsersRequest(...args)
      ),
    backOfficeTransportersListRequest: (...args) =>
      dispatch(TransportersActions.backOfficeTransportersListRequest(...args)),
  };
};

const CompanyServiceIndexRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(Hopitals);
export default withTranslation(["Private", "Common"], { wait: true })(
  CompanyServiceIndexRedux
);

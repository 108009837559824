import { put, takeLatest } from "redux-saga/effects";
import AddCompanyAdminActions, {
  AddCompanyAdminTypes,
} from "reducers/company/add-company-admin";
import errorHandler from "sagas/error-handler";
import companyDetailsActions from "reducers/company/company-details";

import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* backOfficeCreateCompanyAdminAsyncRequest({
  admin,
  companyId,
  callback,
}) {
  const {
    firstname = "",
    lastname = "",
    avatar = "",
    email = "",
    password = "",
  } = admin;
  const query = `
    mutation {
        BackOfficeCreateCompanyUser(user:{ 
            firstname:"${firstname}",
                lastname:"${lastname}",
              avatar:"${avatar}",
              email:"${email}",
              password:"${password}"
            
            },
  companyId:"${companyId}")
           
        {
          _id
          firstname
          lastname
          avatar
          email
          active
          lastLoginAt
          createdAt
          updatedAt
        }
      }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const { BackOfficeCreateCompanyUser } = data;
    yield put(
      AddCompanyAdminActions.backOfficeCreateCompanyAdminRequestSuccess(
        BackOfficeCreateCompanyUser
      )
    );
    yield put(companyDetailsActions.backOfficeCompanyUsersRequest(companyId));

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      AddCompanyAdminActions.backOfficeCreateCompanyAdminRequestFailure
    );

    if (_.isFunction(callback)) callback(message);
  }
}
export default [
  takeLatest(
    AddCompanyAdminTypes.BACK_OFFICE_CREATE_COMPANY_ADMIN_REQUEST,
    backOfficeCreateCompanyAdminAsyncRequest
  ),
];

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeCreateServiceAdminRequest: [
    "admin",
    "companyId",
    "serviceId",
    "callback",
  ],
  backOfficeCreateServiceAdminRequestSuccess: ["admin"],
  backOfficeCreateServiceAdminRequestFailure: ["error"],

  backOfficeClearError: null,
});

export const AddServiceAdminTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  company: {
    users: [],
  },
  service: {
    details: null,
    users: [],
  },

  action: {
    loading: false,
    error: "",
  },
});

export const backOfficeClearError = (state, action) => {
  return Immutable.setIn(state, ["action", "error"], "");
};

export const backOfficeCreateServiceAdminRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeCreateServiceAdminRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["service", "users"],
    [...state.company.users, action.admin]
  );
};

export const backOfficeCreateServiceAdminRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_CREATE_SERVICE_ADMIN_REQUEST]:
    backOfficeCreateServiceAdminRequest,
  [Types.BACK_OFFICE_CREATE_SERVICE_ADMIN_REQUEST_SUCCESS]:
    backOfficeCreateServiceAdminRequestSuccess,
  [Types.BACK_OFFICE_CREATE_SERVICE_ADMIN_REQUEST_FAILURE]:
    backOfficeCreateServiceAdminRequestFailure,
  [Types.BACK_OFFICE_CLEAR_ERROR]: backOfficeClearError,
});

import { put, takeLatest } from "redux-saga/effects";
import AddSamuAdminActions, {
  AddSamuAdminTypes,
} from "reducers/samu/add-samu-admin";
import SamuDetailsActions from "reducers/samu/samu-details";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* backOfficeCreateSamuAdminAsyncRequest({
  admin,
  companyId,
  callback,
}) {
  const {
    firstname = "",
    lastname = "",
    avatar = "",
    email = "",
    password = "",
  } = admin;
  const query = `
    mutation {
      BackOfficeCreateSamuUser (
        user:{
          firstname:"${firstname}",
          lastname:"${lastname}",
          avatar:"${avatar}",
          email:"${email}",
          password:"${password}"
        },
        companyId:"${companyId}"
      )
      {
        _id
        firstname
        lastname
        avatar
        email
        active
        lastLoginAt
        createdAt
        updatedAt
      }
    }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const { BackOfficeCreateSamuUser } = data;
    yield put(AddSamuAdminActions.backOfficeCreateSamuAdminRequestSuccess());
    yield put(
      SamuDetailsActions.backOfficeSamuUseCreated(BackOfficeCreateSamuUser)
    );

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      AddSamuAdminActions.backOfficeCreateSamuAdminRequestFailure
    );
  }
}
export default [
  takeLatest(
    AddSamuAdminTypes.BACK_OFFICE_CREATE_SAMU_ADMIN_REQUEST,
    backOfficeCreateSamuAdminAsyncRequest
  ),
];

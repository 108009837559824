import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { Grid, Row, Col, Alert, Tab, Nav, NavItem } from "react-bootstrap";

import TransporterDetailsActions from "reducers/transporter/transporter-details";
import InformationsTab from "./transporter-details";
// import PatiensTab from "./company-patient"
// import FacturesTab from "./company-facture"
import VehiclesTab from "./transporter-vehicles";
import UsersTab from "./transporter-users";
// import ServicesTab from "./company-service-infos"

class Transporters extends Component {
  constructor(props) {
    super(props);
  }

  renderHospital() {
    const companyId = this.props.match.params.id;
    const { details, users, vehicles } = this.props.company;
    const { name = "" } = details;
    const { t, i18n } = this.props;

    return (
      <Row>
        <Col md={12}>
          <h1 className="title text-center">{name}</h1>
          <Tab.Container id="nav-with-icons" defaultActiveKey="informations">
            <div>
              <div className="nav-container">
                <Nav bsStyle="tabs" bsClass="nav nav-icons">
                  <NavItem eventKey="informations">
                    <i className="fa fa-info-circle" />
                    <br />{" "}
                    {t(
                      "transporterDetailsPage.transporter_details_info_nav_title",
                      { lng: i18n.language }
                    )}
                  </NavItem>
                  <NavItem eventKey="users">
                    <i className="fa fa-users" />
                    <br />{" "}
                    {t(
                      "transporterDetailsPage.transporter_details_users_nav_title",
                      { lng: i18n.language }
                    )}
                  </NavItem>
                  <NavItem eventKey="vehicles">
                    <i className="fa fa-ambulance" />
                    <br />{" "}
                    {t(
                      "transporterDetailsPage.transporter_details_vehicules_nav_title",
                      { lng: i18n.language }
                    )}
                  </NavItem>

                  {/*    <NavItem eventKey="patiens">
                                        <i className="fa fa-wheelchair" />
                                        <br /> Patients
                                    </NavItem>
                                    <NavItem eventKey="factures">
                                        <i className="fa fa-file" />
                                        <br /> Factures
                                    </NavItem>
                                    <NavItem eventKey="historique">
                                        <i className="fa fa-history" />
                                        <br /> Historique
                                    </NavItem> */}
                </Nav>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="informations">
                  <InformationsTab {...{ details }} />
                </Tab.Pane>
                <Tab.Pane eventKey="users">
                  <UsersTab {...{ companyId }} {...users} />
                </Tab.Pane>
                <Tab.Pane eventKey="vehicles">
                  <VehiclesTab {...{ companyId, vehicles }} />
                </Tab.Pane>
                {/*    
									<Tab.Pane eventKey="patiens">
											<PatiensTab />
									</Tab.Pane>
									<Tab.Pane eventKey="factures">
											<FacturesTab />
									</Tab.Pane>
									<Tab.Pane eventKey="historique">
											<HistoriqueTab />
									</Tab.Pane>
								*/}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Col>
      </Row>
    );
  }

  componentDidMount() {
    const {
      match,
      backOfficeGetTransporterDetailsRequest,
      backOfficeTransporterUsersRequest,
      backOfficeTransporterDriversRequest,
    } = this.props;
    const companyId = match.params.id;

    backOfficeGetTransporterDetailsRequest(companyId);
    backOfficeTransporterUsersRequest(companyId);
    backOfficeTransporterDriversRequest(companyId);
  }

  render() {
    const { action, company } = this.props;
    const { error, loading } = action;
    return (
      <div className="main-content">
        <Grid fluid>
          <Row className="text-center">
            <Col md={6} sm={6} mdOffset={3} smOffset={3}>
              {error !== "" ? (
                <Alert bsStyle="danger">
                  <span>{error}</span>
                </Alert>
              ) : null}
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={100}
                width={100}
                visible={loading}
              />
            </Col>
          </Row>

          {_.isNull(company.details) ? null : this.renderHospital()}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.backOfficeTransporterDetails.company.asMutable({
      deep: true,
    }),
    action: state.backOfficeTransporterDetails.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeGetTransporterDetailsRequest: (...args) =>
      dispatch(
        TransporterDetailsActions.backOfficeGetTransporterDetailsRequest(
          ...args
        )
      ),
    backOfficeTransporterUsersRequest: (...args) =>
      dispatch(
        TransporterDetailsActions.backOfficeTransporterUsersRequest(...args)
      ),
    backOfficeTransporterDriversRequest: (...args) =>
      dispatch(
        TransporterDetailsActions.backOfficeTransporterDriversRequest(...args)
      ),
  };
};

const transporterDetailsRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(Transporters);
export default withTranslation(["Private", "Common"], { wait: true })(
  transporterDetailsRedux
);

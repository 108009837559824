import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  taxiGetListResetState: null,
  taxiGetListRequest: ["page", "limit"],
  taxiGetListRequestSuccess: ["taxis"],
  taxiGetListRequestFailure: ["error"],

  taxiCreateNewRequest: ["user", "callback"],
  taxiCreateNewRequestSuccess: null,
  taxiCreateNewRequestFailure: ["error"],

  taxiModifyTaxiUserRequest: ["taxiUser", "id", "callback"],
  taxiModifyTaxiUserRequestSuccess: null,
  taxiModifyTaxiUserRequestFailure: ["error"],

  taxiDeleteTaxiUserRequest: ["taxiUserId", "callback"],
  taxiDeleteTaxiUserRequestSuccess: null,
  taxiDeleteTaxiUserRequestFailure: ["error"],
});

export const TaxisTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  list: {
    page: 1,
    limit: 10,
    vehiclesDrivers: [],
  },

  action: {
    loading: false,
    error: "",
  },
});

/// GENERAL
export const taxiGetListResetState = (state, action) => {
  return INITIAL_STATE;
};

export const taxiGetListRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const taxiGetListRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const taxiGetListRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["list"], action.taxis);
};

export const taxiCreateNewRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const taxiCreateNewRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const taxiCreateNewRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const taxiModifyTaxiUserRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const taxiModifyTaxiUserRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const taxiModifyTaxiUserRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const taxiDeleteTaxiUserRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const taxiDeleteTaxiUserRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const taxiDeleteTaxiUserRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TAXI_GET_LIST_RESET_STATE]: taxiGetListResetState,
  [Types.TAXI_GET_LIST_REQUEST]: taxiGetListRequest,
  [Types.TAXI_GET_LIST_REQUEST_SUCCESS]: taxiGetListRequestSuccess,
  [Types.TAXI_GET_LIST_REQUEST_FAILURE]: taxiGetListRequestFailure,

  [Types.TAXI_CREATE_NEW_REQUEST]: taxiCreateNewRequest,
  [Types.TAXI_CREATE_NEW_REQUEST_SUCCESS]: taxiCreateNewRequestSuccess,
  [Types.TAXI_CREATE_NEW_REQUEST_FAILURE]: taxiCreateNewRequestFailure,

  [Types.TAXI_MODIFY_TAXI_USER_REQUEST]: taxiModifyTaxiUserRequest,
  [Types.TAXI_MODIFY_TAXI_USER_REQUEST_SUCCESS]:
    taxiModifyTaxiUserRequestSuccess,
  [Types.TAXI_MODIFY_TAXI_USER_REQUEST_FAILURE]:
    taxiModifyTaxiUserRequestFailure,

  [Types.TAXI_DELETE_TAXI_USER_REQUEST]: taxiDeleteTaxiUserRequest,
  [Types.TAXI_DELETE_TAXI_USER_REQUEST_SUCCESS]:
    taxiDeleteTaxiUserRequestSuccess,
  [Types.TAXI_DELETE_TAXI_USER_REQUEST_FAILURE]:
    taxiDeleteTaxiUserRequestFailure,
});

import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from "react-notification-system";

import Sidebar from "views/private/Sidebar.jsx";
import AdminNavbar from "views/private/AdminNavbar.jsx";
/*import Footer from "components/Footer/Footer.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
*/
import image from "assets/img/full-screen-image-3.jpg";
// dinamically create dashboard routes
import routes from "routes";

// style for notifications
import { style } from "variables/Variables.jsx";
import "noty/lib/noty.css";
import "noty/lib/themes/bootstrap-v4.css";

var ps;

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _notificationSystem: null,
      image: image,
      color: "black",
      hasImage: true,
      navbar: false,
      mini: false,
      fixedClasses: "dropdown show-dropdown open",
    };
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick = (position) => {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15,
    });
  };
  handleImageClick = (image) => {
    this.setState({ image: image });
  };
  handleColorClick = (color) => {
    this.setState({ color: color });
  };
  handleHasImage = (hasImage) => {
    this.setState({ hasImage: hasImage });
  };
  handleNavbarClick = (navbar) => {
    this.setState({ navbar: navbar });
  };
  handleMiniClick = () => {
    this.setState({ mini: !this.state.mini });
    document.body.classList.toggle("sidebar-mini");
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/private") {
        if (prop.redirect)
          return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
        return (
          <Route
            path={prop.path}
            key={key}
            render={(routeProps) => (
              <prop.component
                {...routeProps}
                handleClick={this.handleNotificationClick}
              />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />

        <Sidebar
          {...this.props}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
          mini={this.state.mini}
        />

        <div
          className={
            "main-panel" +
            (this.props.location.pathname === "/maps/full-screen-maps"
              ? " main-panel-maps"
              : "")
          }
          ref="mainPanel"
        >
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            navbar={this.state.navbar}
          />

          <Switch>{this.getRoutes(routes)}</Switch>

          {/*
                    }
                    
                    <Footer fluid />
                    
                    <FixedPlugin
                      handleImageClick={this.handleImageClick}
                      handleColorClick={this.handleColorClick}
                      handleHasImage={this.handleHasImage}
                      handleNavbarClick={this.handleNavbarClick}
                      handleMiniClick={this.handleMiniClick}
                      bgColor={this.state["color"]}
                      bgImage={this.state["image"]}
                      mini={this.state["mini"]}
                      handleFixedClick={this.handleFixedClick}
                      fixedClasses={this.state.fixedClasses}
                    />
          
                    {
                        */}
        </div>
      </div>
    );
  }
}

// class SubscriptionContainer extends Component {
//     constructor(props) {

//         super(props)
//         this.showNotification = this.showNotification.bind(this)
//         this.onGoingRideStatusChanged = this.onGoingRideStatusChanged.bind(this)
//     }

//     showNotification({ patient, dropoff, status, ...rest }) {

//         const { firstname = "", lastname = "" } = patient
//         const { address = "" } = dropoff

//         if (status === "completed") return

//         let color = ""
//         switch (status) {

//             case "waiting":
//                 color = "warning"
//                 break;
//             case "onboard":
//                 color = "success"
//                 break;
//             case "completed":
//                 color = "danger"
//                 break;
//             case "onway":
//                 color = "info"
//                 break;
//             default:
//                 color = "info"
//                 break;
//         }

//         new Noty({
//             theme: 'bootstrap-v4',
//             type: color,
//             layout: 'topRight',
//             sounds: {
//                 sources: [bip],
//                 volume: 1, conditions: ['docVisible', 'docHidden']
//             },
//             timeout: 5000,
//             text: `Patient: ${firstname} ${lastname} <br/>Destination: ${address} <br/>Status: ${status}`

//         }).show();
//     }

//     render() {

//         return (<Subscription subscription={_SUBSCRIPTION} onSubscriptionData={({ subscriptionData }) => {

//         }}>
//             {({ data = {}, loading, error }) => {
//                 return <Dashboard {...this.props} />
//             }
//             }

//         </Subscription>)
//     }
// }

import { put, takeLatest } from "redux-saga/effects";
import AddDriverActions, {
  TransporterDetailsType,
} from "reducers/transporter/transporter-details";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* backOfficeCreateDriverAsyncRequest({ driver, companyId, callback }) {
  const {
    firstname = "",
    lastname = "",
    avatar = "",
    phone = "",
    password = "",
  } = driver;
  const query = `
    mutation {
      BackOfficeCreateTransporterDriver(
        DriverUser: { 
          firstname:"${firstname}",
          lastname:"${lastname}",
          avatar:"${avatar}",
          phone:"${phone}",
          password:"${password}"
        },
        companyId:"${companyId}"
      ) {
        _id
        firstname
        lastname
        avatar
        phone
        active
        lastLoginAt
        createdAt
        updatedAt
      }
    }
  `;

  try {
    const { BackOfficeCreateTransporterDriver } = yield graphQLClient().request(
      query
    );
    yield put(
      AddDriverActions.backOfficeCreateDriverRequestSuccess(
        BackOfficeCreateTransporterDriver
      )
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      AddDriverActions.backOfficeCreateDriverRequestFailure
    );
  }
}

export default [
  takeLatest(
    TransporterDetailsType.BACK_OFFICE_CREATE_DRIVER_REQUEST,
    backOfficeCreateDriverAsyncRequest
  ),
];

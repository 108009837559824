import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import SamuSectorsActions, {
  SamuSectorsTypes,
} from "reducers/samu/samu-sectors";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

const sectorQuery = `_id sectorName cities samu { id name } updatedAt createdAt`;

function* sectorsAsyncRequest({ companyId, callback }) {
  const query = `
    {
      BackOfficeSamuSectors(companyId: "${companyId}") {
        ${sectorQuery}
      }
    }
  `;

  try {
    const { BackOfficeSamuSectors } = yield graphQLClient().request(query);
    yield put(SamuSectorsActions.sectorsSuccess(BackOfficeSamuSectors));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, SamuSectorsActions.sectorsFailure);
  }
}

function* createAsyncRequest({ companyId, sector, callback }) {
  const mutation = `
    mutation {
      BackOfficeCreateSamuSector(
        companyId: "${companyId}",
        sector: {
          sectorName: "${sector.sectorName}",
          cities: [${sector.cities.map((city) => `"${city}"`)}]
        }
      ) {
        ${sectorQuery}
      }
    }
  `;
  try {
    const { BackOfficeCreateSamuSector } = yield graphQLClient().request(
      mutation
    );
    yield put(SamuSectorsActions.createSuccess(BackOfficeCreateSamuSector));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, SamuSectorsActions.createFailure);
  }
}

function* updateAsyncRequest({ companyId: sectorId, sector, callback }) {
  const mutation = `
    mutation {
      BackOfficeUpdateSamuSector(
        sectorId: "${sectorId}",
        sector: {
          sectorName: "${sector.sectorName}",
          cities: [${sector.cities.map((city) => `"${city}"`)}]
        }
      ) {
        ${sectorQuery}
      }
    }
  `;
  try {
    const { BackOfficeUpdateSamuSector } = yield graphQLClient().request(
      mutation
    );
    yield put(SamuSectorsActions.updateSuccess(BackOfficeUpdateSamuSector));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, SamuSectorsActions.updateFailure);
  }
}

function* addCityAsyncRequest({ sectorId, cityName, callback }) {
  const mutation = `
    mutation {
      BackOfficeAddSamuSectorCity(
        sectorId: "${sectorId}",
        cityName: "${cityName}"
      ) {
        ${sectorQuery}
      }
    }
  `;
  try {
    const { BackOfficeAddSamuSectorCity } = yield graphQLClient().request(
      mutation
    );
    yield put(SamuSectorsActions.addCitySuccess(BackOfficeAddSamuSectorCity));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, SamuSectorsActions.addCityFailure);
  }
}

function* removeCityAsyncRequest({ sectorId, cityName, callback }) {
  const mutation = `
    mutation {
      BackOfficeRemoveSamuSectorCity(
        sectorId: "${sectorId}",
        cityName: "${cityName}"
      ) {
        ${sectorQuery}
      }
    }
  `;
  try {
    const { BackOfficeRemoveSamuSectorCity } = yield graphQLClient().request(
      mutation
    );
    yield put(
      SamuSectorsActions.removeCitySuccess(BackOfficeRemoveSamuSectorCity)
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, SamuSectorsActions.removeCityFailure);
  }
}

function* modifyNameAsyncRequest({ sectorId, sectorName, callback }) {
  const mutation = `
    mutation {
      BackOfficeModifySamuSectorName(
        sectorId: "${sectorId}",
        sectorName: "${sectorName}"
      ) {
        ${sectorQuery}
      }
    }
  `;
  try {
    const { BackOfficeModifySamuSectorName } = yield graphQLClient().request(
      mutation
    );
    yield put(
      SamuSectorsActions.modifyNameSuccess(BackOfficeModifySamuSectorName)
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, SamuSectorsActions.modifyNameFailure);
  }
}

export default [
  takeLatest(SamuSectorsTypes.SECTORS_REQUEST, sectorsAsyncRequest),
  takeLatest(SamuSectorsTypes.CREATE_REQUEST, createAsyncRequest),
  takeLatest(SamuSectorsTypes.UPDATE_REQUEST, updateAsyncRequest),
  takeLatest(SamuSectorsTypes.ADD_CITY_REQUEST, addCityAsyncRequest),
  takeLatest(SamuSectorsTypes.REMOVE_CITY_REQUEST, removeCityAsyncRequest),
  takeLatest(SamuSectorsTypes.MODIFY_NAME_REQUEST, modifyNameAsyncRequest),
];

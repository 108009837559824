import { put, takeLatest } from "redux-saga/effects";
import TaxisActions, { TaxisTypes } from "reducers/taxi/taxi-getTaxis";
import errorHandler from "sagas/error-handler";

import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* TaxiListAsyncRequest({ page, limit }) {
  const query = `
      {
        TaxiUserAndVehicleInUse(page:${page},limit:${limit})
  {
    page
    limit
    totalPages
    totalDocs  
    vehiclesDrivers {_id firstname  lastname email status active vehicle{id    plate model make}}}
     }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const taxis = data.TaxiUserAndVehicleInUse;
    yield put(TaxisActions.taxiGetListRequestSuccess(taxis));
  } catch (error) {
    const { message = "" } = error.response.errors[0];

    yield errorHandler(message, TaxisActions.taxiGetListRequestFailure);
  }
}

function* TaxiCreateNewAsyncRequest({ user, callback }) {
  const {
    firstname = "",
    lastname = "",
    avatar = "",
    email = "",
    password = "",
    phone = "",
    sector = "",
  } = user;
  const query = `
    mutation { 
      BackOfficeCreateTaxiUser( 
        user:{
          firstname:"${firstname}",
          lastname:"${lastname}",
          avatar :"${avatar}",
          email:"${email}",
          password:"${password}",
          phone:"${phone}",
          sector:"${sector}"
        }
      ) { _id firstname lastname email status  }
    }
  `;

  try {
    yield graphQLClient().request(query);

    yield put(TaxisActions.taxiCreateNewRequestSuccess());
    yield put(TaxisActions.taxiGetListRequest(1, 10));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, TaxisActions.taxiCreateNewRequestFailure);
  }
}

function* TaxiModifyUserAsyncRequest({ taxiUser, id, callback }) {
  const { firstname = "", lastname = "", email = "", password = "" } = taxiUser;
  const query = `
      mutation {
        BackOfficeModifyTaxiUser(user:{ 
            firstname:"${firstname}",
            lastname:"${lastname}",
            email:"${email}",
            password:"${password}"
          },
          userId:"${id}"
  )
   
  
          
          
          }
    `;
  try {
    yield graphQLClient().request(query);
    yield put(TaxisActions.taxiModifyTaxiUserRequestSuccess());
    yield put(TaxisActions.taxiGetListRequest(1, 10));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, TaxisActions.taxiModifyTaxiUserRequestFailure);
    if (_.isFunction(callback)) callback(error);
  }
}

function* TaxiDeleteUserAsyncRequest({ taxiUserId, callback }) {
  const query = `
    mutation {
  
        BackOfficeDeleteTaxiUser(userId:"${taxiUserId}")
      
      }
    `;
  try {
    yield graphQLClient().request(query);

    yield put(TaxisActions.taxiDeleteTaxiUserRequestSuccess());
    yield put(TaxisActions.taxiGetListRequest(1, 10));

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      TaxisActions.taxiDeleteTaxiUserRequestSuccessFailure
    );

    if (_.isFunction(callback)) callback(error);
  }
}

export default [
  takeLatest(TaxisTypes.TAXI_GET_LIST_REQUEST, TaxiListAsyncRequest),
  takeLatest(TaxisTypes.TAXI_CREATE_NEW_REQUEST, TaxiCreateNewAsyncRequest),
  takeLatest(
    TaxisTypes.TAXI_MODIFY_TAXI_USER_REQUEST,
    TaxiModifyUserAsyncRequest
  ),

  takeLatest(
    TaxisTypes.TAXI_DELETE_TAXI_USER_REQUEST,
    TaxiDeleteUserAsyncRequest
  ),
];

import { GraphQLClient } from "graphql-request";
import { Store } from "../store";

const state = Store.getState();
const account = state.account.asMutable({ deep: true });
const { token } = account;

let BACK_END = "";

if (process.env.REACT_APP_STAGE === "dev")
  BACK_END =
    process.env.REACT_APP_BACK_END_URL_DEV +
    process.env.REACT_APP_BACK_END_API_PATH;
else if (process.env.REACT_APP_STAGE === "prod")
  BACK_END =
    process.env.REACT_APP_BACK_END_URL_PROD +
    process.env.REACT_APP_BACK_END_API_PATH;
else if (process.env.REACT_APP_STAGE === "demo")
  BACK_END =
    process.env.REACT_APP_BACK_END_URL_DEMO +
    process.env.REACT_APP_BACK_END_API_PATH;
else
  BACK_END =
    process.env.REACT_APP_BACK_END_URL +
    process.env.REACT_APP_BACK_END_API_PATH;

const graphQLClient = new GraphQLClient(BACK_END, {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    dataType: "json",
    authorization: `Bearer ${token}`,
  },
});

export { graphQLClient };

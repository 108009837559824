import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Alert,
} from "react-bootstrap";

import Card from "components/Card/Card";
import LoadingOverlay from "react-loading-overlay";
import Button from "components/CustomButton/CustomButton";
import Checkbox from "components/CustomCheckbox/CustomCheckbox";
import { withTranslation } from "react-i18next";

import logo from "assets/img/logo.png";
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      email: "", //process.env.NODE_ENV === "development" ? "james@yahoo.com" : "",
      password: "", // process.env.NODE_ENV === "development" ? "test123" : "",
    };

    this.onPressLoginButton = this.onPressLoginButton.bind(this);
    this.onPropertyChanged = this.onPropertyChanged.bind(this);
  }

  onPropertyChanged = (properiety) => {
    this.setState(Object.assign({}, this.state, { ...properiety }));
  };

  componentDidMount() {
    setTimeout(
      function () {
        this.setState(Object.assign({}, this.state, { cardHidden: false }));
      }.bind(this),
      700
    );
  }
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const { signinRequest } = this.props;
      const { email, password } = this.state;
      signinRequest(email, password);
    }
  };
  onPressLoginButton() {
    const { signinRequest } = this.props;
    const { email, password } = this.state;
    signinRequest(email, password);
  }
  render() {
    const { t, i18n, account } = this.props;
    const { action } = account;
    const { error = "", loading = false } = action;

    const { email, password } = this.state;

    return (
      <LoadingOverlay active={loading} spinner text="Loading...">
        <Grid>
          {error !== "" && !loading ? (
            <Row>
              <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                <Alert bsStyle="danger">
                  <span>{error}</span>
                </Alert>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md={4} sm={6} mdOffset={4} smOffset={3}>
              <form>
                <Card
                  hidden={this.state.cardHidden}
                  textCenter
                  title={<img src={logo} />}
                  category=""
                  content={
                    <div>
                      <FormGroup>
                        <ControlLabel>
                          {t("LoginPage.email_label", { lng: i18n.language })}
                        </ControlLabel>
                        <FormControl
                          placeholder={t("LoginPage.email_input_placeholder", {
                            lng: i18n.language,
                          })}
                          type="email"
                          disabled={loading}
                          onChange={(e) =>
                            this.onPropertyChanged({ email: e.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                          value={email}
                        />
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>
                          {t("LoginPage.password_label", {
                            lng: i18n.language,
                          })}
                        </ControlLabel>
                        <FormControl
                          placeholder={t(
                            "LoginPage.password_input_placeholder",
                            { lng: i18n.language }
                          )}
                          type="password"
                          disabled={loading}
                          autoComplete="off"
                          onChange={(e) =>
                            this.onPropertyChanged({ password: e.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                          value={password}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Checkbox
                          number="1"
                          label={t("LoginPage.Keep_signedin_checkbox_label", {
                            lng: i18n.language,
                          })}
                        />
                      </FormGroup>
                    </div>
                  }
                  legend={
                    <div>
                      <Button
                        bsStyle="info"
                        fill
                        wd
                        onClick={this.onPressLoginButton}
                      >
                        {t("LoginPage.login_button", { lng: i18n.language })}
                      </Button>
                      <Button bsStyle="default" bsSize="sm" simple wd>
                        {t("LoginPage.forgot_password_button", {
                          lng: i18n.language,
                        })}
                      </Button>
                    </div>
                  }
                />
              </form>
            </Col>
          </Row>
        </Grid>
      </LoadingOverlay>
    );
  }
}

export default withTranslation(["Public", "Common"], { wait: true })(LoginPage);

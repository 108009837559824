import { put, takeLatest } from "redux-saga/effects";
import AccountActions, { AccountTypes } from "reducers/account";

import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* signinAsyncRequest({ email, password }) {
  const query = `
    query { 
        BackOfficeUserLogin( 
      email:"${email}",
        password:"${password}"
      )
      }
    `;

  try {
    const data = yield graphQLClient().request(query);
    const token = data.BackOfficeUserLogin;
    yield put(AccountActions.signinRequestSuccess(token));
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(AccountActions.signinRequestFailure(errors[0].message));
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [takeLatest(AccountTypes.SIGNIN_REQUEST, signinAsyncRequest)];

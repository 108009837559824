import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeCreateCompanyRequest: [
    "siret",
    "name",
    "address",
    "billing_address",
    "email",
    "phones",
    "web",
    "contact",
    "billing",
    "rib",
    "callback",
  ],
  backOfficeCreateCompanyRequestFailure: ["error"],
  backOfficeCreateCompanyRequestSuccess: null,
});

export const AddCompaniesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  action: {
    loading: false,
    error: "",
  },
});

export const backOfficeCreateCompanyRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeCreateCompanyRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeCreateCompanyRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_CREATE_COMPANY_REQUEST]: backOfficeCreateCompanyRequest,
  [Types.BACK_OFFICE_CREATE_COMPANY_REQUEST_FAILURE]:
    backOfficeCreateCompanyRequestFailure,
  [Types.BACK_OFFICE_CREATE_COMPANY_REQUEST_SUCCESS]:
    backOfficeCreateCompanyRequestSuccess,
});

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  backOfficeGetSamuDetailsResetState: null,
  backOfficeGetSamuDetailsRequest: ["companyId"],
  backOfficeGetSamuDetailsRequestSuccess: ["details"],
  backOfficeGetSamuDetailsRequestFailure: ["error"],

  backOfficeClearError: null,

  // // Users
  backOfficeSamuUsersRequest: ["companyId"],
  backOfficeSamuUseCreated: ["user"],
  backOfficeSamuUsersRequestSuccess: ["users"],
  backOfficeSamuUsersRequestFailure: ["error"],

  // backOfficeModifySamuUserRequest: ["transporterUser","id","companyId","callback"],
  // backOfficeModifySamuUserRequestSuccess:null,
  // backOfficeModifySamuUserRequestFailure: ["error"],

  // backOfficeDeleteUserSamuRequest: ["transporterUser","companyId","callback"],
  // backOfficeDeleteUserSamuRequestSuccess: null,
  // backOfficeDeleteUserSamuRequestFailure: ["error"],

  // // Partners
  // backOfficeSamuPartnersRequest: ["companyId", 'page', 'limit'],
  // backOfficeSamuPartnersRequestSuccess: ["vehicles"],
  // backOfficeSamuPartnersRequestFailure: ["error"],

  // backOfficeModifyPartnerRequest: ["vehicle","id","companyId","callback"],
  // backOfficeModifyPartnerRequestSuccess:null,
  // backOfficeModifyPartnerRequestFailure: ["error"],

  // backOfficeDeletePartnerRequest: ["vehiculeId"],
  // backOfficeDeletePartnerRequestSuccess: null,
  // backOfficeDeletePartnerRequestFailure: ["error"],
});

export const SamuDetailsType = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  samu: {
    details: null,
    users: {
      // drivers: [],
      admins: [],
    },
    partners: {
      page: 1,
      limit: 10,
      vehicles: [],
    },
  },
  action: {
    loading: false,
    error: "",
  },
});

/// GENERAL
export const backOfficeGetSamuDetailsResetState = (state, action) => {
  return INITIAL_STATE;
};

export const backOfficeClearError = (state, action) => {
  return Immutable.setIn(state, ["action", "error"], "");
};

export const backOfficeGetSamuDetailsRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["samu", "details"], null);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeGetSamuDetailsRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["samu", "details"], action.details);
};

export const backOfficeGetSamuDetailsRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeSamuUsersRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["samu", "users", "admins"], []);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const backOfficeSamuUsersRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["samu", "users", "admins"], action.users);
};

export const backOfficeSamuUsersRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const backOfficeSamuUseCreated = (state, action) => {
  const users = state.samu.users.admins.asMutable({ deep: true });
  return Immutable.setIn(
    state,
    ["samu", "users", "admins"],
    [action.user, ...users]
  );
};

// export const backOfficeSamuPartnersRequest = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], true)
//     nextSate = Immutable.setIn(nextSate, ["samu", 'vehicles'], {
//         page: 1,
//         limit: 10,
//         vehicles: []
//     })
//     return Immutable.setIn(nextSate, ["action", 'error'], "")
// }

// export const backOfficeSamuPartnersRequestSuccess = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], false)
//     return Immutable.setIn(nextSate, ["samu", 'vehicles'], action.vehicles)
// }

// export const backOfficeSamuPartnersRequestFailure = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], false)
//     return Immutable.setIn(nextSate, ["action", 'error'], action.error)
// }

// export const backOfficeModifySamuUserRequest = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], true)
//     return Immutable.setIn(nextSate, ["action", 'error'], "")
// }

// export const backOfficeModifySamuUserRequestSuccess = (state, action) => {
//     return Immutable.setIn(state, ["action", 'loading'], false)

// }

// export const backOfficeModifySamuUserRequestFailure = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], false)
//     return Immutable.setIn(nextSate, ["action", 'error'], action.error)
// }

// export const backOfficeDeleteUserSamuRequest = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], true)
//     return Immutable.setIn(nextSate, ["action", 'error'], "")
// }

// export const backOfficeDeleteUserSamuRequestSuccess = (state, action) => {
//     return Immutable.setIn(state, ["action", 'loading'], false)

// }

// export const backOfficeDeleteUserSamuRequestFailure = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], false)
//     return Immutable.setIn(nextSate, ["action", 'error'], action.error)
// }

// export const backOfficeModifyPartnerRequest = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], true)
//     return Immutable.setIn(nextSate, ["action", 'error'], "")
// }

// export const backOfficeModifyPartnerRequestSuccess = (state, action) => {
//     return Immutable.setIn(state, ["action", 'loading'], false)

// }

// export const backOfficeModifyPartnerRequestFailure = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], false)
//     return Immutable.setIn(nextSate, ["action", 'error'], action.error)
// }

// export const backOfficeDeletePartnerRequest = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], true)
//     return Immutable.setIn(nextSate, ["action", 'error'], "")
// }

// export const backOfficeDeletePartnerRequestSuccess = (state, action) => {
//     return Immutable.setIn(state, ["action", 'loading'], false)

// }

// export const backOfficeDeletePartnerRequestFailure = (state, action) => {
//     let nextSate = Immutable.setIn(state, ["action", 'loading'], false)
//     return Immutable.setIn(nextSate, ["action", 'error'], action.error)
// }

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BACK_OFFICE_CLEAR_ERROR]: backOfficeClearError,

  [Types.BACK_OFFICE_GET_SAMU_DETAILS_RESET_STATE]:
    backOfficeGetSamuDetailsResetState,

  // Details
  [Types.BACK_OFFICE_GET_SAMU_DETAILS_REQUEST]: backOfficeGetSamuDetailsRequest,
  [Types.BACK_OFFICE_GET_SAMU_DETAILS_REQUEST_SUCCESS]:
    backOfficeGetSamuDetailsRequestSuccess,
  [Types.BACK_OFFICE_GET_SAMU_DETAILS_REQUEST_FAILURE]:
    backOfficeGetSamuDetailsRequestFailure,

  [Types.BACK_OFFICE_SAMU_USERS_REQUEST]: backOfficeSamuUsersRequest,
  [Types.BACK_OFFICE_SAMU_USERS_REQUEST_SUCCESS]:
    backOfficeSamuUsersRequestSuccess,
  [Types.BACK_OFFICE_SAMU_USERS_REQUEST_FAILURE]:
    backOfficeSamuUsersRequestFailure,

  [Types.BACK_OFFICE_SAMU_USE_CREATED]: backOfficeSamuUseCreated,

  // [Types.BACK_OFFICE_MODIFY_SAMU_USER_REQUEST]: backOfficeModifySamuUserRequest,
  // [Types.BACK_OFFICE_MODIFY_SAMU_USER_REQUEST_SUCCESS]: backOfficeModifySamuUserRequestSuccess,
  // [Types.BACK_OFFICE_MODIFY_SAMU_USER_REQUEST_FAILURE]: backOfficeModifySamuUserRequestFailure,

  // [Types.BACK_OFFICE_DELETE_USER_SAMU_REQUEST]: backOfficeDeleteUserSamuRequest,
  // [Types.BACK_OFFICE_DELETE_USER_SAMU_REQUEST_SUCCESS]: backOfficeDeleteUserSamuRequestSuccess,
  // [Types.BACK_OFFICE_DELETE_USER_SAMU_REQUEST_FAILURE]: backOfficeDeleteUserSamuRequestFailure,

  // [Types.BACK_OFFICE_DELETE_DRIVER_SAMU_REQUEST_SUCCESS]: backOfficeDeletePartnerRequestSuccess,
  // [Types.BACK_OFFICE_DELETE_DRIVER_SAMU_REQUEST_FAILURE]: backOfficeDeletePartnerRequestFailure,

  // [Types.BACK_OFFICE_SAMU_PARTNERS_REQUEST]: backOfficeSamuPartnersRequest,
  // [Types.BACK_OFFICE_SAMU_PARTNERS_REQUEST_SUCCESS]: backOfficeSamuPartnersRequestSuccess,
  // [Types.BACK_OFFICE_SAMU_PARTNERS_REQUEST_FAILURE]: backOfficeSamuPartnersRequestFailure,

  // [Types.BACK_OFFICE_MODIFY_PARTNER_REQUEST]: backOfficeModifyPartnerRequest,
  // [Types.BACK_OFFICE_MODIFY_PARTNER_REQUEST_SUCCESS]: backOfficeModifyPartnerRequestSuccess,
  // [Types.BACK_OFFICE_MODIFY_PARTNER_REQUEST_FAILURE]: backOfficeModifyPartnerRequestFailure,

  // [Types.BACK_OFFICE_DELETE_PARTNER_REQUEST]: backOfficeDeletePartnerRequest,
  // [Types.BACK_OFFICE_DELETE_PARTNER_REQUEST_SUCCESS]: backOfficeDeletePartnerRequestSuccess,
  // [Types.BACK_OFFICE_DELETE_PARTNER_REQUEST_FAILURE]: backOfficeDeletePartnerRequestFailure,
});

import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  clearError: null,

  sectorsRequest: ["companyId", "callback"],
  sectorsSuccess: ["sectors"],
  sectorsFailure: ["error"],

  createRequest: ["companyId", "sector", "callback"],
  createSuccess: ["sector"],
  createFailure: ["error"],

  updateRequest: ["companyId", "sector", "callback"],
  updateSuccess: ["sector"],
  updateFailure: ["error"],

  addCityRequest: ["sectorId", "cityName", "callback"],
  addCitySuccess: ["sector"],
  addCityFailure: ["error"],

  removeCityRequest: ["sectorId", "cityName", "callback"],
  removeCitySuccess: ["sector"],
  removeCityFailure: ["error"],

  modifyNameRequest: ["sectorId", "sectorName", "callback"],
  modifyNameSuccess: ["sector"],
  modifyNameFailure: ["error"],
});

export const SamuSectorsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  action: {
    loading: false,
    error: "",
  },
  sectors: [],
  /** EXAMPLE OF ONE SECTOR IN THE ARRAY
   * {
   *   _id: "5c9e4a7b9c916d0017b9c916d001",
   *   sectorName: "1",
   *   cities: ["Paris", "Marseille", "Lyon"]
   *   samu: {
   *     id: "5c9e4a7b9c916d0017b9c916d001",
   *     name: "SAMU 75",
   *   },
   *   updatedAt: "2019-03-29T14:00:27.000Z",
   *   createdAt: "2019-03-29T14:00:27.000Z",
   */
});

/* ------------- Reducers ------------- */

export const clearError = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "error"], "");
  return Immutable.setIn(nextSate, ["action", "loading"], false);
};

// GET SECTORS
export const sectorsRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const sectorsSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["sectors"], action.sectors);
};

export const sectorsFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

// CREATE SECTOR
export const createRequest = sectorsRequest;

export const createSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["sectors"],
    [...state.sectors, action.sector]
  );
};

export const createFailure = sectorsFailure;

// UPDATE SECTOR
export const updateRequest = sectorsRequest;

export const updateSuccess = (state, action) => {
  const sectors = state.sectors.map((sector) => {
    if (sector._id === action.sector._id) {
      return action.sector;
    }
    return sector;
  });
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["sectors"], sectors);
};

export const updateFailure = sectorsFailure;

// ADD CITY
export const addCityRequest = sectorsRequest;

export const addCitySuccess = updateSuccess;

export const addCityFailure = sectorsFailure;

// REMOVE CITY
export const removeCityRequest = sectorsRequest;

export const removeCitySuccess = addCitySuccess;

export const removeCityFailure = sectorsFailure;

// MODIFY SECTOR NAME
export const modifyNameRequest = sectorsRequest;

export const modifyNameSuccess = addCitySuccess;

export const modifyNameFailure = sectorsFailure;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLEAR_ERROR]: clearError,

  [Types.SECTORS_REQUEST]: sectorsRequest,
  [Types.SECTORS_SUCCESS]: sectorsSuccess,
  [Types.SECTORS_FAILURE]: sectorsFailure,

  [Types.CREATE_REQUEST]: createRequest,
  [Types.CREATE_SUCCESS]: createSuccess,
  [Types.CREATE_FAILURE]: createFailure,

  [Types.UPDATE_REQUEST]: updateRequest,
  [Types.UPDATE_SUCCESS]: updateSuccess,
  [Types.UPDATE_FAILURE]: updateFailure,

  [Types.ADD_CITY_REQUEST]: addCityRequest,
  [Types.ADD_CITY_SUCCESS]: addCitySuccess,
  [Types.ADD_CITY_FAILURE]: addCityFailure,

  [Types.REMOVE_CITY_REQUEST]: removeCityRequest,
  [Types.REMOVE_CITY_SUCCESS]: removeCitySuccess,
  [Types.REMOVE_CITY_FAILURE]: removeCityFailure,

  [Types.MODIFY_NAME_REQUEST]: modifyNameRequest,
  [Types.MODIFY_NAME_SUCCESS]: modifyNameSuccess,
  [Types.MODIFY_NAME_FAILURE]: modifyNameFailure,
});

import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import _ from "lodash";
import NewServiceAdmin from "./add-service-user";
import ServiceModifyUser from "./service-modify-user";
import SweetAlert from "react-bootstrap-sweetalert";
import CompanyDetailsActions from "reducers/company/company-details";
import { withTranslation } from "react-i18next";

class UsersTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModifyServiceUserPopUp: false,
      userToUpdate: null,
      alert: null,
      showAddNewAdminModale: false,
    };
    this.onPressCancelRideButton = this.onPressCancelRideButton.bind(this);
  }

  componentDidMount() {}

  warningWithConfirmAndCancelMessage(props) {
    const { t, i18n } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Suppression"
          onConfirm={() => this.onPressCancelRideButton(props)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Oui supprimer"
          cancelBtnText="Non"
          showCancel
        >
          {t("companyUsersPage.delete_company_user_message", {
            lng: i18n.language,
          })}
        </SweetAlert>
      ),
    });
  }
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  onPressCancelRideButton(props) {
    const { serviceId, companyId } = this.props;
    const { backOfficeDeleteUserServiceRequest } = this.props;

    backOfficeDeleteUserServiceRequest(props._id, companyId, serviceId);

    this.hideAlert();
  }
  renderAdmins() {
    const { admins } = this.props;

    return (
      <ReactTable
        data={admins}
        filterable
        columns={[
          {
            Header: "Nom",
            accessor: "firstname",
          },
          {
            Header: "Prénom",
            accessor: "lastname",
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false,
            Cell: (prop) => {
              return (
                <div className="actions-right">
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="view"
                    //onClick={() => history.push(`amb-company/${_id}/`)}
                  >
                    <i className="fa fa-eye" />
                  </Button>{" "}
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="edit"
                    onClick={() => {
                      this.setState(
                        Object.assign({}, this.state, {
                          showModifyServiceUserPopUp: true,
                          userToUpdate: prop.original,
                        })
                      );
                    }}
                  >
                    <i className="fa fa-pencil" />
                  </Button>{" "}
                  <Button
                    bsStyle="dark"
                    simple
                    icon
                    title="delete"
                    onClick={() => {
                      this.warningWithConfirmAndCancelMessage(prop.original);
                    }}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                </div>
              );
            },
          },
        ]}
        showPaginationTop={false}
        showPaginationBottom
        className="-striped -highlight"
      />
    );
  }

  render() {
    const { companyId, serviceId } = this.props;

    const { showAddNewAdminModale } = this.state;
    return (
      <Card
        title=""
        category=""
        content={
          <div>
            {this.state.alert}
            <NewServiceAdmin
              {...{ ...this.props }}
              show={showAddNewAdminModale}
              onHide={() => {
                this.setState(
                  Object.assign({}, this.state, {
                    showAddNewAdminModale: false,
                  })
                );
              }}
            />
            <ServiceModifyUser
              companyId={companyId}
              serviceId={serviceId}
              serviceUser={this.state.userToUpdate}
              show={this.state.showModifyServiceUserPopUp}
              onHide={() => {
                this.setState(
                  Object.assign({}, this.state, {
                    showModifyServiceUserPopUp: false,
                  })
                );
              }}
            />
            <Button
              bsStyle="success"
              fill
              wd
              onClick={() => {
                this.setState(
                  Object.assign({}, this.state, { showAddNewAdminModale: true })
                );
              }}
            >
              Ajouter un admin de service
            </Button>
            {this.renderAdmins()}
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeDeleteUserServiceRequest: (...args) =>
      dispatch(
        CompanyDetailsActions.backOfficeDeleteUserServiceRequest(...args)
      ),
  };
};

const CompanyUsersRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTab);
export default withTranslation(["Private", "Common"], { wait: true })(
  CompanyUsersRedux
);

import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import AddCompanyServiceActions from "reducers/company/company-details";

import {
  Col,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
} from "react-bootstrap";
import { Alert } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import Button from "components/CustomButton/CustomButton.jsx";
import _ from "lodash";
import { Formik } from "formik";
import { string, object, number } from "yup";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import "react-google-places-autocomplete/dist/index.min.css";

const validationSchema = object().shape({
  name: string().required("required"),
  address: object().shape({
    address: string().required("required"),
    location: object().shape({
      latitude: number().required(""),
      longitude: number().required(""),
    }),
  }),
  contact: object().shape({
    firstname: string(),
    lastname: string(),
    email: string(),
    phones: object().shape({
      mobile: string(),
    }),
  }),
});

class NewService extends Component {
  constructor(props) {
    super(props);

    this.Address = createRef();
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  componentDidMount() {}

  onSubmitForm(values, { resetForm, setSubmitting }) {
    const { backOfficeCreateCompanyServiceRequest, companyId, onHide } =
      this.props;

    backOfficeCreateCompanyServiceRequest(values, companyId, (error) => {
      if (error) {
        return;
      }

      setSubmitting(false);
      resetForm();

      this.Address.current.clearValue();
      onHide();
    });
  }

  render() {
    const { action } = this.props;
    const { t, i18n } = this.props;

    const { error } = action;
    return (
      <Formik
        initialValues={{
          name: "",
          address: {
            location: {
              longitude: 0,
              latitude: 0,
            },
            address: "",
          },
          contact: {
            firstname: "",
            lastname: "",
            email: "",
            phones: {
              mobile: "",
            },
          },
        }}
        validationSchema={validationSchema}
        onSubmit={this.onSubmitForm}
      >
        {(props) => {
          const { errors, touched } = props;

          return (
            <Modal
              {...this.props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              backdrop="static"
            >
              {error !== "" ? (
                <Alert bsStyle="danger">
                  <span>{error}</span>
                </Alert>
              ) : null}
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t("addCompanyServicePage.add_company_service_button", {
                    lng: i18n.language,
                  })}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form horizontal>
                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {" "}
                      {t(
                        "addCompanyServicePage.add_company_service_name_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <FormControl
                        placeholder=""
                        type="text"
                        autoComplete="off"
                        className={
                          _.has(errors, "name") && _.has(touched, "name")
                            ? "error"
                            : ""
                        }
                        onChange={props.handleChange("name")}
                        onInput={() =>
                          props.setFieldTouched("name", true, true)
                        }
                        onBlur={(e) => {
                          const { value } = e.currentTarget;
                          props.setFieldValue("name", value, true);
                        }}
                        value={props.values.name}
                        required
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-md-3">
                      {t(
                        "addCompanyServicePage.add_company_service_address_label",
                        { lng: i18n.language }
                      )}
                    </ControlLabel>
                    <Col md={9}>
                      <GooglePlacesAutocomplete
                        ref={this.Address}
                        placeholder="Adresse..."
                        suggestionsStyles={{
                          suggestion: {
                            fontSize: 15,
                          },
                        }}
                        inputStyle={{
                          backgroundColor: "#ffffff",
                          borderColor:
                            _.has(errors, "address.address") &&
                            _.has(touched, "address.address")
                              ? "#fb404b"
                              : "#e3e3e3",
                          color: "#565656",
                          borderWidth: 1,
                          borderRadius: 4,
                          boxShadow: "none",
                          height: 40,
                          borderStyle: "solid",
                          paddingHorizontal: 12,
                          paddingVertical: 8,
                          width: "100%",
                        }}
                        autocompletionRequest={
                          {
                            // types: ['postal_code'],
                          }
                        }
                        onSelect={(result) => {
                          const { place_id, description } = result;

                          props.setFieldValue(
                            "address.address",
                            description,
                            true
                          );

                          geocodeByPlaceId(place_id)
                            .then((results) => {
                              return getLatLng(results[0]);
                            })
                            .then(({ lat, lng }) => {
                              props.setFieldValue(
                                "address.location.latitude",
                                lat,
                                true
                              );
                              props.setFieldValue(
                                "address.location.longitude",
                                lng,
                                true
                              );
                            });
                        }}
                        onLoadFailed={(error) =>
                          console.error("Could not inject Google script")
                        }
                      />
                    </Col>
                  </FormGroup>

                  <legend>
                    {" "}
                    {t(
                      "addCompanyServicePage.add_company_service_contact_label",
                      { lng: i18n.language }
                    )}{" "}
                  </legend>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-md-3">
                        {t(
                          "addCompanyServicePage.add_company_service_lastname_label",
                          { lng: i18n.language }
                        )}
                      </ControlLabel>
                      <Col md={9}>
                        <FormControl
                          type="text"
                          autoComplete="off"
                          className={
                            _.has(errors, "contact.firstname") &&
                            _.has(touched, "contact.firstname")
                              ? "error"
                              : ""
                          }
                          onChange={props.handleChange("contact.firstname")}
                          onInput={() =>
                            props.setFieldTouched(
                              "contact.firstname",
                              true,
                              true
                            )
                          }
                          onBlur={(e) => {
                            const { value } = e.currentTarget;
                            props.setFieldValue(
                              "contact.firstname",
                              value,
                              true
                            );
                          }}
                          value={props.values.contact.firstname}
                        />
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-md-3">
                        {t(
                          "addCompanyServicePage.add_company_service_firstname_label",
                          { lng: i18n.language }
                        )}
                      </ControlLabel>
                      <Col md={9}>
                        <FormControl
                          type="text"
                          autoComplete="off"
                          className={
                            _.has(errors, "contact.lastname") &&
                            _.has(touched, "contact.lastname")
                              ? "error"
                              : ""
                          }
                          onChange={props.handleChange("contact.lastname")}
                          onInput={() =>
                            props.setFieldTouched(
                              "contact.lastname",
                              true,
                              true
                            )
                          }
                          onBlur={(e) => {
                            const { value } = e.currentTarget;
                            props.setFieldValue(
                              "contact.lastname",
                              value,
                              true
                            );
                          }}
                          value={props.values.contact.lastname}
                        />
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-md-3">
                        {t(
                          "addCompanyServicePage.add_company_service_phone_label",
                          { lng: i18n.language }
                        )}
                      </ControlLabel>
                      <Col md={9}>
                        <FormControl
                          type="text"
                          autoComplete="off"
                          className={
                            _.has(errors, "contact.phones.mobile") &&
                            _.has(touched, "contact.phones.mobile")
                              ? "error"
                              : ""
                          }
                          onChange={props.handleChange("contact.phones.mobile")}
                          onInput={() =>
                            props.setFieldTouched(
                              "contact.phones.mobile",
                              true,
                              true
                            )
                          }
                          onBlur={(e) => {
                            const { value } = e.currentTarget;
                            props.setFieldValue(
                              "contact.phones.mobile",
                              value,
                              true
                            );
                          }}
                          value={props.values.contact.phones.mobile}
                        />
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-md-3">
                        {t(
                          "addCompanyServicePage.add_company_service_email_label",
                          { lng: i18n.language }
                        )}
                      </ControlLabel>
                      <Col md={9}>
                        <FormControl
                          type="text"
                          autoComplete="off"
                          className={
                            _.has(errors, "contact.email") &&
                            _.has(touched, "contact.email")
                              ? "error"
                              : ""
                          }
                          onChange={props.handleChange("contact.email")}
                          onInput={() =>
                            props.setFieldTouched("contact.email", true, true)
                          }
                          onBlur={(e) => {
                            const { value } = e.currentTarget;
                            props.setFieldValue("contact.email", value, true);
                          }}
                          value={props.values.contact.email}
                        />
                      </Col>
                    </FormGroup>
                  </fieldset>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  disabled={props.isSubmitting}
                  onClick={() => {
                    props.resetForm();
                    this.props.backOfficeClearError();
                    this.props.onHide();
                  }}
                >
                  {" "}
                  {t("Common:footerPage.close", { lng: i18n.language })}
                </Button>
                <Button
                  bsStyle="success"
                  fill
                  disabled={props.isSubmitting}
                  onClick={() => {
                    props.handleSubmit();
                  }}
                >
                  {t("Common:footerPage.save_changes", { lng: i18n.language })}
                </Button>
              </Modal.Footer>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    action: state.backOfficeGetDetailsCompany.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    backOfficeCreateCompanyServiceRequest: (...args) =>
      dispatch(
        AddCompanyServiceActions.backOfficeCreateCompanyServiceRequest(...args)
      ),
    backOfficeClearError: (...args) =>
      dispatch(AddCompanyServiceActions.backOfficeClearError(...args)),
  };
};

const addCompanyServiceRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewService);
export default withTranslation(["Private", "Common"], { wait: true })(
  addCompanyServiceRedux
);

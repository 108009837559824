import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getRidesRequest: ["page", "limit", "filters"],
  getRidesRequestSuccess: ["rides"],
  getRidesRequestFailure: ["error"],
});

export const GetRidesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  rides: {
    page: 1,
    limit: 10,
    docs: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/// GENERAL
export const getRidesRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const getRidesRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const getRidesRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["rides"], action.rides);
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_RIDES_REQUEST]: getRidesRequest,
  [Types.GET_RIDES_REQUEST_SUCCESS]: getRidesRequestSuccess,
  [Types.GET_RIDES_REQUEST_FAILURE]: getRidesRequestFailure,
});
